import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';

const initialSlider = {
  name: '',
  description: '',
  gallery_id: null,
  display: true,
  image: null,
  video: null,
  link: '',
};
const cookies = new Cookies();

const SlideShowPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, fetchItem } = useContext(DashboardPanelContext);
  const [galleries, setGalleries] = useState([]);
  const [sliderImage, setSliderImage] = useState(null);
  const [sliderVideo, setSliderVideo] = useState(null);
  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  const {
    id,
    item: currentSlider,
    setItem: setCurrentSlider,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/sliders',
    localStorageKey: 'sliders',
    initialItem: initialSlider,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/galleries`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/galleries?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setGalleries(res);
      }
    });
  }, []);

  const fileSelectedHandlerLoad = async (e) => {
    setSliderImage(null);
    const url = process.env.REACT_APP_SITE_URL;
    const name = `${url}`.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setSliderImage({ img: imgSrc });
  };
  const fileSelectedVideoHandlerLoad = async (e) => {
    setSliderVideo(null);
    const url = process.env.REACT_APP_SITE_URL;
    const name = `${url}`.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setSliderVideo({ img: imgSrc });
  };
  useEffect(() => {
    if (currentSlider.data) {
      setCurrentSlider(currentSlider?.data);
    }
    if (currentSlider) {
      setValues(setValue, currentSlider);
    }
    if (currentSlider?.id && currentSlider?.attachments && currentSlider.attachments[0]) {
      if (currentSlider.attachments[0].slug === 'video') {
        fileSelectedVideoHandlerLoad(currentSlider.attachments[0].path);
      }
      if (currentSlider.attachments[0].slug === 'image') {
        fileSelectedHandlerLoad(currentSlider.attachments[0].path);
      }
    }
    if (currentSlider?.id && currentSlider?.attachments && currentSlider.attachments[1]) {
      if (currentSlider.attachments[1].slug === 'video') {
        fileSelectedVideoHandlerLoad(currentSlider.attachments[1].path);
      }
      if (currentSlider.attachments[1].slug === 'image') {
        fileSelectedHandlerLoad(currentSlider.attachments[1].path);
      }
    }
  }, [currentSlider]);

  const onSubmit = () => {
    setLoading(true);
    const slider = { ...currentSlider };
    if (currentSlider.display) {
      slider.display = '1';
    } else {
      slider.display = '0';
    }
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const formData = new FormData();
    for (let i = 0; i < Object.keys(slider).length; i++) {
      formData.append(Object.keys(slider)[i], Object.values(slider)[i] ? Object.values(slider)[i] : '');
    }
    if (id) {
      formData.append('_method', 'put');
    }
    axios({
      method: 'post',
      url: id ? 'sliders/'.concat(id) : 'sliders',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          navigate('/cms/slideshow-list');
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentSlider, setCurrentSlider);
  };
  const fileSelectedHandler = async (e) => {
    const _currentSlider = currentSlider;
    _currentSlider.image = null;
    setSliderImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.image = file;
    setCurrentSlider(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setSliderImage({ img: reader.result });
    };
  };
  const fileSelectedVideoHandler = async (e) => {
    const _currentSlider = currentSlider;
    _currentSlider.video = null;
    setSliderVideo(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.video = file;
    setCurrentSlider(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setSliderVideo({ img: reader.result });
    };
  };
  return (
    <div style={{ margin: 10 }}>
      {currentSlider && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/cms/slideshow-list',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات اسلایدشو' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                {/* {!currentSlider.id && (
                  <BTextField
                    sm={6}
                    xs={12}
                    label='نام اسلایدشو'
                    name='name'
                    value={currentSlider?.name}
                    onChange={onChange}
                    readOnly={viewReadOnly || currentSlider?.id}
                    error={errors?.name}
                    required
                  />
                )} */}
                <BTextField
                  sm={6}
                  xs={12}
                  label='نام اسلایدشو'
                  name='name'
                  value={currentSlider?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly || currentSlider?.id}
                  error={errors?.name}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='گالری اسلایدشو'
                  required
                  name='gallery_id'
                  value={currentSlider?.gallery_id}
                  onChange={onChange}
                  error={errors?.gallery_id}
                  items={convertArrayToKeyedObject(galleries, 'id', 'description')}
                  readOnly={viewReadOnly || currentSlider?.id}
                />
                <BTextareaField
                  onChange={onChange}
                  name='description'
                  sm={12}
                  value={currentSlider?.description}
                  label='توضیحات '
                  // readOnly={currentSlider?.id}
                />
                <BTextField
                  sm={12}
                  xs={12}
                  label='لینک اسلایدشو'
                  name='link'
                  value={currentSlider?.link}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                />
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب عکس</div>
                  <span>
                    اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا میباشد.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandler}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {sliderImage?.img && (
                    <img alt='slider' src={sliderImage?.img} style={{ width: '200px', height: '200px' }} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب فیلم</div>
                  <span>اندازه فایل آپلود شده حداکثر 100 مگابایت میباشد. فرمت فیلم به صورت m4v یا mp4 میباشد.</span>
                  <br />
                  <input
                    type='file'
                    accept='video/mp4,video/x-m4v,video/*'
                    onChange={fileSelectedVideoHandler}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {sliderVideo?.img && (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video width='320' height='240' controls>
                      <source src={sliderVideo?.img} type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Grid>
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='نمایش داده شود'
                  value={Number(currentSlider?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/cms/slideshow-list',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default SlideShowPage;
