import React from 'react';
import { Grid, Typography } from '@mui/material';
import notFoundImage from 'assets/img/not-found.avif';

const NotFound = () => (
  <div>
    <Grid container justifyContent='center' spacing={4}>
      <Grid item lg={6} xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h5' style={{ direction: 'ltr' }}>
            404: The page you are looking for isn’t here
          </Typography>
          <Typography variant='subtitle2'>
            You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation
          </Typography>
          <img
            alt='Under development'
            src={notFoundImage}
            style={{ marginTop: 5, display: 'inline-block', maxWidth: '100%', width: 560 }}
          />
        </div>
      </Grid>
    </Grid>
  </div>
);

export default NotFound;
