import React from 'react';
import { string } from 'prop-types';
// import { FormHelperText } from '@material-ui';

const BErrorFormHelperText = ({ text = '' }) =>
  text ? (
    <div>text</div>
  ) : (
    // <FormHelperText error={text} component='span' id='component-error-text'>
    //   {text}
    // </FormHelperText>
    <></>
  );

BErrorFormHelperText.propTypes = {
  text: string,
};

export default BErrorFormHelperText;
