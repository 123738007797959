import React from 'react';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(1),
//   },
// }));
const ListsContainer = ({ children }) => (
  // const classes = useStyles();
  <div>{Array.isArray(children) ? children.map((c) => c) : children}</div>
);
export default ListsContainer;
