import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';

const initialItem = {
  name: '',
  price: '',
  description: '',
};

const DeliveryPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);
  const { viewReadOnly } = globalState;
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/delivery-method',
    localStorageKey: 'delivery-method',
    initialItem,
  });

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    saveItem('delivery-method', _currentItem, id)
      .then(() => navigate('/delivery-management/delivery-methods'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <>
          <Card {...rest}>
            <Button
              id='backToListIcon'
              component={Link}
              to={{
                pathname: '/delivery-management/delivery-methods',
              }}
              color='secondary'
              variant='outlined'
              style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
            >
              <ArrowLeftOutlined />
            </Button>
            <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
              <CardHeader title='اطلاعات روش ارسال' />
              <Divider />
              <CardContent>
                <br />
                <Grid container spacing={3}>
                  <BTextField
                    xs={12}
                    sm={6}
                    label='عنوان'
                    name='name'
                    value={currentItem?.name}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.name}
                    required
                  />
                  <BTextField
                    xs={12}
                    sm={6}
                    label='هزینه ارسال (تومان)'
                    name='price'
                    value={currentItem?.price}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.price}
                    required
                  />
                  <BTextField
                    xs={12}
                    sm={6}
                    label='توضیحات'
                    name='description'
                    value={currentItem?.description}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.description}
                    required
                  />
                  <BCheckBoxField
                    sm={12}
                    name='display'
                    label='نمایش داده شود'
                    value={Number(currentItem?.display)}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                  />
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid item xs={12} sm={6}>
                  {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                  <Button
                    id='backToList'
                    component={Link}
                    to={{
                      pathname: '/delivery-management/delivery-methods',
                    }}
                    color='secondary'
                    variant='outlined'
                  >
                    بازگشت به لیست
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
        </>
      )}
    </div>
  );
};

export default DeliveryPage;
