import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space } from 'antd';
import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { DashboardPanelContext } from '../../../components/context/DashboardContext';
import Table from '../../../components/tables/Table';
import useTableActions from '../../../hooks/useTableActions';
import ListsContainer from '../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../components/EnabledColumn/EnabledColumn';

const ProvincesList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder='جستجو'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            جستجو
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            ریست
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            فیلتر
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            بستن
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/locations/get-all-provinces`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const resData = response.data.map((r) => ({ ...r, key: r.code }));
        setData(resData);
      }
    });
    setColumns([
      {
        title: 'عنوان',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'کد استان',
        dataIndex: 'code',
        key: 'code',
        ...getColumnSearchProps('code'),
      },
      {
        title: 'وضعیت',
        dataIndex: 'status',
        key: 'status',
        type: 'boolean',
        filters: [
          {
            text: 'فعال',
            value: true,
          },
          {
            text: 'غیر فعال',
            value: false,
          },
        ],
        onFilter: (value, record) => record.display === value,
        sorter: (a, b) => Number(a.display) - Number(b.display),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'none',
    deletePermission: 'none',
    editURL: 'none',
    removePATH: 'none',
    createPermission: 'none',
    extraActions: ['admin.activate-province.activateProvince', '/locations/activate-province/'],
    extraActionsOne: ['admin.deactivate-province.deactivateProvince', '/locations/deactivate-province/'],
  });

  const handleCreate = () => {};

  const onActivateProvince = useCallback((recordId) => {
    saveItem('/locations/activate-province/', { id: recordId }).then(() => navigate(0));
  }, []);

  const onDeactivateProvince = useCallback((recordId) => {
    saveItem('/locations/deactivate-province/', { id: recordId }).then(() => navigate(0));
  }, []);

  return (
    <ListsContainer>
      <Table
        key={key}
        title='لیست استان ها'
        columns={columns}
        createSlug='افزودن صفحه'
        actions={actions}
        createAction={handleCreate}
        tableRef={tableRef}
        data={data}
        pageSize={32}
        filtering={false}
        sortableColumns
        extraActions={[
          {
            title: 'فعال کردن استان',
            url: actions[4],
            icon: CheckCircleOutlined,
            callback: (record) => onActivateProvince(record),
          },
          {
            title: 'غیر فعال کردن استان',
            url: actions[5],
            icon: CloseCircleOutlined,
            callback: (record) => onDeactivateProvince(record),
          },
        ]}
      />
    </ListsContainer>
  );
};

export default ProvincesList;
