import React, { useEffect, useState } from 'react';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Profile = (props) => {
  const { credentials } = props;

  const [user, setUser] = useState({
    name: '',
    avatar: '/images/avatars/avatar_11.png',
  });

  useEffect(() => {
    if (credentials?.user) {
      setUser(credentials?.user);
    }
  }, [credentials]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 'fit-content',
        borderBottom: '1px solid #ddd',
        borderLeft: '1px solid #ddd',
        padding: '20px 30px',
      }}
    >
      {user && (
        <div style={{ display: 'flex', alignItems: 'flex-start', color: '#38291E' }}>
          <div
            style={{
              color: '#38291E',
              padding: '0 4px',
              fontSize: '15px',
              marginLeft: '10px',
              marginBottom: '10px',
            }}
          >
            {' '}
            <UserOutlined />
          </div>{' '}
          <Typography>{user.name}</Typography>
        </div>
      )}
      <Link to='/profile' className='profile' style={{ padding: '5px', borderRadius: '5px', marginRight: '-5px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', color: '#38291E' }}>
          <div
            style={{
              color: '#38291E',
              padding: '0 4px',
              fontSize: '15px',
              marginLeft: '10px',
            }}
          >
            {' '}
            <EditOutlined />
          </div>{' '}
          <Typography>حساب کاربری</Typography>
        </div>
      </Link>
    </div>
  );
};

export default Profile;
