import useHasPermission from './useHasPermission';

const useTableActions = ({
  updatePermission,
  deletePermission,
  editURL,
  removePATH,
  createPermission,
  viewPermission = '',
  extraActions = null,
  extraActionsOne = null,
  extraActionsAttribute = null,
  duplicatePermission = null,
  extraActionsQuantity = null,
}) => {
  const useHasUpdatePermission = useHasPermission(updatePermission);
  const useHasDeletePermission = useHasPermission(deletePermission);
  const useHasCreatePermission = useHasPermission(createPermission);
  const useHasViewPermission = useHasPermission(viewPermission);
  const useHasExtraPermission = useHasPermission(extraActions && extraActions[0] ? extraActions[0] : '');
  const useHasExtraOnePermission = useHasPermission(extraActionsOne && extraActionsOne[0] ? extraActionsOne[0] : '');
  const useHasExtraAttributePermission = useHasPermission(
    extraActionsAttribute && extraActionsAttribute[0] ? extraActionsAttribute[0] : '',
  );
  const useHasExtraQuantityPermission = useHasPermission(
    extraActionsQuantity && extraActionsQuantity[0] ? extraActionsQuantity[0] : '',
  );
  const useHasDuplicatePermission = duplicatePermission;
  return [
    useHasUpdatePermission ? editURL : null,
    removePATH && useHasDeletePermission ? removePATH : null,
    useHasCreatePermission,
    useHasViewPermission ? editURL : null,
    useHasExtraPermission ? extraActions[1] : null,
    useHasExtraOnePermission ? extraActionsOne[1] : null,
    useHasExtraAttributePermission ? extraActionsAttribute[1] : null,
    useHasDuplicatePermission,
    useHasExtraQuantityPermission ? extraActionsQuantity[1] : null,
  ];
};

export default useTableActions;
