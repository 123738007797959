import { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { UserCredentialsContext } from '../components/context/LoginContext';

const useHasPermission = (permission) => {
  const [mainPermission, setMainPermission] = useState(null);
  const { credentials } = useContext(UserCredentialsContext);

  useEffect(() => {
    const permissions = _.get(credentials?.user, 'permissions', []);
    if (permissions.length > 0) {
      if (permissions.find((per) => per.name === permission)?.id) {
        setMainPermission(permission);
      } else {
        setMainPermission('undefined');
      }
    } else {
      setMainPermission('undefined');
    }
  }, [credentials]);

  return mainPermission === permission;
};

export default useHasPermission;
