import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import styles from '../../../assets/scss/modules/inputs.module.scss';
import { showState } from '../../functions/showState';
import './BMultiSelectField.css';
import { ReactFormHookServerError } from '../../../common/utilities';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(4),
//   },
//   customInputFormControl: {
//     width: '100%',
//   },
//   formLabel: {
//     fontSize: 16,
//     marginBottom: 10,
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   chip: {
//     margin: 2,
//   },
// }));

const BMultiSelectField = ({
  items = {},
  label,
  name,
  value,
  readOnly = false,
  error,
  onChange,
  onKeyDown,
  xs = 12,
  sm = 4,
  serverError,
}) => {
  const prepareForOnChange = (e) => {
    const item = {
      currentTarget: {
        name,
        value: e.target.value,
      },
    };
    onChange(item);
  };
  return (
    <>
      <Grid item xs={xs} sm={sm} aria-disabled={readOnly}>
        <InputLabel htmlFor={`input--${name}`} style={{ fontSize: '16px', marginBottom: '10px' }}>
          {label}
        </InputLabel>
        <Select
          multiple
          name={name}
          onKeyDown={onKeyDown || (() => undefined)}
          onChange={prepareForOnChange}
          fullWidth
          readOnly={readOnly}
          variant='outlined'
          value={value}
          MenuProps={MenuProps}
          renderValue={(selected) => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map((e) => (
                <Chip
                  key={e}
                  label={items[e] || ''}
                  style={{ margin: '2px', padding: '10px' }}
                  onDelete={() => undefined}
                />
              ))}
            </div>
          )}
        >
          {Object.keys(items).map((item, index) => (
            <MenuItem key={index} value={item}>
              {showState(items[item])}
            </MenuItem>
          ))}
        </Select>
        {error && <p className={styles.validationError}>اجباری است</p>}
        {ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
      </Grid>
    </>
  );
};

export default BMultiSelectField;
