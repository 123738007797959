import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space, Spin } from 'antd';
import { SearchOutlined, SlidersOutlined, CopyOutlined, MessageOutlined, ProjectOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Grid } from '@mui/material';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY, SET_SAVE_SUCCESS } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';
import { BSwalShowError } from '../../../../common/BSwal';

const cookies = new Cookies();

const ProductList = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const searchInput = useRef(null);
  const [searchProduct, setSearchProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAllProducts = useCallback(() => {
    fetchItem({
      modelName: `/product`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        const number_of_pages = response.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        for (let i = 0; i < response.data?.data.length; i++) {
          allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
        }
        setData(allData);
      }
    });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    saveItem('/product/search', { title: selectedKeys[0] }).then(async (response) => {
      globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
      if (response) {
        const allData = [];
        const number_of_pages = response.data?.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        for (let i = 0; i < response.data?.data?.data?.length; i++) {
          allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
        }
        setData(allData);
      }
    });
  };
  const handleReset = (clearFilters, close) => {
    clearFilters();
    getAllProducts();
    close();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder='جستجو'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            جستجو
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, close)}
            size='small'
            style={{
              width: 90,
            }}
          >
            ریست
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              // setSearchText(selectedKeys[0]);
              // setSearchedColumn(dataIndex);
            }}
          >
            فیلتر
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            بستن
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) => {
    //   console.log('record', record);
    //   console.log('dataIndex', dataIndex);
    //   console.log('val', value);
    //   saveItem('/product/search', { title: value }).then((res) => console.log('res', res));
    //   // return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    // },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  useEffect(() => {
    getAllProducts();
  }, [searchProduct, getAllProducts]);

  useEffect(() => {
    setColumns([
      {
        title: 'عنوان محصول',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'دسته بندی',
        dataIndex: 'category',
        key: 'category',
        render: (item) => item.name,
      },
      {
        title: 'برند',
        dataIndex: 'brand',
        key: 'brand',
        render: (item) => item.name,
      },
      // {
      //   title: 'موجودی',
      //   dataIndex: 'inventory',
      //   key: 'inventory',
      //   render: (item) => item?.quantity || 0,
      // },
      {
        title: 'وضعیت انتشار',
        dataIndex: 'active',
        key: 'active',
        type: 'boolean',
        // filters: [
        //   {
        //     text: 'منتشر شده',
        //     value: '1' || 1 || true,
        //   },
        //   {
        //     text: 'منتشر نشده',
        //     value: 0,
        //   },
        // ],
        // onFilter: (value, record) => console.log('rec', record, value),
        sorter: (a, b) => Number(a.active) - Number(b.active),
        sortDirections: ['descend'],
        render: (rowData) => (rowData === 1 ? 'منتشر شده' : 'منتشر نشده'),
      },
      {
        title: 'وضعیت',
        dataIndex: 'display',
        key: 'display',
        type: 'boolean',
        // filters: [
        //   {
        //     text: 'فعال',
        //     value: '1',
        //   },
        //   {
        //     text: 'غیر فعال',
        //     value: '0',
        //   },
        // ],
        // onFilter: (value, record) => record.display === value,
        sorter: (a, b) => Number(a.display) - Number(b.display),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'product.update',
    deletePermission: 'product.destroy',
    editURL: '/product-management/product/',
    removePATH: 'product',
    createPermission: 'product.store',
    extraActions: ['admin.product.get_comments.by.id', '/product-management/comments/'],
    extraActionsAttribute: ['show-product-attribute-options', '/product-management/product-attribute/'],
    duplicatePermission: 'duplicate',
    extraActionsQuantity: ['show-product-attribute-options', '/product-management/product-attribute-quantity/'],
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate('/product-management/product');
  };

  const onDuplicateProduct = (productId) => {
    fetchItem({
      modelName: `/product/${productId}`,
      showLoader: true,
    }).then((response) => {
      if (response.data) {
        const initialItem = {
          name: response.data.name,
          name_en: response.data.name_en,
          product_category_id: response.data.category?.id || null,
          brand_id: response.data.brand?.id || null,
          description: response.data.description,
          instruction: response.data.instruction,
          base_price: response.data.base_price,
          off_price: response.data.off_price,
          new: response.data.new,
          special: response.data.special,
          popular: response.data.popular,
          active: response.data.active,
          display: response.data.display,
        };
        saveItem(`product`, initialItem, null).then(() => setSearchProduct((prev) => !prev));
      }
    });
  };

  const onChangeGetOtherPageData = (page) => {
    fetchItem({
      modelName: `/product?page=${page}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.data.length; i++) {
          allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
        }
        setData(allData);
      }
    });
  };

  const onImportProducts = useCallback(
    (e) => {
      setLoading(true);
      const xmlFile = e.target.files[0];

      if (xmlFile) {
        // Create a new FormData object
        const formData = new FormData();

        // Append the XML file to the FormData object
        formData.append('xml_file', xmlFile);
        axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
        axios({
          method: 'post',
          url: '/product/import-xml',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('userToken')}`,
          },
        })
          .catch((err) => BSwalShowError(err?.data?.messages))
          .finally(() => {
            setLoading(false);
            getAllProducts();
          });
      }
    },
    [getAllProducts],
  );
  const onImportImages = useCallback((e) => {
    setLoading(true);
    const xmlFile = e.target.files[0];

    if (xmlFile) {
      // Create a new FormData object
      const formData = new FormData();

      // Append the XML file to the FormData object
      formData.append('zip_file', xmlFile);
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      axios({
        method: 'post',
        url: '/product/import-product-images',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    }
  }, []);
  return (
    <>
      <Spin tip='در حال بارگذاری' spinning={loading}>
        <Grid container>
          <Grid item xs={12} sm={2}>
            <div style={{ fontSize: '16px' }}>ایمپورت فایل XML</div>
            <input
              type='file'
              accept='.xml'
              onChange={onImportProducts}
              style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
            />
            <style>
              {`
            input[type="file"]::-webkit-file-upload-button {
              visibility: hidden;
            }
            input[type="file"]::before {
              content: 'Choose File';
              color: #333; /* Change the color to match your design */
              display: inline-block;
              background: #ddd; /* Change the background color to match your design */
              border: 1px solid #999; /* Change the border color to match your design */
              padding: 5px 10px;
              border-radius: 5px;
              cursor: pointer;
            }
            input[type="file"]:hover::before {
              background: #bbb; /* Change the background color on hover to match your design */
            }
          `}
            </style>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div style={{ fontSize: '16px' }}>ایمپورت فایل عکس ها (zip)</div>
            <input
              type='file'
              accept='.zip'
              onChange={onImportImages}
              style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
            />
            <style>
              {`
            input[type="file"]::-webkit-file-upload-button {
              visibility: hidden;
            }
            input[type="file"]::before {
              content: 'Choose File';
              color: #333; /* Change the color to match your design */
              display: inline-block;
              background: #ddd; /* Change the background color to match your design */
              border: 1px solid #999; /* Change the border color to match your design */
              padding: 5px 10px;
              border-radius: 5px;
              cursor: pointer;
            }
            input[type="file"]:hover::before {
              background: #bbb; /* Change the background color on hover to match your design */
            }
          `}
            </style>
          </Grid>
        </Grid>
        <ListsContainer>
          <Table
            tableKey={key}
            key={key}
            title='لیست محصولات'
            columns={columns}
            createSlug='افزودن به محصولات'
            actions={actions}
            createAction={handleCreate}
            tableRef={tableRef}
            data={data}
            pageSize={pageSize}
            filtering={false}
            sortableColumns
            totalPages={totalPageNumber}
            getOtherPageData={onChangeGetOtherPageData}
            extraActions={[
              { title: 'تخصبص ویژگی به محصول', url: actions[6], icon: SlidersOutlined },
              { title: 'تخصبص موجودی به محصول', url: actions[8], icon: ProjectOutlined },
              {
                title: 'ایجاد کپی از محصول',
                url: actions[7],
                icon: CopyOutlined,
                callback: (record) => onDuplicateProduct(record),
              },
              { title: 'لیست نظرات', url: actions[4], icon: MessageOutlined },
            ]}
          />
        </ListsContainer>
      </Spin>
    </>
  );
};

export default ProductList;
