import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment-jalaali';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';

moment.loadPersian({ usePersianDigits: false });

const DiscountType = [
  {
    key: 'percent',
    name: 'درصدی',
  },
  {
    key: 'amount',
    name: 'مقدار ثابت',
  },
];

const initialItem = {
  name: '',
  code: '',
  discount_type: null,
  value: '',
  description: '',
  start_date: '',
  end_date: '',
  status: true,
};

const DiscountPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);
  const { viewReadOnly } = globalState;
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [firstLoad, setFirstLoad] = useState(false);

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/discounts',
    localStorageKey: 'discounts',
    initialItem,
  });

  useEffect(() => {
    if (firstLoad && currentItem?.id && (currentItem.start_date || currentItem.end_date)) {
      const _currentItem = currentItem;
      _currentItem.start_date = moment(_currentItem.start_date, 'YYYY-M-D').format('jYYYY/jM/jD');
      _currentItem.end_date = moment(_currentItem.end_date, 'YYYY-M-D').format('jYYYY/jM/jD');
      setCurrentItem(_currentItem);
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
      setFirstLoad(true);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.status) {
      _currentItem.status = '1';
    } else {
      _currentItem.status = '0';
    }
    if (_currentItem.start_date && moment(_currentItem.start_date, 'jYYYY/jM/jD').isValid()) {
      _currentItem.start_date = moment(_currentItem.start_date, 'jYYYY/jM/jD').format('YYYY-M-D');
    }
    if (_currentItem.end_date && moment(_currentItem.end_date, 'jYYYY/jM/jD').isValid()) {
      _currentItem.end_date = moment(_currentItem.end_date, 'jYYYY/jM/jD').format('YYYY-M-D');
    }
    saveItem('discounts', _currentItem, id)
      .then(() => navigate('/customer-management/discounts'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/customer-management/discounts',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات کد تخفیف' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  label='عنوان تخفیف'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BTextField
                  label='کد تخفیف'
                  name='code'
                  value={currentItem?.code}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.code}
                  required
                />
                <BTextField
                  label='توضیحات'
                  name='description'
                  value={currentItem?.description}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.description}
                  required
                />
                <BSelectField
                  sm={4}
                  xs={12}
                  label='انتخاب نوع تخفیف'
                  required
                  name='discount_type'
                  value={currentItem?.discount_type}
                  onChange={onChange}
                  error={errors?.discount_type}
                  items={convertArrayToKeyedObject(DiscountType, 'key', 'name')}
                  readOnly={viewReadOnly}
                />
                <BTextField
                  label='مقدار تخفیف'
                  name='value'
                  value={currentItem?.value}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.value}
                  required
                />
                <BDateFieldTable
                  name='start_date'
                  label='تاریخ شروع'
                  error={errors?.start_date}
                  value={currentItem?.start_date}
                  onChange={onChange}
                />
                <BDateFieldTable
                  name='end_date'
                  label='تاریخ پایان'
                  error={errors?.end_date}
                  value={currentItem?.end_date}
                  onChange={onChange}
                />
                <BCheckBoxField
                  sm={12}
                  name='status'
                  label='فعال'
                  value={Number(currentItem?.status)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/customer-management/discounts',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default DiscountPage;
