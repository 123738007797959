import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _, { unset } from 'lodash';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Layout, theme } from 'antd';
import { Sidebar, Topbar, Footer } from './components';
import { DashboardPanelContext } from '../../components/context/DashboardContext';
import { SET_ERROR, SET_SAVE_SUCCESS } from '../../components/context/reducer/actionTypes';
import { useUserCredentialsContext } from '../../components/context/LoginContext';
import ConvertErrorsToHuman from '../../data/errors/backErrors';
import { BSwalShowError } from '../../common/BSwal';
import '../../assets/scss/index.scss';

const { Content } = Layout;

const Main = (props) => {
  const { globalState, globalDispatch } = useContext(DashboardPanelContext);
  const credentials = useUserCredentialsContext();
  const { error, saveSuccess } = globalState;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { children } = props;

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
  };

  const handleCloseError = (reason, index) => {
    if (reason === 'clickaway') {
      return;
    }
    const errors = error.messages;
    delete errors[index];
    globalDispatch({ type: SET_ERROR, value: errors });
  };

  useEffect(() => {
    if (error !== undefined) {
      if (error && (error.status === 409 || error.status === 500 || error.status === 503 || error.status === 403)) {
        BSwalShowError(_.get(error, 'messages', [])).then((result) => {
          if (result.value) {
            // globalDispatch({ type: SET_ERROR, value: null });
          }
        });
      }
    }
  }, [error]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Topbar />
      <Content
        style={{
          padding: '0',
          minHeight: '100%',
        }}
      >
        <Layout
          style={{
            padding: '0',
            background: colorBgContainer,
            minHeight: '100vh',
          }}
        >
          <Sidebar credentials={credentials} />
          <Content
            style={{
              padding: '24px',
              minHeight: '100%',
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={saveSuccess}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <Alert severity='success' onClose={handleClose}>
                اطلاعات با موفقیت ذخیره شد
              </Alert>
            </Snackbar>
            {_.get(error, 'messages', []).length > 0 ? (
              error.messages
                .filter((c) => c.fieldName === 'unknown')
                .map((errorItem, index) => (
                  <React.Fragment key={index}>
                    <Snackbar
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={!!errorItem}
                      autoHideDuration={5000}
                      onClose={(event, reason) => handleCloseError(reason, index)}
                    >
                      <Alert severity='error' onClose={(event, reason) => handleCloseError(reason, index)}>
                        {errorItem.fieldName}
                        <br />
                        {ConvertErrorsToHuman(errorItem.message.slice(0, 10))}
                      </Alert>
                    </Snackbar>
                  </React.Fragment>
                ))
            ) : (
              <div />
            )}
            {children}
          </Content>
        </Layout>
      </Content>
      <Footer />
    </Layout>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
