import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Input, Button as AntdButton, ColorPicker } from 'antd';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';

const initialItem = {
  name: '',
  attribute_category_id: null,
  data_type_id: null,
  unit_id: null,
  active: true,
  display: true,
  selectable: false,
};

const DataTypes = [
  {
    id: '1',
    name: 'ورودی',
  },
  {
    id: '3',
    name: 'تاریخ',
  },
  {
    id: '4',
    name: 'یک از دو',
  },
  {
    id: '5',
    name: 'یک از چند',
  },
  {
    id: '7',
    name: 'رنگ',
  },
];

const AttributePage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const { viewReadOnly } = globalState;
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/attribute',
    localStorageKey: 'attribute',
    initialItem,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/attribute-category`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1' && response.data?.data[i].id !== id) {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/attribute-category?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1' && r.data?.data[n].id !== id) {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setCategories(res);
      }
    });
    fetchItem({
      modelName: `/unit`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i] && response.data?.data[i].id !== id) {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/unit?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1' && r.data?.data[n].id !== id) {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setUnits(res);
      }
    });
  }, []);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const [textFields, setTextFields] = useState(['']); // Initialize with one empty text field

  const addTextField = () => {
    setTextFields([...textFields, '']);
  };

  const removeTextField = (index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields.splice(index, 1);
    setTextFields(updatedTextFields);
  };

  const handleChange = (value, index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = updatedTextFields[index].startsWith('#')
      ? updatedTextFields[index].slice(0, 7).concat(value)
      : value;
    setTextFields(updatedTextFields);
  };
  const handleColorChange = (value, index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value.concat(
      updatedTextFields[index].startsWith('#') ? updatedTextFields[index].slice(7) : updatedTextFields[index],
    );
    setTextFields(updatedTextFields);
  };

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (_currentItem.active && _currentItem.active !== 0) {
      _currentItem.active = 1;
    } else {
      _currentItem.active = 0;
    }
    if (_currentItem.display && _currentItem.display !== '0') {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    if (_currentItem.selectable && _currentItem.selectable !== '0') {
      _currentItem.selectable = '1';
    } else {
      _currentItem.selectable = '0';
    }
    if (
      String(_currentItem.data_type_id) === '4' ||
      String(_currentItem.data_type_id) === '5' ||
      String(_currentItem.data_type_id) === '7'
    ) {
      const options = [];
      if (textFields && textFields.length > 0) {
        textFields.forEach((text) => {
          if (text.length > 0) {
            options.push(text);
          }
        });
      }
      if (options.length > 0) {
        _currentItem.options = options;
      } else {
        BSwalShowError(['لطفا مقادیر را وارد کنید.']);
        setLoading(false);
        return;
      }
    } else {
      delete _currentItem.options;
    }
    saveItem('attribute', _currentItem, id)
      .then(() => navigate('/product-management/attributes'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  useEffect(() => {
    setTextFields([]);
  }, [currentItem?.data_type_id]);

  useEffect(() => {
    if (currentItem?.options && currentItem?.options.length > 0) {
      // const options = JSON.parse(currentItem?.options);
      const options = [];
      for (let i = 0; i < currentItem?.options.length; i++) {
        options.push(currentItem?.options[i].value);
      }
      setTextFields(options);
    }
  }, [currentItem?.options]);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/product-management/attributes',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات ویژگی' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={6}
                  label='عنوان'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب دسته'
                  name='attribute_category_id'
                  value={currentItem?.attribute_category_id}
                  onChange={onChange}
                  error={errors?.attribute_category_id}
                  items={convertArrayToKeyedObject(categories, 'id', 'name')}
                  readOnly={viewReadOnly || categories.length === 0}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب نوع داده'
                  name='data_type_id'
                  value={currentItem?.data_type_id}
                  onChange={onChange}
                  error={errors?.data_type_id}
                  items={convertArrayToKeyedObject(DataTypes, 'id', 'name')}
                  readOnly={viewReadOnly}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب واحد'
                  name='unit_id'
                  value={currentItem?.unit_id}
                  onChange={onChange}
                  error={errors?.unit_id}
                  items={convertArrayToKeyedObject(units, 'id', 'name')}
                  readOnly={viewReadOnly}
                />
                {(String(currentItem?.data_type_id) === '4' || String(currentItem?.data_type_id) === '5') && (
                  <Grid item xs={12}>
                    <div style={{ fontSize: '16px', width: '100%', marginBottom: '10px' }}> مقادیر</div>
                    <div style={{ width: '100%' }}>
                      {textFields.map((text, index) => (
                        <>
                          <Input
                            style={{ width: '80%', marginTop: '10px' }}
                            value={text}
                            onChange={(e) => handleChange(e.target.value, index)}
                          />
                          <AntdButton
                            style={{ marginRight: '5px' }}
                            type='primary'
                            icon={<MinusCircleOutlined />}
                            onClick={() => removeTextField(index)}
                          >
                            حذف
                          </AntdButton>
                        </>
                      ))}
                      <AntdButton
                        type='dashed'
                        style={{ marginRight: '5px' }}
                        icon={<PlusOutlined />}
                        onClick={addTextField}
                        disabled={String(currentItem?.data_type_id) === '4' && textFields.length === 2}
                      >
                        افزودن
                      </AntdButton>
                    </div>
                  </Grid>
                )}
                {String(currentItem?.data_type_id) === '7' && (
                  <Grid item xs={12}>
                    <div style={{ fontSize: '16px', width: '100%', marginBottom: '10px' }}> مقادیر</div>
                    <div style={{ width: '100%' }}>
                      {textFields.map((text, index) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Input
                            style={{ width: '80%', marginTop: '10px' }}
                            value={text.startsWith('#') ? text.slice(7) : text}
                            onChange={(e) => handleChange(e.target.value, index)}
                          />
                          <ColorPicker
                            defaultValue='#1677ff'
                            value={text.startsWith('#') ? text.slice(0, 7) : '#1677ff'}
                            onChange={(value, hex) => handleColorChange(hex, index)}
                            style={{ marginRight: '10px' }}
                          />
                          <AntdButton
                            style={{ marginRight: '5px' }}
                            type='primary'
                            icon={<MinusCircleOutlined />}
                            onClick={() => removeTextField(index)}
                          >
                            حذف
                          </AntdButton>
                        </div>
                      ))}
                      <AntdButton
                        type='dashed'
                        style={{ marginTop: '10px' }}
                        icon={<PlusOutlined />}
                        onClick={addTextField}
                      >
                        افزودن
                      </AntdButton>
                    </div>
                  </Grid>
                )}
                <BCheckBoxField
                  sm={12}
                  name='selectable'
                  label='قابل انتخاب توسط مشتری'
                  value={currentItem?.selectable === '0' ? false : currentItem?.selectable || false}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='نمایش داده شود'
                  value={currentItem?.display === '0' ? false : currentItem?.display || false}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='active'
                  label='انتشار'
                  value={currentItem?.active === 0 ? false : currentItem?.active || false}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/product-management/attributes',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default AttributePage;
