import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { SET_PAGE_LOADING, SET_SAVE_SUCCESS } from '../components/context/reducer/actionTypes';
import { DashboardPanelContext } from '../components/context/DashboardContext';
import { GetQueryStringValue, PropTypes } from '../common/utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBasicInformationMountedEffect = <T>({
  match,
  fetchItemURL,
  localStorageKey,
  initialItem,
}: {
  match: any;
  fetchItemURL: string;
  localStorageKey: string;
  initialItem: T;
}) => {
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);

  const [isConfirmation, setIsConfirmation] = useState(false);
  const [item, setItem] = useState<T>(() => {
    const storedItem = localStorage.getItem(localStorageKey);
    return storedItem ? JSON.parse(storedItem) : initialItem;
  });
  const [id, setId] = useState<number | string | null>(null);

  useEffect(() => {
    if (GetQueryStringValue('confirmation')) {
      setIsConfirmation(true);
    }
  }, []);

  useEffect(() => {
    const tempId = parseInt(_.get(match, 'params.id', '0'), 10);
    setId(tempId);

    if (tempId !== 0) {
      const _id = _.get(item, 'id') as string;
      if (typeof _id === 'string' && parseInt(_id, 10) === tempId) {
        setItem(item);
        if (match.params.id !== 'histories') {
          if (match.params.id) {
            globalDispatch({ type: SET_PAGE_LOADING, value: true });
            fetchItem<T>({ modelName: fetchItemURL, id: tempId })
              .then((r) => setItem(r!))
              .finally(() => {
                globalDispatch({ type: SET_PAGE_LOADING, value: false });
              });
          }
        }
      } else if (match.params.id !== 'histories') {
        if (match.params.id) {
          globalDispatch({ type: SET_PAGE_LOADING, value: true });
          fetchItem<T>({ modelName: fetchItemURL, id: tempId })
            .then((r) => setItem(r!))
            .finally(() => {
              globalDispatch({ type: SET_PAGE_LOADING, value: false });
            });
        }
      } else {
        setItem(initialItem);
      }
    } else {
      setItem(initialItem);
    }

    globalDispatch({ type: SET_SAVE_SUCCESS, value: false });

    return () => {
      setItem(initialItem);
      localStorage.removeItem(localStorageKey);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(item));
  }, [item, localStorageKey]);

  return {
    id,
    setItem,
    item,
    isConfirmation,
  };
};

useBasicInformationMountedEffect.propTypes = {
  match: PropTypes.object,
  fetchItemURL: PropTypes.string,
  localStorageKey: PropTypes.string,
  initialItem: PropTypes.object,
};

export default useBasicInformationMountedEffect;
