import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import Table from '../../../../components/tables/Table';

const initialItem = {
  order_number: '',
  created_at: '',
  user: {
    name: '',
    mobile: '',
  },
  address: {
    city: {
      name: '',
      province: {
        name: '',
      },
    },
    postal_code: '',
    address_detail: '',
  },
  order_status: '',
  address_id: '',
  cart_id: '',
  delivery_time_id: null,
  order_total_price: '',
  payment_id: '',
  payment: '',
  delivery_price: '',
  cart_items: [],
};

const OrderPage = (props) => {
  const { staticContext, ...rest } = props;
  const { fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [ordersStatus, setOrderStatus] = useState([]);
  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/orders',
    localStorageKey: 'orders',
    initialItem,
  });
  const [key, setKey] = useState(0);
  const [columns, setColumns] = useState([]);
  const tableRef = useRef(null);
  const [cartItems, setCartItems] = useState([]);
  const [priceTableKey, setPriceTableKey] = useState(0);
  const [priceTableColumns, setPriceTableColumns] = useState([]);
  const priceTableRef = useRef(null);
  const [priceTableItems, setPriceTableItems] = useState([]);

  useEffect(() => {
    fetchItem({
      modelName: `/order-status`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.length; i++) {
          allData.push({ ...response.data[i], key: response.data[i].id });
        }
        setOrderStatus(allData);
      }
    });
  }, []);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    _currentItem.order_status = Number(currentItem.order_status);
    if (!_currentItem.delivery_time_id) delete _currentItem.delivery_time_id;
    saveItem('/orders', _currentItem, id)
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  useEffect(() => {
    if (currentItem && currentItem.cart && currentItem.cart.items && currentItem.cart.items.length > 0) {
      const finalItems = [];
      for (let i = 0; i < currentItem.cart.items.length; i++) {
        const cartItem = currentItem.cart.items[i];
        const cartItemVariantAttributes = cartItem?.product_variant?.product_variant_attributes ?? [];
        let finalName = cartItem?.product?.name ?? '';
        for (let j = 0; j < cartItemVariantAttributes.length; j++) {
          const cartAtt = cartItemVariantAttributes[j];
          const attName = cartAtt?.attribute?.name ?? '';
          const attValue =
            cartAtt?.attribute?.data_type_id === 7
              ? cartAtt?.attribute_option?.value.slice(7) ?? ''
              : cartAtt?.attribute_option?.value ?? '';
          if (!(cartAtt?.attribute_id === 21 && cartAtt?.attribute_option_id === 129)) {
            const attUint = cartAtt?.attribute?.data_type_id === 7 ? '' : cartAtt?.attribute?.unit?.name ?? '';
            finalName += ` - ${attName}: ${attValue}${attUint ? `${attUint}` : ''}`;
          }
        }
        finalItems.push({
          name: finalName,
          brand: cartItem?.product?.brand?.name ?? '',
          category: cartItem?.product?.category?.name ?? '',
          price: Number(cartItem?.product_variant?.base_price ?? 0) || Number(cartItem?.product?.base_price ?? 0) || '',
          offPrice: Number(cartItem?.product_variant?.base_price ?? 0)
            ? Number(cartItem?.product_variant?.off_price ?? 0) || 'بدون تخفیف'
            : Number(cartItem?.product?.off_price ?? 0) || 'بدون تخفیف',
          quantity: cartItem?.quantity ?? '',
          key: `${cartItem?.cart_id ?? 0}-${cartItem?.id ?? 0}-${cartItem?.product?.id ?? 0}`,
        });
      }
      setCartItems(finalItems);
      const finalPriceItem = {
        orderTotalPrice: Number(currentItem?.order_total_price ?? 0),
        deliveryPayment: Number(currentItem?.delivery_price ?? 0),
        discountCode: '',
        discountCodeValue: '',
        orderPayment: currentItem?.payment?.price ?? '',
        referenceId: currentItem?.payment?.reference_id,
      };
      setPriceTableItems([finalPriceItem]);
    } else {
      setCartItems([]);
    }
  }, [currentItem]);

  useEffect(() => {
    setColumns([
      {
        title: 'نام محصول',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'برند',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'دسته والد',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'قیمت (تومان)',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'قیمت بعد از تخفیف محصول (تومان)',
        dataIndex: 'offPrice',
        key: 'offPrice',
      },
      {
        title: 'تعداد',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ]);
    setKey(key + 1);
  }, []);

  useEffect(() => {
    setPriceTableColumns([
      {
        title: 'مبلغ کل سفارش (تومان)',
        dataIndex: 'orderTotalPrice',
        key: 'orderTotalPrice',
      },
      {
        title: ' کد تخفیف',
        dataIndex: 'discountCode',
        key: 'discountCode',
      },
      {
        title: 'مبلغ تخفیف کد تخفیف',
        dataIndex: 'discountCodeValue',
        key: 'discountCodeValue',
      },
      {
        title: 'هزینه ارسال (تومان)',
        dataIndex: 'deliveryPayment',
        key: 'deliveryPayment',
      },
      {
        title: 'مبلغ پرداخت شده (تومان)',
        dataIndex: 'orderPayment',
        key: 'orderPayment',
      },
      {
        title: 'کد پیگیری پرداخت',
        dataIndex: 'referenceId',
        key: 'referenceId',
      },
    ]);
    setPriceTableKey(key + 1);
  }, []);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/order-management/orders',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='جزییات سفارش' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={3}
                  label='عنوان'
                  name='order_number'
                  value={currentItem?.order_number}
                  onChange={onChange}
                  readOnly
                  error={errors?.order_number}
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='نام و نام خانوادگی مشتری'
                  name='order_number'
                  value={currentItem?.user?.name}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='شماره موبایل مشتری'
                  name='user.mobile'
                  value={currentItem?.user?.mobile}
                  onChange={onChange}
                  readOnly
                />
                <BDateFieldTable
                  xs={12}
                  sm={3}
                  name='create_at'
                  label='تاریخ ثبت سفارش'
                  error={errors?.created_at}
                  value={currentItem?.created_at}
                  onChange={onChange}
                  readOnly
                />
                <div style={{ height: '30px', width: '100%' }} />
                <div style={{ height: '30px', width: '100%' }}>جزییات آدرس گیرنده</div>
                <BTextField
                  xs={12}
                  sm={3}
                  label='استان'
                  name='address.city.province.name'
                  value={currentItem?.address?.city?.province?.name}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='شهر'
                  name='address.city.name'
                  value={currentItem?.address?.city?.name}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='کد پستی'
                  name='address.postal_code'
                  value={currentItem?.address?.postal_code}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={12}
                  label='نشانی دقیق گیرنده'
                  name='address.address_detail'
                  value={currentItem?.address?.address_detail}
                  onChange={onChange}
                  readOnly
                />
                <BSelectField
                  sm={3}
                  xs={12}
                  label='وضعیت سفارش'
                  name='order_status'
                  value={currentItem?.order_status}
                  onChange={onChange}
                  items={convertArrayToKeyedObject(ordersStatus, 'id', 'status')}
                />
                <Grid item xs={12} sm={6} style={{ marginTop: '40px' }}>
                  <BSubmitButton id='changeOrderStatus' label='ویرایش' loading={loading} />
                </Grid>
              </Grid>
              <ListsContainer key='basket'>
                <Table
                  tableKey={key}
                  key={key}
                  title='جزییات سبد'
                  columns={columns}
                  createSlug=''
                  actions={[]}
                  createAction={undefined}
                  tableRef={tableRef}
                  data={cartItems}
                  pageSize={10}
                  filtering={false}
                  totalPages={undefined}
                  getOtherPageData={undefined}
                />
              </ListsContainer>
              <ListsContainer key='price'>
                <Table
                  tableKey={priceTableKey}
                  key={priceTableKey}
                  title='جزییات پرداخت'
                  columns={priceTableColumns}
                  createSlug=''
                  actions={[]}
                  createAction={undefined}
                  tableRef={priceTableRef}
                  data={priceTableItems}
                  filtering={false}
                  totalPages={undefined}
                  getOtherPageData={undefined}
                />
              </ListsContainer>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/order-management/orders',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default OrderPage;
