import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CkeditorField from '../../../../components/inputs/CkeditorField';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';

const cookies = new Cookies();

const initialItem = {
  name: '',
  link: '',
  display: true,
  setting_section_id: null,
  type: 'text',
  value_text: '',
  value: '',
  image: null,
};

const settingType = [
  {
    key: 'text',
    name: 'ادیتور',
  },
  {
    key: 'string',
    name: 'متن',
  },
  {
    key: 'file',
    name: 'عکس',
  },
];
const url = process.env.REACT_APP_SITE_URL;

const SettingsPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const [sections, setSections] = useState([]);
  const [sliderImage, setSliderImage] = useState(null);
  const [textEditor, setTextEditor] = useState('');
  const { viewReadOnly } = globalState;
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/settings',
    localStorageKey: 'settings',
    initialItem,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/setting-sections`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/setting-sections?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setSections(res);
      }
    });
  }, []);

  const fileSelectedHandlerLoad = async (e) => {
    setSliderImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setSliderImage({ img: imgSrc });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
    if (currentItem.id && currentItem.attachments && currentItem.attachments[0]) {
      fileSelectedHandlerLoad(currentItem.attachments[0].path);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    if (currentItem.image) {
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      const formData = new FormData();
      for (let i = 0; i < Object.keys(_currentItem).length; i++) {
        formData.append(
          Object.keys(_currentItem)[i],
          Object.values(_currentItem)[i] ? Object.values(_currentItem)[i] : '',
        );
      }
      if (id) {
        formData.append('_method', 'put');
      }
      axios({
        method: 'post',
        url: id ? 'settings/'.concat(id) : 'settings',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
        .then((res) => {
          if (res.data.data) {
            navigate('/cms/settings');
          }
          if (res?.data?.error?.email) {
            BSwalShowError(res?.data?.error?.email);
          }
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    } else {
      saveItem('settings', _currentItem, id)
        .then(() => navigate('/cms/settings'))
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    }
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const fileSelectedHandler = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setSliderImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setSliderImage({ img: reader.result });
    };
  };

  useEffect(() => {
    setCurrentItem({ ...currentItem, value_text: textEditor });
  }, [textEditor]);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/cms/settings',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات تنظیمات' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                {!currentItem.id && (
                  <BTextField
                    xs={12}
                    sm={12}
                    label='نام آیتم '
                    name='name'
                    value={currentItem?.name}
                    onChange={onChange}
                    readOnly={viewReadOnly || currentItem?.id}
                    error={errors?.name}
                    required
                    // {...register('name', { required: true })}
                  />
                )}
                <BTextareaField
                  onChange={onChange}
                  name='description'
                  sm={12}
                  xs={12}
                  value={currentItem?.description}
                  label='عنوان'
                  readOnly={viewReadOnly || currentItem?.id}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='لینک به صفحه'
                  name='link'
                  value={currentItem?.link}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.link}
                />
                {!currentItem.id && (
                  <BSelectField
                    sm={6}
                    xs={12}
                    label='انتخاب قسمت تنظیمات'
                    required
                    name='setting_section_id'
                    value={currentItem?.setting_section_id}
                    onChange={onChange}
                    error={errors?.setting_section_id}
                    readOnly={viewReadOnly || currentItem?.id}
                    items={convertArrayToKeyedObject(sections, 'id', 'name')}
                  />
                )}
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب نوع'
                  required
                  name='type'
                  value={currentItem?.type}
                  onChange={onChange}
                  error={errors?.type}
                  items={convertArrayToKeyedObject(settingType, 'key', 'name')}
                  readOnly={viewReadOnly || currentItem?.id}
                />
                {currentItem?.type === 'text' && isLayoutReady && (
                  <Grid item xs={12} style={{ direction: 'rtl' }}>
                    <CkeditorField value={currentItem.content} onChange={(data) => setTextEditor(data)} />
                  </Grid>
                )}
                {currentItem?.type === 'string' && (
                  <BTextField
                    xs={12}
                    sm={6}
                    label='محتوای متن'
                    name='value'
                    value={currentItem?.value}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.value}
                  />
                )}
                {currentItem?.type === 'file' && (
                  <Grid item xs={12} sm={12}>
                    <CardHeader title='انتخاب عکس' style={{ padding: '0' }} />
                    <span>
                      اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا
                      میباشد.
                    </span>
                    <br />
                    <input
                      type='file'
                      accept='.png, .jpg, .jpeg, .webp'
                      onChange={fileSelectedHandler}
                      style={{ marginBottom: '15px', marginTop: '15px' }}
                    />
                    {sliderImage?.img && (
                      <img alt='slider' src={sliderImage?.img} style={{ width: '200px', height: '200px' }} />
                    )}
                  </Grid>
                )}
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='نمایش داده شود'
                  value={Number(currentItem?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/cms/settings',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default SettingsPage;
