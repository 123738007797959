import React from 'react';
import AddIcon from '@mui/icons-material/Add';

import Locale from '../../helpers/Locale';
import { Default } from '../../data/Dictionary';
import styles from '../../assets/scss/modules/slidingButtonStyle.module.scss';

/**
 *
 * @param {Object} history
 * @param {String} location
 * @param {function} onClick
 * @param {String} className
 * @param {String} label
 * @returns {HTMLDivElement} It will return a sliding button
 * @constructor
 */
export default function SlidingButton({ onClick, className, label, id = null, style = {} }) {
  const Language = Locale();

  return (
    <div
      aria-hidden='true'
      // style={style}
      id={id}
      // className={`${styles.container} ${className}__container`}
      style={{
        width: 'auto',
        background: 'rgba(188, 138, 101, 1)',
        color: '#fff',
        cursor: 'pointer',
        margin: '0 10px',
        padding: '0 17px',
      }}
      onClick={onClick}
    >
      {/* <div className={`${styles.plus} ${className}__plus`} id={id}>
        <AddIcon />
      </div> */}
      {/* <div className={`${styles.title} ${className}__title`}>
        <span>{label || Default[Language].addNewItem}</span>
      </div> */}
      <div style={{ float: 'right' }}>
        <AddIcon style={{ marginTop: '8px', fontSize: '17px', marginLeft: '4px' }} />
      </div>
      <div style={{ float: 'right', marginTop: '6px' }}>
        <span>{label || Default[Language].addNewItem}</span>
      </div>
    </div>
  );
}
