export const showState = (state) => {
  switch (state) {
    case 'REVIEWING':
      return 'در حال بررسی';
    case 'APPROVED':
      return 'تایید شده';
    case 'REGISTERED':
      return 'ثبت شده';
    case 'REJECTED':
      return 'رد شده';
    case 'PARAMETER_APPROVED':
      return 'پارامتر تایید شده';
    case 'VALUE_APPROVED':
      return 'مقدار تایید شده';
    case 'PARAMETER_REVIEWING':
      return 'در حال بررسی';
    case 'PROVINCE':
      return 'استان';
    case 'CITY':
      return 'شهر';
    case 'GUILD':
      return 'صنف';
    case 'OWNERSHIP_TYPE':
      return 'نوع مالکیت';
    case 'LAND_OWNER':
      return 'مالک';
    case 'RENTER':
      return 'مستاجر';
    case 'REPUTATION':
      return 'شهرت و اعتبار';
    case 'GOOD':
      return 'خوب';
    case 'BAD':
      return 'بد';
    case 'PERFECT':
      return 'عالی';
    case 'MEDIOCRE':
      return 'متوسط';
    case 'VERY_BAD':
      return 'خیلی بد';
    case 'MARITAL_STATUS':
      return 'وضعیت تاهل';
    case 'SINGLE':
      return 'مجرد';
    case 'MARRIED':
      return 'متاهل';
    case 'DIVORCED':
      return 'جدا شده';
    case 'GENDER':
      return 'جنسیت';
    case 'MALE':
      return 'مرد';
    case 'FEMALE':
      return 'زن';
    case 'PROMISSORY_NOTE':
      return 'سفته';
    case 'CHECK':
      return 'چک';
    case 'DOCUMENT_PROPERTY':
      return 'سند ملکی';
    case 'BANK_DOCUMENT':
      return 'ضمانت نامه بانکی';
    case 'RESIDENTIAL':
      return 'مسکونی';
    case 'OFFICIAL':
      return 'اداری';
    case 'RECREATIONAL':
      return 'تفریحی';
    case 'MIXED':
      return 'مختلط';
    case 'PHONE':
      return 'تلفن';
    case 'WHATSAPP':
      return 'واتس آپ';
    case 'MOBILE':
      return 'موبایل';
    case 'TELEGRAM':
      return 'تلگرام';
    case 'MORNING':
      return 'صبح';
    case 'NOON':
      return 'ظهر';
    case 'NIGHT':
      return 'شب';
    case 'AFTERNOON':
      return 'عصر';
    case 'SELLER':
      return 'فروشنده';
    case 'PURCHASER':
      return 'مسئول سفارش';
    case 'RECEIVER':
      return 'تحویل گیرنده';
    case 'EXPORT_DEVELOPMENT_BANK_OF_IRAN':
      return 'بانک توسعه صادرات ایران';
    case 'PARSIAN':
      return 'پـارسیان';
    case 'MASKAN':
      return 'مسکن';
    case 'CASPIAN_CREDIT_INSTITUTION':
      return 'موسسه اعتباری کاسپین';
    case 'MIDDLE_EAST':
      return ' خاورمیانه';
    case 'KOSAR_CREDIT_INSTITUTION':
      return ' موسسه اعتباری کوثر';
    case 'POST_BANK':
      return 'پست بانک';
    case 'HEKMAT_IRANIAN':
      return ' حکمت ایرانیان';
    case 'ANSAR':
      return ' انصار';
    case 'SEPAH':
      return ' سپه';
    case 'BANK_OF_INDUSTRY_AND_MINE':
      return ' بانک صنعت و معدن';
    case 'TOSE_E_CREDIT_INSTITUTION':
      return 'موسسه اعتباری توسعه ';
    case 'SARMAYEH':
      return 'سرمایه';
    case 'SAMAN':
      return 'سامان';
    case 'IRAN_ZAMIN':
      return 'ایران زمین';
    case 'MELLAL_CREDIT_INSTITUTION':
      return ' موسسه اعتباری ملل';
    case 'MELLAT':
      return 'ملت ';
    case 'GHAVAMIN':
      return 'قوامین ';
    case 'EGHTESAD_NOVIN':
      return 'اقتصاد نوین ';
    case 'SHAHR':
      return ' شهر ';
    case 'TOURISM':
      return ' توریسم ';
    case 'TEJARAT':
      return ' تجارت ';
    case 'KARAFARIN':
      return ' کارآفرین ';
    case 'MELLI':
      return ' ملی ';
    case 'AYANDEH':
      return ' آینده ';
    case 'SINA':
      return ' سینا ';
    case 'PASARGAD':
      return ' پاسارگاد ';
    case 'KESHAVARZI':
      return ' کشاورزی ';
    case 'SADERAT':
      return ' صادرات ';
    case 'REFAH':
      return ' رفاه ';
    case 'NOR_CREDIT_INSTITUTION':
      return ' موسسه اعتباری نور ';
    case 'DAY':
      return ' دی';
    case 'TOSE_E_TA_AVON':
      return ' توسعه تعاون';
    case 'LONG_TERM_INVESTMENT':
      return ' سرمایه گذاری بلند مدت';
    case 'INTEREST_FREE_CURRENT':
      return '    جاری';
    case 'SHORT_TERM_INVESTMENT':
      return ' سرمایه گذاری کوتاه مدت';
    case 'INTEREST_FREE_SAVING':
      return ' قرض الحسنه ';
    case 'parameterName':
      return 'نام پارامتر';
    case 'SALARY':
      return ' حقوق ';
    case 'Child allowance':
      return ' حق اولاد ';
    case 'LEAVE':
      return ' ترک کار ';
    case 'SEVERANCE_PAY':
      return ' سنوات ';
    case 'BONUS':
      return 'عیدی';
    default:
      return state;
  }
};
