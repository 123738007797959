import React, { useContext, useEffect, useRef, useState } from 'react';
import { CardActions, CardContent, Divider, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';
import { onChangeForBComponents } from '../../../../common/utilities';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSubmitButton from '../../../../components/buttons/SubmitButton';

const cookies = new Cookies();

const ProductCommentsList = () => {
  const { handleSubmit, setValue } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { saveItem, fetchItem } = useContext(DashboardPanelContext);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [productName, setProductName] = useState('');
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState({
    message: '',
    status: '0',
    show: '0',
    id: '',
    content: '',
    parent_id: null,
    parentMessage: '',
  });

  const showModal = (_id) => {
    const _comment = data.find((com) => Number(com.id) === _id);
    const _parent = [];
    if (_comment.parent_id) {
      _parent.push(data.find((com) => Number(com.id) === _comment.parent_id));
    }
    setComment({
      message: _comment.content,
      show: _comment.show,
      status: _comment.status,
      id: _comment.id,
      content: '',
      parent_id: _comment.parent_id,
      parentMessage: _parent.length > 0 ? _parent[0].content : null,
    });
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setComment({
      message: '',
      status: '0',
      show: '0',
      id: '',
      content: '',
      parent_id: null,
      parentMessage: '',
    });
    setIsModalOpen(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setComment({
      message: '',
      status: '0',
      show: '0',
      id: '',
      content: '',
      parent_id: null,
      parentMessage: '',
    });
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchItem({
      modelName: `/product/get-comments/${id}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          res.push(response.data?.data[i]);
        }
        const number_of_pages = response.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        setData(res);
      }
    });
    fetchItem({
      modelName: `/product/${id}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setProductName(response.data?.name);
      }
    });

    fetchItem({
      modelName: `/users`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          res.push(response.data.data[i]);
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/users?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                res.push(r.data?.data[n]);
              }
            });
          }
        }
        setUsers(res);
      }
    });
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: 'کاربر',
        dataIndex: 'customer_id',
        key: 'customer_id',
        render: (customerId) => users.find((value) => Number(value.id) === Number(customerId))?.name,
      },
      {
        title: 'متن پیام',
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: 'نوع کامنت',
        dataIndex: 'parent_id',
        key: 'parent_id',
        type: 'boolean',
        filters: [
          {
            text: 'کامنت اصلی',
            value: null,
          },
          {
            text: 'پاسخ',
            value: 0,
          },
        ],
        onFilter: (value, record) => (value === 0 ? record.parent_id : record.parent_id === value),
        sorter: (a, b) => Number(a.parent_id) - Number(b.parent_id),
        sortDirections: ['descend'],
        render: (rowData) => (rowData ? <span>پاسخ</span> : <span>کامنت اصلی</span>),
      },
      {
        title: 'وضعیت',
        dataIndex: 'display',
        key: 'display',
        type: 'boolean',
        filters: [
          {
            text: 'فعال',
            value: '1',
          },
          {
            text: 'غیر فعال',
            value: '0',
          },
        ],
        onFilter: (value, record) => record.status === value,
        sorter: (a, b) => Number(a.status) - Number(b.status),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
      {
        title: 'وضعیت تایید',
        dataIndex: 'status',
        key: 'status',
        type: 'boolean',
        filters: [
          {
            text: 'فعال',
            value: '1',
          },
          {
            text: 'غیر فعال',
            value: '0',
          },
        ],
        onFilter: (value, record) => record.status === value,
        sorter: (a, b) => Number(a.status) - Number(b.status),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
      // {
      //   title: 'نمایش در صفحه اصلی',
      //   dataIndex: 'show',
      //   key: 'show',
      //   type: 'boolean',
      //   filters: [
      //     {
      //       text: 'فعال',
      //       value: '1',
      //     },
      //     {
      //       text: 'غیر فعال',
      //       value: '0',
      //     },
      //   ],
      //   onFilter: (value, record) => record.show === value,
      //   sorter: (a, b) => Number(a.show) - Number(b.show),
      //   sortDirections: ['descend'],
      //   render: EnabledColumn,
      // },
    ]);
    setKey(key + 1);
  }, [users]);

  const actions = useTableActions({
    updatePermission: 'update.comment.status',
    deletePermission: 'comments.delete',
    editURL: 'modal',
    removePATH: 'delete-comment',
    createPermission: '',
    viewPermission: '',
  });

  const onChangeGetOtherPageData = (page) => {
    fetchItem({
      modelName: `/product/get-comments/?page=${page}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setData(response.data?.data);
      }
    });
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, comment, setComment);
  };

  const onSubmit = () => {
    setLoading(true);
    const _comment = { ...comment };
    if (comment.show && comment.show !== '0') {
      _comment.show = '1';
    } else {
      _comment.show = '0';
    }
    if (comment.status && comment.status !== '0') {
      _comment.status = '1';
    } else {
      _comment.status = '0';
    }
    const _data = {
      status: _comment.status,
      show: _comment.show,
    };
    saveItem(`/comments`, _data, comment.id).finally(() => {
      if (_comment.content) {
        const _commentData = {
          parent_id: _comment.id,
          content: _comment.content,
        };
        axios.defaults.baseURL = `${process.env.REACT_APP_URL}`;
        axios({
          method: 'post',
          url: `/product/add-comment/${id}`,
          data: _commentData,
          headers: {
            Authorization: `Bearer ${cookies.get('userToken')}`,
          },
        }).finally(() => {
          setLoading(false);
          handleOk();
        });
      } else {
        setLoading(false);
        handleOk();
      }
    });
  };

  return (
    <ListsContainer>
      <Button
        id='backToList'
        onClick={() => navigate('/product-management/products')}
        color='secondary'
        variant='outlined'
      >
        بازگشت به لیست محصولات
      </Button>
      <Table
        key={key}
        title={`لیست نظرات محصول ${productName}`}
        columns={columns}
        createSlug=''
        actions={actions}
        createAction={undefined}
        onRowClick
        tableRef={tableRef}
        data={data}
        pageSize={10}
        filtering={false}
        sortableColumns
        totalPages={totalPageNumber}
        getOtherPageData={onChangeGetOtherPageData}
        handleOpenModal={(_id) => showModal(_id)}
      />
      <Modal title='اطلاعات نظر ثبت شده' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
          <Divider />
          <CardContent>
            <br />
            <Grid container spacing={3}>
              <BTextareaField xs={12} sm={12} label='متن پیام' name='message' value={comment?.message} readOnly />
              {!comment.parent_id && (
                <BTextareaField
                  xs={12}
                  sm={12}
                  label='پاسخ'
                  name='content'
                  value={comment?.content}
                  onChange={onChange}
                />
              )}
              {comment.parentMessage && (
                <BTextareaField
                  xs={12}
                  sm={12}
                  label='در پاسخ به'
                  name='parentMessage'
                  value={comment?.parentMessage}
                  onChange={onChange}
                  readOnly
                />
              )}
              <BCheckBoxField
                sm={12}
                name='status'
                label='تایید نظر ثبت شده'
                value={Number(comment?.status)}
                onChange={onChange}
              />
              {/* <BCheckBoxField
                sm={12}
                name='show'
                label='نمایش نظر ثبت شده در صفحه اصلی'
                value={Number(comment?.show)}
                onChange={onChange}
              /> */}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
              <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />
            </Grid>
          </CardActions>
        </form>
      </Modal>
    </ListsContainer>
  );
};

export default ProductCommentsList;
