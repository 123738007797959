/* eslint-disable prefer-destructuring */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { CardContent, Grid } from '@mui/material';
import moment from 'moment-jalaali';
import { useForm } from 'react-hook-form';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY, SET_SAVE_SUCCESS } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import { onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';

const initialItem = {
  from_date: '',
  to_date: '',
  order_status: '',
  mobile: '',
  order_number: '',
};

const OrderList = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [searchItems, setSearchItems] = useState(initialItem);
  const { errors, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [ordersStatus, setOrderStatus] = useState([]);
  const [searchMode, setSearchMode] = useState(false);

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, searchItems, setSearchItems);
  };

  const onLoadData = useCallback(() => {
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        const number_of_pages = response.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        for (let i = 0; i < response.data?.data.length; i++) {
          allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
        }
        setData(allData);
      }
    });
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/order-status`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.length; i++) {
          allData.push({ ...response.data[i], key: response.data[i].id });
        }
        setOrderStatus(allData);
      }
    });
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        const number_of_pages = response.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        for (let i = 0; i < response.data?.data.length; i++) {
          allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
        }
        setData(allData);
      }
    });
  }, [onLoadData]);

  useEffect(() => {
    setColumns([
      {
        title: 'شماره سفارش',
        dataIndex: 'order_number',
        key: 'order_number',
      },
      {
        title: 'شماره موبایل',
        dataIndex: 'user',
        key: 'user',
        render: (item) => item.mobile,
      },
      {
        title: 'تاریخ ثبت سفارش',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (item) => moment(item).format('jYYYY/jM/jD HH:mm:ss'),
      },
      {
        title: 'مجموع قیمت (تومان)',
        dataIndex: 'order_total_price',
        key: 'order_total_price',
        render: (item) => Number(item).toLocaleString(),
      },
      {
        title: 'وضعیت',
        dataIndex: 'status',
        key: 'status',
        render: (rowData) => rowData.status,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'product.update',
    deletePermission: 'product.destroy',
    editURL: '/order-management/order/',
    removePATH: 'orders',
    createPermission: '',
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate('/order-management/order');
  };

  const onChangeGetOtherPageData = (page) => {
    fetchItem({
      modelName: searchMode ? `/orders/search?page=${page}` : `/orders?page=${page}`,
      showLoader: true,
    }).then((response) => {
      if (searchMode) {
        if (response) {
          const allData = [];
          const number_of_pages = response.data?.data?.last_page;
          setTotalPageNumber(number_of_pages * 10);
          for (let i = 0; i < response.data?.data?.data.length; i++) {
            allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
          }
          setData(allData);
        }
      } else if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.data.length; i++) {
          allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
        }
        setData(allData);
      }
    });
  };

  const onSubmit = useCallback(() => {
    setLoading(true);
    setSearchMode(true);
    const _searchItems = {};
    if (searchItems.from_date) {
      const dateTimeStringFrom = searchItems.from_date;
      _searchItems.from_date = dateTimeStringFrom.split(' ')[0];
    }

    if (searchItems.to_date) {
      const dateTimeStringTo = searchItems.to_date;
      _searchItems.to_date = dateTimeStringTo.split(' ')[0];
    }

    if (searchItems.mobile) {
      _searchItems.mobile = searchItems.mobile;
    }

    if (searchItems.order_number) {
      _searchItems.order_number = searchItems.order_number;
    }

    if (searchItems.order_status) {
      _searchItems.order_status = searchItems.order_status;
    }
    saveItem('orders/search', _searchItems, null)
      .then((response) => {
        globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
        if (response) {
          const allData = [];
          const number_of_pages = response.data?.data?.last_page;
          setTotalPageNumber(number_of_pages * 10);
          for (let i = 0; i < response.data?.data?.data.length; i++) {
            allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
          }
          setData(allData);
        }
      })
      .finally(() => setLoading(false));
  }, [searchItems]);

  const onResetSearch = useCallback(() => {
    setSearchMode(false);
    onLoadData();
    setSearchItems(initialItem);
  }, [onLoadData]);

  return (
    <>
      <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={3}>
            <BDateFieldTable
              name='from_date'
              label='از تاریخ'
              value={searchItems.from_date}
              onChange={onChange}
              required
              key='from_date'
              xs={12}
              sm={3}
            />
            <BDateFieldTable
              name='to_date'
              label='تا تاریخ'
              value={searchItems.to_date}
              onChange={onChange}
              required
              key='to_date'
              xs={12}
              sm={3}
            />
            <BTextField
              xs={12}
              sm={3}
              label='شماره موبایل'
              name='mobile'
              value={searchItems.mobile}
              onChange={onChange}
            />
            <BTextField
              xs={12}
              sm={3}
              label='شماره سفارش'
              name='order_number'
              value={searchItems.order_number}
              onChange={onChange}
            />
            <BSelectField
              sm={3}
              xs={12}
              label='وضعیت سفارش'
              name='order_status'
              value={searchItems?.order_status}
              onChange={onChange}
              error={errors?.data_type_id}
              items={convertArrayToKeyedObject(ordersStatus, 'id', 'status')}
            />
            <Grid item xs={12} sm={3}>
              <BSubmitButton id='submitSearch' label='جستجو' loading={loading} style={{ marginTop: '40px' }} />{' '}
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
              <Button id='resetSearch' loading={loading} style={{ marginTop: '40px' }} onClick={onResetSearch}>
                نمایش همه
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
      <ListsContainer>
        <Table
          tableKey={key}
          key={key}
          title='لیست سفارشات'
          columns={columns}
          createSlug='افزودن به سفارشات'
          actions={actions}
          createAction={handleCreate}
          tableRef={tableRef}
          data={data}
          pageSize={pageSize}
          filtering={false}
          sortableColumns
          totalPages={totalPageNumber}
          getOtherPageData={onChangeGetOtherPageData}
        />
      </ListsContainer>
    </>
  );
};

export default OrderList;
