import React from 'react';

interface Props {}
const DontHavePermission: React.FC<Props> = () => (
  <div
    style={{
      fontSize: 58,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    شما دسترسی به این صفحه را ندارید
  </div>
);

export default DontHavePermission;
