import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

// const useStyles = makeStyles(() => ({
//   buttonProgress: {
//     position: 'relative',
//     top: 7,
//   },
// }));
const BSubmitButton = React.forwardRef((props, ref) => {
  const { loading, id, label, disabled = false, style = {}, onSubmit = () => null, onClick = () => null } = props;
  return (
    <>
      <Button
        style={style}
        ref={ref}
        type='submit'
        variant='outlined'
        color='primary'
        disabled={loading || disabled}
        id={id}
        onClick={onClick}
        onSubmit={onSubmit}
      >
        {label}
        {loading && <CircularProgress style={{ marginTop: -14, marginRight: 10 }} size={24} />}
      </Button>
    </>
  );
});

BSubmitButton.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.any,
  style: PropTypes.any,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BSubmitButton;
