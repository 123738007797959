import React from 'react';
import { InputLabel, Tooltip } from '@mui/material';
// import { makeStyles } from '@mui/material/styles';
import { InfoOutlined } from '@mui/icons-material/InfoOutlined';
import { PropTypes, ReactFormHookServerError } from '../common/utilities';

const IconWithTooltip = ({ title }) => (
  <Tooltip color='primary' style={{ position: 'absolute', marginTop: -3 }} title={title}>
    <InfoOutlined />
  </Tooltip>
);

IconWithTooltip.propTypes = {
  title: PropTypes.string,
};

const BInputLabel = ({ error, serverError, name, label, tooltip, required = false }) =>
  label ? (
    <InputLabel
      error={error?.message || ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
      htmlFor={`input--${name}`}
      style={{ marginBottom: '10px', fontSize: '16px' }}
    >
      {label.trim()}
      {required && <span style={{ color: 'red' }}> *</span>}
      {tooltip && <IconWithTooltip title={tooltip} />}
    </InputLabel>
  ) : (
    ''
  );
BInputLabel.propTypes = {
  error: PropTypes.object,
  serverError: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default React.memo(BInputLabel);
