import React from 'react';
import { Typography } from '@mui/material';

const Footer = () => (
  <div style={{ padding: '10px', textAlign: 'center', borderTop: '1px solid #ddd' }}>
    <Typography variant='body1' />
    <Typography variant='caption'>
      All rights reserved by Bitweb Studio <span>&copy;</span>{' '}
    </Typography>
  </div>
);

export default Footer;
