import { useContext, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { cleanCookies } from 'universal-cookie/lib/utils';
import { DashboardPanelContext } from '../../components/context/DashboardContext';
import { SET_INITIAL_STATE } from '../../components/context/reducer/actionTypes';

const cookies = new Cookies();

const Logout = () => {
  const navigate = useNavigate();
  const { globalDispatch, initialStateZero } = useContext(DashboardPanelContext);

  useEffect(() => {
    axios.defaults.baseURL = `${process.env.REACT_APP_URL}`;
    axios({
      method: 'post',
      url: `logout`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then(() => {
        cleanCookies();
        globalDispatch({ type: SET_INITIAL_STATE, value: initialStateZero });
        localStorage.clear();
      })
      .catch((err) => {
        cleanCookies();
        globalDispatch({ type: SET_INITIAL_STATE, value: initialStateZero });
        localStorage.clear();
      });
  }, []);

  navigate('/login');
};

export default Logout;
