/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BMultiSelectField from '../../../../components/inputs/forms/BMultiSelectField';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';

const initialItem = {
  name: '',
  description: '',
  service_id: null,
  main_video: null,
  image: null,
  display: true,
};

const cookies = new Cookies();
const url = process.env.REACT_APP_SITE_URL;

const VideoPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [services, setServices] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [sliderImage, setSliderImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/videos',
    localStorageKey: 'videos',
    initialItem,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/services`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/services?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setServices(res);
      }
    });
  }, []);

  useEffect(() => {
    if (id) {
      fetchItem({
        modelName: `/videos`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (response.data?.data[i].display === '1' && Number(response.data?.data[i]?.id) !== Number(id)) {
              res.push(response.data?.data[i]);
            }
          }
          if (response.data?.last_page > 1) {
            for (let j = 2; j <= response.data?.last_page; j++) {
              // eslint-disable-next-line no-await-in-loop
              await fetchItem({
                modelName: `/videos?page=${j}`,
                showLoader: true,
              }).then((r) => {
                for (let n = 0; n < r.data?.data?.length; n++) {
                  if (r.data?.data[n].display === '1' && Number(r.data?.data[n]?.id) !== Number(id)) {
                    res.push(r.data?.data[n]);
                  }
                }
              });
            }
          }
          setVideos(res);
        }
      });
    }
  }, [id]);
  const fileSelectedHandlerLoad = async (e) => {
    setSliderImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setSliderImage({ img: imgSrc });
  };

  const fileSelectedHandlerLoadImage = async (e) => {
    setCoverImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setCoverImage({ img: imgSrc });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  useEffect(() => {
    if (currentItem?.id && currentItem?.attachments && currentItem?.attachments.length > 0) {
      const _image = currentItem.attachments.find((attachment) => attachment.slug === 'image');
      if (_image) {
        fileSelectedHandlerLoadImage(_image.path);
      }
      const _video = currentItem.attachments.find((attachment) => attachment.slug === 'main_video');
      if (_video) {
        fileSelectedHandlerLoad(_video.path);
      }
    }
    if (currentItem?.related_videos) {
      const _videos = [];
      for (let i = 0; i < currentItem?.related_videos.length; i++) {
        _videos.push(currentItem?.related_videos[i].id);
      }
      setSelectedVideos(_videos);
    }
  }, [currentItem?.id]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    delete _currentItem.attachments;
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const formData = new FormData();
    for (let i = 0; i < Object.keys(_currentItem).length; i++) {
      formData.append(
        Object.keys(_currentItem)[i],
        Object.values(_currentItem)[i] ? Object.values(_currentItem)[i] : '',
      );
    }
    if (id) {
      saveItem('videos/sync-related-videos', { ids: selectedVideos }, id);
    }
    if (id) {
      formData.append('_method', 'put');
    }
    axios({
      method: 'post',
      url: id ? 'videos/'.concat(id) : 'videos',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          navigate('/course-management/videos');
        }
        if (res?.data?.error?.email) {
          BSwalShowError(res?.data?.error?.email);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 413) {
          BSwalShowError(['حجم ویدیو آپلود شده بیش از حد مجاز است. حجم مجاز حداکثر 100 مگابایت میباشد.']);
        } else {
          BSwalShowError(err?.data?.messages);
        }
      })
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const fileSelectedHandler = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.main_video = null;
    setSliderImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.main_video = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setSliderImage({ img: reader.result });
    };
  };

  const fileSelectedHandlerImage = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setCoverImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setCoverImage({ img: reader.result });
    };
  };

  const onChangeSelectedVideo = (e) => {
    setSelectedVideos(e?.currentTarget?.value);
  };

  const onRemoveVideo = () => {
    setSliderImage(null);
    const _currentSlider = currentItem;
    _currentSlider.main_video = null;
    setCurrentItem(_currentSlider);
  };

  const onRemoveImage = () => {
    setCoverImage(null);
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setCurrentItem(_currentSlider);
  };

  return (
    <div style={{ margin: 10 }}>
      {loading && (
        <div className='loading-overlay'>
          <Spin tip='در حال بارگذاری اطلاعات' size='large' />
        </div>
      )}
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/course-management/videos',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات ویدیو' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={6}
                  label='عنوان ویدیو به فارسی برای نمایش'
                  name='description'
                  value={currentItem?.description}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.description}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='نام ویدیو به انگلیسی'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب سرویس'
                  name='service_id'
                  value={currentItem?.service_id}
                  onChange={onChange}
                  error={errors?.service_id}
                  items={convertArrayToKeyedObject(services, 'id', 'name')}
                  readOnly={viewReadOnly}
                  required
                />
                {videos.length > 0 && (
                  <BMultiSelectField
                    sm={6}
                    xs={12}
                    label='انتخاب ویدیوهای مرتبط'
                    name='videos'
                    value={selectedVideos || []}
                    onChange={onChangeSelectedVideo}
                    items={convertArrayToKeyedObject(videos, 'id', 'name')}
                    readOnly={viewReadOnly}
                  />
                )}
                <BTextareaField
                  onChange={onChange}
                  name='upload_description'
                  sm={12}
                  xs={12}
                  value={currentItem?.upload_description}
                  label='توضیحات'
                  required
                />
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب کاور ویدیو</div>
                  <span>
                    اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا میباشد.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandlerImage}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {coverImage?.img && (
                    <>
                      <button type='button' onClick={() => onRemoveImage()} aria-label='Remove Image'>
                        <DeleteOutlined />
                      </button>
                      <img
                        src={coverImage.img}
                        alt='cover'
                        style={{ width: '200px', height: '200px', margin: '10px 10px 0 0' }}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب ویدیو</div>
                  <span>اندازه فایل آپلود شده حداکثر 100 مگابایت میباشد. فرمت فیلم به صورت m4v یا mp4 میباشد.</span>
                  <br />
                  <input
                    type='file'
                    accept='video/mp4,video/x-m4v,video/*'
                    onChange={fileSelectedHandler}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {sliderImage?.img && (
                    <>
                      <button type='button' onClick={() => onRemoveVideo()} aria-label='Remove Video'>
                        <DeleteOutlined />
                      </button>
                      <video width='320' height='240' controls style={{ margin: '10px 10px 0 0' }}>
                        <track kind='captions' srcLang='en' label='English' />
                        <source src={sliderImage?.img} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    </>
                  )}
                </Grid>
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='نمایش داده شود'
                  value={Number(currentItem?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/course-management/videos',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default VideoPage;
