/* eslint-disable prefer-destructuring */
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { DashboardPanelContext } from '../../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../../components/buttons/SubmitButton';
import BTextareaField from '../../../../../components/inputs/forms/BTextareaField';
import { setValues, onChangeForBComponents } from '../../../../../common/utilities';
import { BSwalShowError } from '../../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../../components/inputs/forms/BCheckBoxField';

const initialItem = {
  content: '',
  status: true,
  question: '',
  answer: '',
};

const cookies = new Cookies();

const CourseFaqPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState } = useContext(DashboardPanelContext);
  const { viewReadOnly } = globalState;
  const { courseId } = useParams();

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/get-faq',
    localStorageKey: '/get-faq',
    initialItem,
  });

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.status) {
      _currentItem.status = '1';
    } else {
      _currentItem.status = '0';
    }
    const _arr = [];
    _arr.push(_currentItem.question);
    _arr.push(_currentItem.answer);
    _currentItem.content = [..._arr];
    delete _currentItem.answer;
    delete _currentItem.question;
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    if (!id || id === 0) {
      axios({
        method: 'post',
        url: `course/add-faq/${courseId}`,
        data: _currentItem,
        headers: {
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
        .then(() => navigate(`/course-management/faq/${courseId}`))
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    } else {
      axios({
        method: 'put',
        url: `update-faq/${id}`,
        data: _currentItem,
        headers: {
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
        .then(() => navigate(`/course-management/faq/${courseId}`))
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    }
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  useEffect(() => {
    if (currentItem.content) {
      const _currentItem = { ...currentItem };
      const _arr = JSON.parse(currentItem.content);
      if (_arr) {
        _currentItem.question = _arr[0];
        _currentItem.answer = _arr[1];
        setCurrentItem(_currentItem);
      }
    }
  }, [currentItem.content]);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: `/course-management/faq/${courseId}`,
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='متن پرسش و پاسخ' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextareaField
                  onChange={onChange}
                  name='question'
                  sm={12}
                  xs={12}
                  value={currentItem?.question}
                  label='پرسش'
                  required
                />
                <BTextareaField
                  sm={12}
                  xs={12}
                  label='پاسخ'
                  name='answer'
                  value={currentItem?.answer}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.answer}
                  required
                />
                <BCheckBoxField
                  sm={12}
                  name='status'
                  label='نمایش داده شود'
                  value={Number(currentItem?.status)}
                  onChange={onChange}
                  readOnly
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ status: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: `/course-management/faq/${courseId}`,
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default CourseFaqPage;
