import _ from 'lodash';

const errors = [
  {
    value: 'not_null',
    text: {
      en: 'Field is mandatory.',
      fa: 'فیلد اجباری می باشد.',
    },
  },
  {
    value: 'not_empty',
    text: {
      en: 'Field should not be empty.',
      fa: 'فیلد نباید خالی باشد.',
    },
  },
  {
    value: 'only_digits',
    text: {
      en: 'Only digits are acceptable.',
      fa: 'فقط ارقام قابل قبول هستند.',
    },
  },
  {
    value: 'ten_digits',
    text: {
      en: 'The value must contain ten digits.',
      fa: 'مقدار باید شامل ده رقم باشد.',
    },
  },
  {
    value: 'unique_multi',
    text: {
      en: 'Unique constraint violated for these fields',
      fa: 'مقادیر وارد شده تکراری است.',
    },
  },
  {
    value: 'unique',
    text: {
      en: 'Field value must be unique.',
      fa: 'مقدار وارد شده تکراری است.',
    },
  },
  {
    value: 'eleven_digits_at_list',
    text: {
      en: 'The value must be eleven digits at least.',
      fa: 'مقدار باید ۱۱ رقمی باشد',
    },
  },
  {
    value: 'user_not_found',
    text: {
      en: 'User not found.',
      fa: 'کاربر مورد نظر یافت نشد.',
    },
  },
  {
    value: 'exception',
    text: {
      en: 'Unexpected error has occurred.',
      fa: 'سرور در انجام عملیات با خطا مواجه شده است.',
    },
  },
  {
    value: 'entityNotFoundException',
    text: {
      en: 'Entity not found.',
      fa: 'موجودیت مورد نظر یافت نشد.',
    },
  },
  {
    value: 'resourceNotFoundException',
    text: {
      en: 'Resource not found.',
      fa: 'اطلاعات مورد نظر یافت نشد.',
    },
  },
  {
    value: 'fileStorageException',
    text: {
      en: 'Unexpected error has occurred during file storage.',
      fa: 'خطایی در هنگام ذخیره فایل به وجود آمده است.',
    },
  },
  {
    value: 'dataIntegrityViolationException',
    text: {
      en: 'Can not delete entity.',
      fa: 'مورد انتخابی بدلیل داشتن ارتباط با موجودیتی دیگر، قابل حذف نیست',
    },
  },
  {
    value: 'sqlIntegrityViolationException',
    text: {
      en: "can't update entity.",
      fa: 'امکان ویرایش وجود ندارد.',
    },
  },
  {
    value: 'uniqueConstraintViolationException',
    text: {
      en: 'Field value must be unique.',
      fa: 'مقدار فیلد نمی تواند تکراری باشد.',
    },
  },
  {
    value: 'unauthorizedAccessException',
    text: {
      en: 'The authenticated user is not authorized to access the resource.',
      fa: 'کاربر مجاز به دسترسی به منبع مورد نظر نیست.',
    },
  },
  {
    value: 'inoperativeArgumentException',
    text: {
      en: 'Input is inappropriate.',
      fa: 'ورودی قابل قبول نیست.',
    },
  },
  {
    value: 'unacceptableOperationException',
    text: {
      en: 'The requested operation is not supported.',
      fa: 'درخواست قابل اجرا نیست.',
    },
  },
  {
    value: 'bouncedCheckCoefficient',
    text: {
      en: 'Value must be between 0.1 and 1.',
      fa: 'مقدار باید بین 0.1 تا 1 باشد.',
    },
  },
  {
    value: 'firstDocCheckoutCoefficient',
    text: {
      en: 'Value must be between 0.1 and 1.',
      fa: 'مقدار باید بین 0.1 تا 1 باشد.',
    },
  },
  {
    value: 'secondDocCheckoutCoefficient',
    text: {
      en: 'Value must be between 0.1 and 1.',
      fa: 'مقدار باید بین 0.1 تا 1 باشد.',
    },
  },
  {
    value: 'secondDocCheckoutIntervalMonthCount',
    text: {
      en: 'Value is invalid.',
      fa: 'مقدار وارد شده برای بیش از چند ماه باید بیشتر از بین چند ماه باشد ',
    },
  },
  {
    value: 'docCheckoutEffectRemovalMonthCount',
    text: {
      en: 'Value is invalid.',
      fa: 'مقدار وارد شده برای  رفع سو اثر باید بیشتر از بیش از چند ماه باشد',
    },
  },
  {
    value: 'criteriaValuesOverlapException',
    text: {
      en: 'Criteria values are overlapping.',
      fa: 'مقادیر معیارها همپوشانی دارند.',
    },
  },
  {
    value: 'criteriaValuesIncompleteException',
    text: {
      en: 'Criteria values do not include all possible values.',
      fa: 'مقادیر معیارها همه مقادیر ممکن را پوشش نداده اند.',
    },
  },
  {
    value: 'unsupportedOperationException',
    text: {
      en: 'Requested operation is unsupported.',
      fa: 'عملیات درخواستی قابل اجرا نیست.',
    },
  },
  {
    value: 'outOfBoundaryException',
    text: {
      en: 'entered price is out of boundary.',
      fa: 'مقدار وارد شده خارج از محدوده مجاز می باشد.',
    },
  },
  {
    value: 'illegalArgumentException',
    text: {
      en: 'Submitted data is invalid.',
      fa: 'اطلاعات ارسالی نامعتبر هستند.',
    },
  },
  {
    value: 'vastaConnectionException',
    text: {
      en: 'cannot connect with vasta web server',
      fa: 'خطا در برقراری ارتباط با سرور وستا',
    },
  },
  {
    value: 'startDate_after_endDate',
    text: {
      en: 'start date must be before start date.',
      fa: 'تاریخ شروع باید قبل از تاریخ پایان باشد.',
    },
  },
  {
    value: 'coefficient',
    text: {
      en: 'Value must be between 0.1 and 1.',
      fa: 'مقدار باید بین 0.1 تا 1 باشد.',
    },
  },
  {
    value: 'min_two_values',
    text: {
      en: 'The field must have at least two values.',
      fa: 'فیلد حداقل باید شامل دو مقدار باشد.',
    },
  },
  {
    value: 'invalidFileTypeException',
    text: {
      en: 'Type of uploaded file is invalid.',
      fa: 'نوع فایل ارسالی مجاز نمی باشد.',
    },
  },
  {
    value: 'maxFileSizeExceededException',
    text: {
      en: 'Maximum upload size exceeded.',
      fa: 'نوع فایل ارسالی مجاز نمی باشد.',
    },
  },
  {
    value: 'turnoverParameterName',
    text: {
      en: 'turnoverParameterName is unique.',
      fa: 'نوع فایل ارسالی مجاز نمی باشد.',
    },
  },
  {
    value: 'notAchievedDocumentParameterName',
    text: {
      en: 'notAchievedDocumentParameterName is unique.',
      fa: 'نام پارامتر سند سررسید نشده نمیتواند تکراری باشد.',
    },
  },
  {
    value: 'docCheckoutParameterName',
    text: {
      en: 'docCheckoutParameterName is unique.',
      fa: 'نام پارامتر سند تسویه نمیتواند تکراری باشد.',
    },
  },
  {
    value: 'openFactorParameterName',
    text: {
      en: 'openFactorParameterName is unique.',
      fa: 'نام پارامتر فاکتور باز نمیتواند تکراری باشد.',
    },
  },
  {
    value: 'invalidNationalCode',
    text: {
      en: 'National Code is invalid.',
      fa: 'کد ملی صحیح نمی باشد',
    },
  },
];

const translator = {
  englishName: 'نام انگلیسی',
  name: 'نام',
};

const ConvertErrorsToHuman = (text_en) => {
  if (text_en?.startsWith('Unique constraint violated for these fields')) {
    const str = errors.find((c) => c.value === 'unique_multi').text.fa;
    const splited = text_en.split('\n');
    const items = _.filter(splited.slice(1, splited.length - 1).map((c) => c.replace('- ', '')));
    const translated = items.map((c) => translator[c]);
    return `${str} ${_.join(translated, ' - ')}`;
  }
  const error = errors.find((c) => c.text.en.includes(text_en) || c.text.en === text_en);
  const mandatory = text_en.includes('field is mandatory.') ? errors.find((c) => c.value === 'not_null').text?.fa : '';
  return _.get(error, 'text.fa', '') || mandatory;
};

export default ConvertErrorsToHuman;
