import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import CkeditorField from '../../../../components/inputs/CkeditorField';

const initialItem = {
  name: '',
  description: '',
  content: '',
  display: true,
  page_type: 'generic',
};

// const pageType = [
//   {
//     key: 'generic',
//     name: 'پیش فرض',
//   },
// ];

const PagesPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);
  const [textEditor, setTextEditor] = useState('');
  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const editorContainerRef = useRef(null);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/pages',
    localStorageKey: 'pages',
    initialItem,
  });

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    _currentItem.page_type = 'generic';
    saveItem('pages', _currentItem, id)
      .then(() => navigate('/cms/pages'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  useEffect(() => {
    setCurrentItem({ ...currentItem, content: textEditor });
  }, [textEditor]);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={RouterLink}
            to={{
              pathname: '/cms/pages',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات صفحه' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  onChange={onChange}
                  name='description'
                  sm={6}
                  xs={12}
                  value={currentItem?.description}
                  label='نام صفحه به فارسی '
                  required
                />
                <BTextField
                  sm={6}
                  xs={12}
                  label='لینک صفحه به انگلیسی'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                  // {...register('name', { required: true })}
                />
                {/* <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب نوع صفحه'
                  required
                  name='page_type'
                  value={currentItem?.page_type}
                  onChange={onChange}
                  error={errors?.page_type}
                  items={convertArrayToKeyedObject(pageType, 'key', 'name')}
                  readOnly={viewReadOnly}
                /> */}
                <Grid item xs={12} style={{ direction: 'ltr' }}>
                  <div ref={editorContainerRef}>
                    <div ref={editorToolbarRef} />
                    <div ref={editorRef}>
                      {currentItem.page_type === 'generic' && isLayoutReady && (
                        <CkeditorField value={currentItem.content} onChange={(data) => setTextEditor(data)} />
                      )}
                    </div>
                  </div>
                </Grid>

                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='نمایش داده شود'
                  value={Number(currentItem?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={RouterLink}
                  to={{
                    pathname: '/cms/pages',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default PagesPage;
