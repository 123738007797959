import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import { permissionsData } from '../../../../data/Dictionary';

const initialItem = {
  name: '',
};

const cookies = new Cookies();

const RolePage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const [permissions, setPermissions] = useState([]);
  const { viewReadOnly } = globalState;
  const [rolePermission, setRolePermission] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [ready, setReady] = useState(false);

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/roles',
    localStorageKey: 'roles',
    initialItem,
  });

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const memoizedItemName = useMemo(() => currentItem.name, [currentItem.name]);
  const memoizedId = useMemo(() => id, [id]);

  const OnLoad = useCallback(async () => {
    if (id && permissions.length === 0 && currentItem?.name?.length > 0) {
      await fetchItem({
        modelName: `/permissions`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const _rolePermission = {};
          const res = [];
          for (let i = 0; i < response.data?.length; i++) {
            if (currentItem.name === 'super-admin') {
              res.push(response.data[i]);
              _rolePermission[response.data[i].name] = false;
            } else if (
              permissionsData.fa[response.data[i].name] &&
              !permissionsData.fa[response.data[i].name].includes('برنامه نویس') &&
              !permissionsData.fa[response.data[i].name].includes('دوره') &&
              !permissionsData.fa[response.data[i].name].includes('وبلاگ') &&
              !permissionsData.fa[response.data[i].name].includes('سرویس') &&
              !permissionsData.fa[response.data[i].name].includes('همکار') &&
              !permissionsData.fa[response.data[i].name].includes('تقویم') &&
              !permissionsData.fa[response.data[i].name].includes('مشاوره') &&
              !permissionsData.fa[response.data[i].name].includes('مدرک') &&
              !permissionsData.fa[response.data[i].name].includes('ویدیو') &&
              !permissionsData.fa[response.data[i].name].includes('وقت دهی') &&
              !permissionsData.fa[response.data[i].name].includes('کامنت')
            ) {
              res.push(response.data[i]);
              _rolePermission[response.data[i].name] = false;
            }
          }
          setPermissions(res);
          await fetchItem({
            modelName: `/roles/${id}/permissions`,
            showLoader: true,
          })
            .then(async (re) => {
              if (re) {
                for (let k = 0; k < re.data?.data?.length; k++) {
                  _rolePermission[re.data?.data[k].name] = true;
                }
                if (re.data?.last_page > 1) {
                  for (let j = 2; j <= re.data?.last_page; j++) {
                    // eslint-disable-next-line no-await-in-loop
                    await new Promise((resolve) => {
                      setTimeout(resolve, 1000); // 1000 milliseconds (1 second)
                    });
                    // eslint-disable-next-line no-await-in-loop
                    await fetchItem({
                      modelName: `/roles/${id}/permissions?page=${j}`,
                      showLoader: true,
                    }).then((r) => {
                      for (let n = 0; n < r.data?.data?.length; n++) {
                        _rolePermission[r.data?.data[n].name] = true;
                      }
                    });
                  }
                }
                setRolePermission(_rolePermission);
              }
            })
            .finally(() => setReady(true));
        }
      });
    }
  }, [memoizedItemName, memoizedId]);

  useEffect(() => {
    OnLoad();
  }, [OnLoad]);

  const onCheckPermissionValue = useCallback((permission) => Number(rolePermission[permission.name]), [rolePermission]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    saveItem('roles', _currentItem, id)
      .then(() => navigate('/access-management/roles'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onSubmitPermissions = () => {
    setLoadingPermission(true);
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const keys = Object.keys(rolePermission).filter((k) => rolePermission[k] === true);
    const _permission = [];
    for (let i = 0; i < keys.length; i++) {
      const permission = permissions.find((p) => p.name === keys[i]);
      if (permission) {
        _permission.push(String(permission.id));
      }
    }
    const data = {
      permissions: _permission,
    };
    if (_permission.length > 0) {
      saveItem(`sync-permissions-to-role/${id}`, data)
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoadingPermission(false));
    }
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const onChangePermission = (e) => {
    onChangeForBComponents(e, setValue, rolePermission, setRolePermission);
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <>
          <Card {...rest}>
            <Button
              id='backToListIcon'
              component={Link}
              to={{
                pathname: '/access-management/roles',
              }}
              color='secondary'
              variant='outlined'
              style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
            >
              <ArrowLeftOutlined />
            </Button>
            <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
              <CardHeader title='اطلاعات نقش' />
              <Divider />
              <CardContent>
                <br />
                <Grid container spacing={3}>
                  <BTextField
                    xs={12}
                    sm={6}
                    label='نام'
                    name='name'
                    value={currentItem?.name}
                    onChange={onChange}
                    error={errors?.name}
                    readOnly={currentItem?.id}
                    required
                  />
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid item xs={12} sm={6}>
                  {!currentItem?.id && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                  <Button
                    id='backToList'
                    component={Link}
                    to={{
                      pathname: '/access-management/roles',
                    }}
                    color='secondary'
                    variant='outlined'
                  >
                    بازگشت به لیست
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
          {currentItem.id && !ready && (
            <Spin tip='در حال لود کردن دسترسی های نقش' size='small'>
              <div className='content' />
            </Spin>
          )}
          {currentItem.id && Object.keys(rolePermission).length > 0 && ready && (
            <Card {...rest} style={{ marginTop: '10px' }}>
              <form className='formContainer' onSubmit={handleSubmit(onSubmitPermissions)}>
                <CardHeader title='تخصیص دسترسی به نقش' />
                <Divider />
                <CardContent>
                  <br />
                  <Grid container spacing={3}>
                    {permissions.map((permission, index) => (
                      <BCheckBoxField
                        sm={3}
                        key={index}
                        name={permission.name}
                        label={permissionsData.fa[permission.name] || permission.name}
                        value={onCheckPermissionValue(permission)}
                        onChange={onChangePermission}
                        readOnly={viewReadOnly}
                      />
                    ))}
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item xs={12} sm={6}>
                    {!viewReadOnly && (
                      <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loadingPermission} />
                    )}
                  </Grid>
                </CardActions>
              </form>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default RolePage;
