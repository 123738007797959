export const SET_ITEMS_LOADING = 'SET_ITEMS_LOADING';
export const SET_ITEM_LOADING = 'SET_ITEM_LOADING';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_SAVE_SUCCESS = 'SET_SAVE_SUCCESS';
export const SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER = 'SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER';
export const SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER = 'SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER';
export const SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER_NAME = 'SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER_NAME';
export const SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER_NAME = 'SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER_NAME';
export const SET_SELECTED_MENU_ID = 'SET_SELECTED_MENU_ID';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const SET_VIEW_READ_ONLY = 'SET_VIEW_READ_ONLY';
export const SET_HISTORY_ID_LIST = 'SET_HISTORY_ID_LIST';
export const SET_ENUMS = 'SET_ENUMS';
export const SET_ENUM = 'SET_ENUM';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_LOADING_HISTORY = 'SET_LOADING_HISTORY';
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY';
