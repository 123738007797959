import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, SearchOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Input, Space, Button as AntdButton } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  convertArrayToKeyedObject,
  onKeyDownHandlerOnlyAcceptIntegerEnglish,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';
import BMultiSelectField from '../../../../components/inputs/forms/BMultiSelectField';
import Table from '../../../../components/tables/Table';
import useTableActions from '../../../../hooks/useTableActions';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';

const initialItem = {
  name: '',
  description: '',
  display: true,
  service_id: null,
  content: '',
  videos: [],
  price: '',
  off_price: '',
  new: true,
  special: true,
  popular: true,
  image: null,
  banner: null,
  video: null,
  cover_image: null,
  on_site_image: null,
  course_type: 'online',
  capacity: '',
  start_date: null,
  end_date: null,
  duration: '',
  hour: '',
  minute: '',
  summary: '',
  topic: '',
  discount_percentage: '',
};

const CourseType = [
  {
    key: 'online',
    name: 'مجازی',
  },
  {
    key: 'on_site',
    name: 'حضوری',
  },
];

const cookies = new Cookies();
const url = process.env.REACT_APP_SITE_URL;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function Quote({ quote, index }) {
  return (
    <Draggable draggableId={quote.id} index={index} key={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {quote.content}
        </div>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(({ quotes }) =>
  quotes.map((quote, index) => <Quote quote={quote} index={index} key={index} />),
);

const CoursePage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const [services, setServices] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sliderImage, setSliderImage] = useState(null);
  const [courseImage, setCourseImage] = useState(null);
  const [introVideo, setIntroVideo] = useState(null);
  const [videoCover, setVideoCover] = useState(null);
  const { viewReadOnly } = globalState;
  const [courseCustomers, setCourseCustomers] = useState([]);
  const [columns, setColumns] = useState([]);
  const tableRef = useRef(null);
  const [key, setKey] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [onSiteImage, setOnSiteImage] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [state, setState] = useState({ quotes: videos });

  useEffect(() => {
    fetchItem({
      modelName: `/services`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/services?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setServices(res);
      }
    });
    fetchItem({
      modelName: `/videos`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/videos?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setAllVideos(res);
        setVideos(res);
      }
    });
    fetchItem({
      modelName: `/customers`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            const user = response.data?.data[i].user;
            if (user) {
              user.id = response.data?.data[i]?.id;
              res.push(user);
            }
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/customers?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r?.data?.data[n]?.display === '1') {
                  const _user = r.data?.data[n].user;
                  if (_user) {
                    _user.id = r.data?.data[n]?.id;
                    res.push(_user);
                  }
                }
              }
            });
          }
        }
        setCustomers(res);
      }
    });
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder='جستجو'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            جستجو
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            ریست
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            فیلتر
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            بستن
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  useEffect(() => {
    setColumns([
      {
        title: 'نام',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'ایمیل',
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
      },
      {
        title: 'موبایل',
        dataIndex: 'mobile',
        key: 'mobile',
        ...getColumnSearchProps('mobile'),
      },
    ]);
    setKey(key + 1);
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(state.quotes, result.source.index, result.destination.index);

    setState({ quotes });
  };

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/courses',
    localStorageKey: 'courses',
    initialItem,
  });

  useEffect(() => {
    if (id) {
      fetchItem({
        modelName: `/courses`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (response.data?.data[i].display === '1' && Number(response.data?.data[i]?.id) !== Number(id)) {
              res.push(response.data?.data[i]);
            }
          }
          if (response.data?.last_page > 1) {
            for (let j = 2; j <= response.data?.last_page; j++) {
              // eslint-disable-next-line no-await-in-loop
              await fetchItem({
                modelName: `/courses?page=${j}`,
                showLoader: true,
              }).then((r) => {
                for (let n = 0; n < r.data?.data?.length; n++) {
                  if (r.data?.data[n].display === '1' && Number(r.data?.data[n]?.id) !== Number(id)) {
                    res.push(r.data?.data[n]);
                  }
                }
              });
            }
          }
          setCourses(res);
        }
      });
    }
  }, [id]);

  const getCourseCustomerList = useCallback(() => {
    if (id && customers.length > 0) {
      fetchItem({
        modelName: `/courses/${id}/customer-list`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res = [];
          const number_of_pages = response.data?.last_page;
          setTotalPageNumber(number_of_pages * 10);
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (response.data?.data[i].display === '1') {
              const user = customers.find((customer) => String(customer.id) === String(response.data?.data[i].id));
              if (user) {
                Object.keys(user).forEach((userKey) => {
                  if (user[userKey] === 'null') {
                    user[userKey] = '';
                  }
                });
                res.push(user);
              }
            }
          }
          setCourseCustomers(res);
        }
      });
    }
  }, [customers, id]);

  useEffect(() => {
    getCourseCustomerList();
  }, [id, customers]);

  const fileSelectedHandlerLoad = async (e) => {
    setSliderImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setSliderImage({ img: imgSrc });
  };

  const fileSelectedHandlerImageLoad = async (e) => {
    setCourseImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setCourseImage({ img: imgSrc });
  };

  const fileSelectedHandlerLoadVideo = async (e) => {
    setIntroVideo(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setIntroVideo({ img: imgSrc });
  };

  const fileSelectedHandlerLoadVideoCover = async (e) => {
    setVideoCover(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setVideoCover({ img: imgSrc });
  };

  const fileSelectedHandlerLoadOnSiteImage = async (e) => {
    setOnSiteImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setOnSiteImage({ img: imgSrc });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  useEffect(() => {
    if (currentItem?.videos?.length > 0) {
      let _videos = currentItem.videos.filter((video) => video.display === '1');
      _videos = _videos.sort((a, b) => Number(a?.pivot?.order) - Number(b?.pivot?.order));
      _videos = _videos.map((video) => String(video.id));
      setSelectedVideos(_videos);
    }
    if (currentItem.id && currentItem.attachments && currentItem.attachments.length > 0) {
      const _image = currentItem.attachments.find((attachment) => attachment.slug === 'image');
      const _banner = currentItem.attachments.find((attachment) => attachment.slug === 'banner');
      const _video = currentItem.attachments.find((attachment) => attachment.slug === 'video');
      const _cover = currentItem.attachments.find((attachment) => attachment.slug === 'cover_image');
      const _onSiteImage = currentItem.attachments.find((attachment) => attachment.slug === 'on_site_image');
      if (_image && _image.path) {
        fileSelectedHandlerImageLoad(_image.path);
      }
      if (_banner && _banner.path) {
        fileSelectedHandlerLoad(_banner.path);
      }
      if (_video && _video.path) {
        fileSelectedHandlerLoadVideo(_video.path);
      }
      if (_cover && _cover.path) {
        fileSelectedHandlerLoadVideoCover(_cover.path);
      }
      if (_onSiteImage && _onSiteImage.path) {
        fileSelectedHandlerLoadOnSiteImage(_onSiteImage.path);
      }
    }
    if (currentItem?.related_courses) {
      const _courses = [];
      for (let i = 0; i < currentItem?.related_courses.length; i++) {
        _courses.push(currentItem?.related_courses[i].id);
      }
      setSelectedCourses(_courses);
    }
  }, [currentItem.id]);

  const [textFields, setTextFields] = useState(['']); // Initialize with one empty text field

  const addTextField = () => {
    setTextFields([...textFields, '']);
  };

  const removeTextField = (index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields.splice(index, 1);
    setTextFields(updatedTextFields);
  };

  const handleChange = (value, index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value;
    setTextFields(updatedTextFields);
  };

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    if (currentItem.new) {
      _currentItem.new = '1';
    } else {
      _currentItem.new = '0';
    }
    _currentItem.special = '1';
    _currentItem.popular = '1';
    // if (currentItem.special) {
    //   _currentItem.special = '1';
    // } else {
    //   _currentItem.special = '0';
    // }
    // if (currentItem.popular) {
    //   _currentItem.popular = '1';
    // } else {
    //   _currentItem.popular = '0';
    // }
    _currentItem.videos = {};
    state.quotes?.forEach((video, index) => {
      _currentItem.videos[String(video.id)] = {
        order: index + 1,
      };
    });
    _currentItem.videos = JSON.stringify(_currentItem.videos);
    if (_currentItem.price === '0' || _currentItem.price === 0 || !_currentItem.price) {
      _currentItem.free = '1';
    } else {
      _currentItem.free = '0';
    }
    if (_currentItem.hour || _currentItem.minute) {
      const durationHour = _currentItem.hour ? String(_currentItem.hour).padStart(2, '0') : '00';
      const durationMinute = _currentItem.minute ? String(_currentItem.minute).padStart(2, '0') : '00';
      _currentItem.duration = `${durationHour}:${durationMinute}:00`;
    }
    delete _currentItem.hour;
    delete _currentItem.minute;
    const _topic = [];
    if (textFields && textFields.length > 0) {
      textFields.forEach((text) => {
        if (text.length > 0) {
          _topic.push(text);
        }
      });
    }
    if (_topic.length > 0) {
      _currentItem.topic = JSON.stringify(_topic);
    } else {
      _currentItem.topic = '';
    }
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const formData = new FormData();
    for (let i = 0; i < Object.keys(_currentItem).length; i++) {
      formData.append(
        Object.keys(_currentItem)[i],
        Object.values(_currentItem)[i] ? Object.values(_currentItem)[i] : '',
      );
    }
    if (id) {
      formData.append('_method', 'put');
    }
    if (id) {
      saveItem('courses/sync-related-courses', { ids: selectedCourses }, id);
    }
    axios({
      method: 'post',
      url: id ? 'courses/'.concat(id) : 'courses',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          navigate('/course-management/courses');
        }
        if (res?.data?.error?.email) {
          BSwalShowError(res?.data?.error?.email);
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const onChangeSelectedVideo = (e) => {
    setSelectedVideos(e?.currentTarget?.value);
  };

  const onChangeSelectedCustomers = (e) => {
    setSelectedCustomers(e?.currentTarget?.value);
  };

  const attachCustomerToCourse = useCallback(() => {
    if (selectedCustomers.length > 0 && currentItem.id) {
      saveItem('courses/attach-customer-to-course', { ids: selectedCustomers }, currentItem.id).then(() => {
        setSelectedCustomers([]);
        getCourseCustomerList();
      });
    }
  }, [currentItem.id, selectedCustomers]);

  const detachCustomerToCourse = useCallback(
    (customerId) => {
      saveItem('courses/detach-customer-to-course', { ids: [String(customerId)] }, currentItem.id).then(
        getCourseCustomerList(),
      );
    },
    [currentItem.id, selectedCustomers],
  );

  useEffect(() => {
    const _videos = selectedVideos.map((videoId) => ({
      id: String(videoId),
      content: videos.find((video) => Number(video.id) === Number(videoId))?.name,
    }));
    setState({ quotes: _videos });
  }, [selectedVideos, videos]);

  const fileSelectedHandler = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setCourseImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setCourseImage({ img: reader.result });
    };
  };

  const fileSelectedImageHandler = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.banner = null;
    setSliderImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.banner = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setSliderImage({ img: reader.result });
    };
  };

  const fileSelectedOnSiteImageHandler = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.on_site_image = null;
    setOnSiteImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.on_site_image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setOnSiteImage({ img: reader.result });
    };
  };

  const actions = useTableActions({
    updatePermission: '',
    deletePermission: 'courses.detachCustomersToCourse',
    editURL: '',
    removePATH: `courses/detach-customer-to-course`,
    createPermission: 'courses.attachCustomersToCourse',
  });
  const onChangeSelectedCourse = (e) => {
    setSelectedCourses(e?.currentTarget?.value);
  };
  const onChangeGetOtherPageData = (page) => {
    if (id && customers.length > 0) {
      fetchItem({
        modelName: `/courses/${id}/customer-list?page=${page}`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (response.data?.data[i].display === '1') {
              const user = customers.find((customer) => String(customer.id) === String(response.data?.data[i].user_id));
              if (user) {
                res.push(user);
              }
            }
          }
          setCourseCustomers(res);
        }
      });
    }
  };

  useEffect(() => {
    if (currentItem?.service_id && selectedVideos?.length === 0) {
      setVideos(allVideos?.filter((video) => String(video?.service_id) === String(currentItem?.service_id)));
    }
    if (currentItem?.service_id && selectedVideos?.length > 0) {
      setVideos(allVideos);
    }
  }, [currentItem?.service_id]);

  const fileSelectedHandlerVideo = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.video = null;
    setIntroVideo(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.video = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setIntroVideo({ img: reader.result });
    };
  };

  const fileSelectedHandlerVideoCover = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.cover_image = null;
    setVideoCover(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.cover_image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setVideoCover({ img: reader.result });
    };
  };

  const durationToHourAndMinute = useCallback((duration) => {
    const parts = duration.split(':');
    if (parts.length === 3) {
      const [hour, minute] = parts.map(Number);
      return { hour, minute };
    }
    // Handle invalid duration format (e.g., "hh:mm:ss" expected)
    return { hour: 0, minute: 0 };
  }, []);

  useEffect(() => {
    if (currentItem?.duration) {
      const { hour, minute } = durationToHourAndMinute(currentItem.duration);
      const _currentItem = { ...currentItem };
      _currentItem.hour = hour;
      _currentItem.minute = minute;
      setCurrentItem(_currentItem);
    }
  }, [currentItem?.duration]);

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    if (currentItem?.topic && currentItem?.topic.length > 0 && isJSON(currentItem?.topic)) {
      const _topics = JSON.parse(currentItem?.topic);
      setTextFields(_topics);
    }
  }, [currentItem?.topic]);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/course-management/courses',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='اطلاعات دوره' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={6}
                  label='عنوان دوره برای نمایش'
                  name='description'
                  value={currentItem?.description}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.description}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='عنوان دوره به انگلیسی'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب سرویس'
                  required
                  name='service_id'
                  value={currentItem?.service_id}
                  onChange={onChange}
                  error={errors?.service_id}
                  items={convertArrayToKeyedObject(services, 'id', 'name')}
                  readOnly={viewReadOnly}
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='انتخاب نوع برگزاری دوره'
                  required
                  name='course_type'
                  value={currentItem?.course_type}
                  onChange={onChange}
                  error={errors?.course_type}
                  items={convertArrayToKeyedObject(CourseType, 'key', 'name')}
                  readOnly={viewReadOnly}
                />
                {currentItem.course_type === 'on_site' && (
                  <>
                    <BDateFieldTable
                      name='start_date'
                      label='تاریخ شروع دوره'
                      error={errors?.start_date}
                      value={currentItem?.start_date}
                      onChange={onChange}
                      required
                    />
                    <BDateFieldTable
                      name='end_date'
                      label='تاریخ پایان دوره'
                      error={errors?.end_date}
                      value={currentItem?.end_date || null}
                      onChange={onChange}
                      required
                    />
                  </>
                )}
                <div style={{ fontSize: '16px', marginTop: '20px', width: '100%' }}>
                  {' '}
                  مدت دوره (فقط به صورت عدد وارد شود)
                </div>
                <BTextField
                  xs={12}
                  sm={3}
                  label='ساعت'
                  name='hour'
                  value={currentItem?.hour}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.hour}
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                  placeholder='12'
                  required
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='دقیقه'
                  name='minute'
                  value={currentItem?.minute}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.minute}
                  placeholder='40'
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='ظرفیت دوره برای نمایش (تعداد نفر)'
                  name='capacity'
                  value={currentItem?.capacity}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.capacity}
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='قیمت دوره(تومان)'
                  name='price'
                  value={currentItem?.price}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.price}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='قیمت بعد از تخفیف(تومان)'
                  name='off_price'
                  value={currentItem?.off_price}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.off_price}
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='درصد تخفیف برای نمایش (عدد وارد شود)'
                  name='discount_percentage'
                  value={currentItem?.discount_percentage}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.discount_percentage}
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                  placeholder='30'
                />
                <BTextareaField
                  onChange={onChange}
                  name='upload_description'
                  sm={12}
                  xs={12}
                  value={
                    currentItem.course_type === 'online'
                      ? 'سایز استاندارد عکس دوره در کارت های دوره ها 564 در 325 پیکسل میباشد. در صفحه درباره دوره، سایز استاندارد عکس بنر دوره 611 در 384 پیکسل است. سایز استاندارد عکس کاور ویدیو 822 در 424 پیکسل است.'
                      : 'سایز استاندارد عکس دوره در کارت های دوره ها 757 در 280 پیکسل در صفحه اصلی و 483 در 411 در صفحه لیست دوره های حضوری میباشد. در صفحه درباره دوره، سایز استاندارد عکس بنر دوره 611 در 384 پیکسل است. سایز استاندارد عکس کاور ویدیو 822 در 424 پیکسل است.'
                  }
                  label='توضیحات راهنما برای ادمین'
                  readOnly
                />
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>
                    {currentItem.course_type === 'on_site' ? 'انتخاب عکس دوره در صفحه اصلی' : 'انتخاب عکس دوره'}
                  </div>
                  <span>
                    اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا میباشد.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandler}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {courseImage?.img && (
                    <img alt='courseImage' src={courseImage?.img} style={{ width: '200px', height: '200px' }} />
                  )}
                </Grid>
                {currentItem.course_type === 'on_site' && (
                  <Grid item xs={12} sm={12}>
                    <div style={{ fontSize: '16px' }}>انتخاب عکس دوره در صفحه لیست دوره های حضوری</div>
                    <span>
                      اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا
                      میباشد.
                    </span>
                    <br />
                    <input
                      type='file'
                      accept='.png, .jpg, .jpeg, .webp'
                      onChange={fileSelectedOnSiteImageHandler}
                      style={{ marginBottom: '15px', marginTop: '15px' }}
                    />
                    {onSiteImage?.img && (
                      <img alt='courseImage' src={onSiteImage?.img} style={{ width: '200px', height: '200px' }} />
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب بنر دوره</div>
                  <span>
                    اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا میباشد.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedImageHandler}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {sliderImage?.img && (
                    <img alt='banner' src={sliderImage?.img} style={{ width: '200px', height: '200px' }} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب ویدیو معرفی</div>
                  <span>اندازه فایل آپلود شده حداکثر 100 مگابایت میباشد. فرمت فیلم به صورت m4v یا mp4 میباشد.</span>
                  <br />
                  <input
                    type='file'
                    accept='video/mp4,video/x-m4v,video/*'
                    onChange={fileSelectedHandlerVideo}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {introVideo?.img && (
                    <video width='320' height='240' controls>
                      <source src={introVideo?.img} type='video/mp4' />
                      <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب کاور ویدیو</div>
                  <span>
                    اندازه فایل آپلود شده حداکثر 4 مگابایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا میباشد.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandlerVideoCover}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {videoCover?.img && (
                    <img src={videoCover.img} alt='cover' style={{ width: '200px', height: '200px' }} />
                  )}
                </Grid>
                <BTextareaField
                  onChange={onChange}
                  name='summary'
                  sm={12}
                  value={currentItem?.summary}
                  label='مطلب خلاصه درباره دوره'
                  required
                />
                <BTextareaField
                  onChange={onChange}
                  name='content'
                  sm={12}
                  value={currentItem?.content}
                  label='درباره دوره'
                  required
                />
                <Grid item xs={12}>
                  <div style={{ fontSize: '16px', width: '100%', marginBottom: '10px' }}> محتوای دوره (سر فصل ها)</div>
                  <div style={{ width: '100%' }}>
                    {textFields.map((text, index) => (
                      <>
                        <Input
                          style={{ width: '80%', marginTop: '10px' }}
                          value={text}
                          onChange={(e) => handleChange(e.target.value, index)}
                        />
                        <AntdButton
                          style={{ marginRight: '5px' }}
                          type='primary'
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeTextField(index)}
                        >
                          حذف
                        </AntdButton>
                      </>
                    ))}
                    <AntdButton
                      type='dashed'
                      style={{ marginRight: '5px' }}
                      icon={<PlusOutlined />}
                      onClick={addTextField}
                    >
                      افزودن
                    </AntdButton>
                  </div>
                </Grid>
                {courses.length > 0 && (
                  <BMultiSelectField
                    sm={6}
                    xs={12}
                    label='انتخاب دوره های مرتبط'
                    name='courses'
                    value={selectedCourses || []}
                    onChange={onChangeSelectedCourse}
                    items={convertArrayToKeyedObject(courses, 'id', 'description')}
                    readOnly={viewReadOnly}
                  />
                )}
                <BMultiSelectField
                  xs={12}
                  sm={12}
                  label='انتخاب ویدیوهای دوره'
                  name='videos'
                  value={selectedVideos || []}
                  onChange={onChangeSelectedVideo}
                  items={convertArrayToKeyedObject(videos, 'id', 'name')}
                  readOnly={viewReadOnly}
                />
                <Grid item xs={12}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='list'>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          // style={{ display: 'flex', padding: '8px', overflow: 'auto' }}
                        >
                          <h5>انتخاب ترتیب ویدیوها</h5>
                          <QuoteList quotes={state.quotes} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>

                {currentItem.id && (
                  <>
                    <BMultiSelectField
                      sm={6}
                      xs={12}
                      label='تخصیص دانشجو به دوره'
                      name='customers'
                      value={selectedCustomers || []}
                      onChange={onChangeSelectedCustomers}
                      items={convertArrayToKeyedObject(customers, 'id', 'name')}
                      readOnly={viewReadOnly || customers.length === 0}
                    />
                    <Grid item xs={12} sm={6} style={{ marginRight: '0', marginTop: '42px' }}>
                      <Button
                        id='addStudent'
                        onClick={attachCustomerToCourse}
                        color='secondary'
                        variant='outlined'
                        disabled={selectedCustomers.length === 0 || !actions[2]}
                      >
                        اضافه کردن دانشجو به دوره
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Table
                        key={key}
                        title='لیست دانشجو های دوره'
                        columns={columns}
                        createSlug='افزودن به دوره ها'
                        actions={actions}
                        tableRef={tableRef}
                        data={courseCustomers}
                        pageSize={10}
                        filtering={false}
                        removePath='courses/detach-customer-to-course'
                        removeFunction={(_id) => detachCustomerToCourse(_id)}
                        sortableColumns
                        totalPages={totalPageNumber}
                        getOtherPageData={onChangeGetOtherPageData}
                      />
                    </Grid>
                  </>
                )}
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='نمایش داده شود'
                  value={Number(currentItem?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='new'
                  label='جدید'
                  value={Number(currentItem?.new)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                {/* <BCheckBoxField
                  sm={12}
                  name='special'
                  label='ویژه'
                  value={Number(currentItem?.special)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='popular'
                  label='محبوب'
                  value={Number(currentItem?.popular)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                /> */}
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/course-management/courses',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default CoursePage;
