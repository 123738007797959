import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, MinusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Row, Button as AntdButton } from 'antd';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BDateFieldTableEn from '../../../../components/inputs/forms/BDateFieldTableEn';

const url = process.env.REACT_APP_SITE_URL;
const cookies = new Cookies();

const ProductAttributePage = (props) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { staticContext, ...rest } = props;
  const { fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [loading, setLoading] = useState(false);
  const [attributeCategories, setAttributeCategories] = useState([]);
  const { handleSubmit, setValue } = useForm();
  const [currentItem, setCurrentItem] = useState({});
  const [currentOptionsItem, setCurrentOptionsItem] = useState({});
  const [multipleChoiceArray, setMultipleChoiceArray] = useState([]);
  const [removedFields, setRemovedFields] = useState([]);
  const [allAttributeIds, setAllAttributeIds] = useState([]);
  const [images, setImages] = useState([]);
  // const mainFileInputRef = useRef(null);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    fetchItem({
      modelName: `/attribute-category/options`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        if (response.data.length > 0) {
          const filtered = response.data.filter((res) => res.id !== 6);
          setAttributeCategories(filtered);
        }
      }
    });
    fetchItem({
      modelName: `/product-attribute-options/${productId}`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const optionItems = {};
        const multipleChoice = [];
        const allIds = [];
        response.data.dropdowns.forEach((obj) => {
          Object.entries(obj).forEach(([key, value]) => {
            const removeObj = {
              attribute_id: String(key),
              attribute_option_id: String(value),
            };
            allIds.push(removeObj);
            const matchingObject = response.data.dataTypes.find((resObj) => Object.keys(resObj).includes(String(key)));
            if (matchingObject && matchingObject[String(key)] === 4) {
              if (!optionItems[`attribute${key}`]) {
                optionItems[`attribute${key}`] = {};
              }
              optionItems[`attribute${key}`].option = value;
            }
            if (
              (matchingObject && matchingObject[String(key)] === 5) ||
              (matchingObject && matchingObject[String(key)] === 7)
            ) {
              const newArr = {
                attribute_id: Number(key),
                option_id: value,
              };
              multipleChoice.push(newArr);
            }
          });
        });
        setMultipleChoiceArray(multipleChoice);

        setCurrentOptionsItem(optionItems);
        response.data.attachments?.forEach((obj) => {
          Object.entries(obj).forEach(([key, value]) => {
            value?.attachments.forEach((attachmentValue) => {
              const name = url.concat(attachmentValue.path);
              const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
              const attachmentId = attachmentValue.id;
              setImages((prevImagesList) => {
                // Check if the image already exists
                const existingImageIndex = prevImagesList.findIndex(
                  (image) =>
                    String(image.attId) === String(key) && String(image.attOptionId) === String(value.option_id),
                );
                if (existingImageIndex !== -1) {
                  // Image exists, update its src
                  return prevImagesList.map((image, index) => {
                    if (index === existingImageIndex) {
                      return {
                        ...image,
                        imgs: [...image.imgs, { img: imgSrc, id: attachmentId }],
                      };
                    }
                    return image;
                  });
                }
                // Image does not exist, add it
                return [
                  ...prevImagesList,
                  { attId: key, attOptionId: value.option_id, imgs: [{ img: imgSrc, id: attachmentId }] }, // Add new image
                ];
              });
            });
          });
        });
        const items = {};
        response.data.inputs.forEach((obj) => {
          Object.entries(obj).forEach(([key, value]) => {
            const removeObj = {
              attribute_id: String(key),
              attribute_option_id: String(value),
            };
            allIds.push(removeObj);
            if (!items[`attribute${key}`]) {
              items[`attribute${key}`] = {};
            }
            items[`attribute${key}`] = value;
          });
        });
        setCurrentItem(items);
        setAllAttributeIds(allIds);
      }
    });
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    const _multipleChoiceArray = [...multipleChoiceArray];
    const finalData = {
      product_id: productId,
      attributes: [],
    };
    for (let i = 0; i < _multipleChoiceArray.length; i++) {
      finalData.attributes.push({
        attribute_id: _multipleChoiceArray[i].attribute_id,
        attribute_option_id: _multipleChoiceArray[i].option_id,
        // modified_price: _multipleChoiceArray[i].modified_price || '0',
      });
    }
    Object.keys(_currentItem).forEach((key) => {
      const value = _currentItem[key];
      finalData.attributes.push({
        attribute_id: key.replace('attribute', ''),
        value,
      });
    });
    const _currentOptionsItem = { ...currentOptionsItem };
    Object.keys(_currentOptionsItem).forEach((key) => {
      const value = _currentOptionsItem[key];
      finalData.attributes.push({
        attribute_id: key.replace('attribute', ''),
        attribute_option_id: value.option,
      });
    });
    const finalRemoveDataAttributes = [];
    for (let i = 0; i < removedFields.length; i++) {
      const isExist = finalData.attributes.find(
        (item) =>
          String(item.attribute_id) === String(removedFields[i].attribute_id) &&
          String(item.attribute_option_id) === String(removedFields[i].attribute_option_id),
      );
      if (!isExist) finalRemoveDataAttributes.push(removedFields[i]);
    }
    const finalRemoveData = {
      product_id: productId,
      attributes: finalRemoveDataAttributes,
    };
    const attributesJSONString = JSON.stringify(finalData.attributes);
    finalData.attributes = attributesJSONString;
    const attributesRemovedJSONString = JSON.stringify(finalRemoveData.attributes);
    finalRemoveData.attributes = attributesRemovedJSONString;
    const formData = new FormData();
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    // Append simple fields
    formData.append('product_id', finalData.product_id);

    // Append attributes as JSON string
    formData.append('attributes', finalData.attributes);
    images.forEach((imageGroup) => {
      imageGroup.files?.forEach((file) => {
        formData.append(`attachments_${imageGroup.attId}_${imageGroup.attOptionId}[]`, file);
      });
    });
    axios({
      method: 'post',
      url: '/product-attribute-options',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then(async () => {
        if (finalRemoveDataAttributes.length > 0) {
          await saveItem(`remove-product-attribute-options`, finalRemoveData, null);
        }
        if (removedImages.length > 0) {
          const imageFormData = new FormData();
          const removedImagesJSON = JSON.stringify(removedImages);
          imageFormData.append('attachment_ids', removedImagesJSON);
          axios({
            method: 'post',
            url: 'product/delete-attachment',
            data: imageFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${cookies.get('userToken')}`,
            },
          }).then(() => {
            navigate('/product-management/products');
          });
        } else {
          navigate('/product-management/products');
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChangeDynamicField = (fieldName, event) => {
    const { value } = event.currentTarget;
    setCurrentItem((prevItem) => ({
      ...prevItem,
      [fieldName]: value,
    }));
    setValue(fieldName, value);
  };

  const onRemoveDynamicField = (fieldName, value) => {
    setCurrentItem((prevItem) => ({
      ...prevItem,
      [fieldName]: value,
    }));
    setValue(fieldName, value);
  };

  const onChangeDynamicOptionsField = (objName, fieldName, event) => {
    const { value } = event.currentTarget;
    setCurrentOptionsItem((prevItem) => ({
      ...prevItem,
      [objName]: {
        ...prevItem[objName],
        [fieldName]: value,
      },
    }));
    setValue(fieldName, value);
  };

  const onRemoveDynamicOptionsField = (objName, fieldName) => {
    const items = { ...currentOptionsItem };
    if (items[objName]) {
      delete items[objName];
      setCurrentOptionsItem(items);
    }
    setValue(fieldName, '');
  };

  const onAddMultipleChoiceArray = useCallback((attributeId, event) => {
    const { value } = event.currentTarget;

    setMultipleChoiceArray((prevArray) => {
      const existingItem = prevArray.find((item) => item.attribute_id === attributeId && item.option_id === value);

      if (!existingItem) {
        const newArr = {
          attribute_id: attributeId,
          option_id: value,
        };

        return [...prevArray, newArr];
      }

      return prevArray;
    });
  }, []);

  const onRemoveMultipleChoiceArray = useCallback((attributeId, optionId) => {
    setMultipleChoiceArray((prevArray) => {
      const updatedArray = prevArray.filter(
        (item) => !(item.attribute_id === attributeId && item.option_id === optionId),
      );
      return updatedArray;
    });
  }, []);

  const onRemoveOptions = useCallback(
    (attributeId, optionId) => {
      const idExist = allAttributeIds.find(
        (att) => att.attribute_id === String(attributeId) && att.attribute_option_id === String(optionId),
      );
      if (idExist) {
        const removeObj = {
          attribute_id: attributeId,
          attribute_option_id: optionId,
        };
        setRemovedFields((prev) => [...prev, removeObj]);
      }
    },
    [allAttributeIds],
  );

  const mainFileSelectedHandler = (e, attId, attOptionId) => {
    const files = Array.from(e.target.files);

    setImages((prevImagesList) => {
      // Find existing entry for the given attId and attOptionId
      const existingEntryIndex = prevImagesList.findIndex(
        (entry) => String(entry.attId) === String(attId) && String(entry.attOptionId) === String(attOptionId),
      );

      if (existingEntryIndex !== -1) {
        // Update the existing entry by adding new files
        return prevImagesList.map((entry, index) => {
          if (index === existingEntryIndex) {
            return {
              ...entry,
              files: [...(entry.files ?? []), ...files], // Add new files to the array, even if entry.files doesn't exist
            };
          }
          return entry;
        });
      }

      // If no entry exists, create a new one
      return [
        ...prevImagesList,
        { attId, attOptionId, files }, // Add new entry with the array of files
      ];
    });
  };
  const removeImage = async (attId, attOptionId, idToRemove = undefined, fileToRemove = undefined) => {
    setImages((prevImagesList) => {
      let idsToRemove = [];
      // Create a copy of the previous images list with modifications
      const updatedImagesList = prevImagesList
        .map((image) => {
          if (String(image.attId) === String(attId) && String(image.attOptionId) === String(attOptionId)) {
            // Filter out the specific image by idToRemove
            if (idToRemove) {
              const updatedImgs = image.imgs.filter((img) => img.id !== idToRemove);
              return { ...image, imgs: updatedImgs };
            }
            // Filter out the specific file by fileToRemove
            if (fileToRemove) {
              const updatedFiles = image.files.filter((img) => img !== fileToRemove);
              return { ...image, files: updatedFiles };
            }
            // If no specific id or file is provided, collect all ids and clear imgs and files
            if (image.imgs) {
              idsToRemove = image.imgs?.map((img) => img.id);
            }
            return null;
          }
          return image;
        })
        .filter((img) => img);
      if (idsToRemove.length > 0) {
        setRemovedImages((prev) => [...prev, ...idsToRemove]);
      }
      return updatedImagesList;
    });

    // Optionally update the removedImages state
    if (idToRemove) {
      setRemovedImages((prev) => [...prev, idToRemove]);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Card {...rest}>
        <Button
          id='backToListIcon'
          component={Link}
          to={{
            pathname: '/product-management/products',
          }}
          color='secondary'
          variant='outlined'
          style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
        >
          <ArrowLeftOutlined />
        </Button>
        <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='تخصیص ویژگی به محصول' />
          <Divider />
          <CardContent>
            <br />
            <Grid container spacing={3}>
              {attributeCategories.map((attCat, index) => (
                <div key={`${attCat.name}-${index}`} style={{ width: '100%' }}>
                  <h3>{attCat.name}</h3>
                  <Divider />
                  <br />
                  {attCat.attributes.map((att, _index) => {
                    const fieldName = `attribute${att.id}`;
                    if (att.data_type_id === 1) {
                      return (
                        <Grid
                          container
                          spacing={3}
                          key={`G-${att.data_type_id}-${fieldName}-${_index}`}
                          style={{ marginTop: '10px' }}
                        >
                          <BTextField
                            xs={12}
                            sm={6}
                            label={`${att.name}`.concat(att.unit_id ? `(${att.unit?.name || ''})` : '')}
                            name={fieldName}
                            value={currentItem ? currentItem[fieldName] : ''}
                            onChange={(e) => onChangeDynamicField(fieldName, e)}
                            key={`${fieldName}+${_index}`}
                            required
                          />
                          <br />
                        </Grid>
                      );
                    }
                    if (att.data_type_id === 3) {
                      return (
                        <Grid
                          container
                          spacing={3}
                          key={`G-${att.data_type_id}-${fieldName}-${_index}`}
                          style={{ marginTop: '10px' }}
                        >
                          <BDateFieldTableEn
                            name={fieldName}
                            label={`${att.name}`}
                            value={currentItem ? currentItem[fieldName] : ''}
                            onChange={(e) => onChangeDynamicField(fieldName, e)}
                            required
                            key={`${fieldName}+${_index}`}
                            xs={12}
                            sm={6}
                          />
                          {currentItem[fieldName] && (
                            <AntdButton
                              style={{ marginRight: '5px', marginTop: '60px' }}
                              type='primary'
                              icon={<MinusCircleOutlined />}
                              onClick={() => {
                                onRemoveDynamicField(fieldName, '');
                              }}
                              key='btn-date-remove'
                            >
                              حذف ویژگی
                            </AntdButton>
                          )}
                          <br />
                        </Grid>
                      );
                    }
                    if (att.data_type_id === 4) {
                      const originalObject = convertArrayToKeyedObject(att.options, 'id', 'value');
                      const appendString = att.unit_id ? ` (${att.unit?.name || ''})` : '';
                      const updatedObject = {};
                      Object.keys(originalObject).forEach((OKey) => {
                        updatedObject[OKey] = originalObject[OKey] + appendString;
                      });
                      return (
                        <Grid
                          container
                          spacing={3}
                          style={{ marginTop: '10px' }}
                          key={`G-${att.data_type_id}-${fieldName}-${_index}`}
                        >
                          <BSelectField
                            sm={6}
                            xs={12}
                            label={`${att.name}`}
                            name={`option_${fieldName}`}
                            value={
                              currentOptionsItem && currentOptionsItem[fieldName]
                                ? currentOptionsItem[fieldName]?.option
                                : ''
                            }
                            onChange={(e) => onChangeDynamicOptionsField(fieldName, 'option', e)}
                            items={updatedObject}
                            key={`${fieldName}+${_index}`}
                          />
                          {currentOptionsItem &&
                            currentOptionsItem[fieldName] &&
                            currentOptionsItem[fieldName]?.option && (
                              <>
                                <AntdButton
                                  style={{ marginRight: '5px', marginTop: '60px' }}
                                  type='primary'
                                  icon={<MinusCircleOutlined />}
                                  onClick={() => {
                                    onRemoveDynamicOptionsField(fieldName, 'option', '');
                                    onRemoveOptions(att.id, currentOptionsItem[fieldName]?.option);
                                  }}
                                  key='btn-select-delete'
                                >
                                  حذف ویژگی
                                </AntdButton>
                              </>
                            )}
                        </Grid>
                      );
                    }
                    if (att.data_type_id === 5 || att.data_type_id === 7) {
                      const originalObject = convertArrayToKeyedObject(att.options, 'id', 'value');
                      const transformedObject = Object.fromEntries(
                        Object.entries(originalObject).map(([key, value]) => [key, value.slice(7)]),
                      );
                      const appendString = att.unit_id ? ` (${att.unit?.name || ''})` : '';
                      const updatedObject = {};
                      Object.keys(originalObject).forEach((OKey) => {
                        updatedObject[OKey] = originalObject[OKey] + appendString;
                      });
                      return (
                        <Grid
                          container
                          spacing={3}
                          style={{ marginTop: '10px' }}
                          key={`G-${att.data_type_id}-${fieldName}-${_index}`}
                        >
                          <BSelectField
                            sm={6}
                            xs={12}
                            label={`${att.name}`}
                            name={`option_${fieldName}`}
                            onChange={(e) => onAddMultipleChoiceArray(att.id, e)}
                            items={att.data_type_id === 7 ? transformedObject : updatedObject}
                            key={`${att.data_type_id}-${fieldName}-${_index}`}
                          />
                          <Row style={{ width: '100%', margin: '10px 0' }}>
                            {multipleChoiceArray
                              .filter((choice) => choice.attribute_id === att.id)
                              .map((eachOption, indc) => (
                                <Grid container spacing={3} style={{ marginTop: '10px', alignItems: 'center' }}>
                                  <BTextField
                                    xs={12}
                                    sm={4}
                                    label='مقدار انتخاب شده'
                                    value={
                                      att.data_type_id === 7
                                        ? transformedObject[eachOption.option_id]
                                        : updatedObject[eachOption.option_id]
                                    }
                                    key={`${eachOption.option_id}.${String(indc)}`}
                                    readOnly
                                    style={{ marginLeft: '10px' }}
                                  />
                                  <Grid item xs={12} sm={12}>
                                    <div style={{ fontSize: '16px' }}>انتخاب عکس ویژگی</div>
                                    <span>
                                      اندازه فایل آپلود شده حداکثر 300 کیلوبایت میباشد. فرمت عکس به صورت .webp یا
                                      jpeg/jpg یا png یا میباشد.
                                    </span>
                                    <br />
                                    <input
                                      // ref={mainFileInputRef}
                                      type='file'
                                      accept='.png, .jpg, .jpeg, .webp'
                                      onChange={(e) => mainFileSelectedHandler(e, att.id, eachOption.option_id)}
                                      style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
                                      multiple
                                    />
                                    <style>
                                      {`
                                        input[type="file"]::-webkit-file-upload-button {
                                          visibility: hidden;
                                        }
                                        input[type="file"]::before {
                                          content: 'Choose File';
                                          color: #333; /* Change the color to match your design */
                                          display: inline-block;
                                          background: #ddd; /* Change the background color to match your design */
                                          border: 1px solid #999; /* Change the border color to match your design */
                                          padding: 5px 10px;
                                          border-radius: 5px;
                                          cursor: pointer;
                                        }
                                        input[type="file"]:hover::before {
                                          background: #bbb; /* Change the background color on hover to match your design */
                                        }
                                      `}
                                    </style>
                                    {images.find(
                                      (img) =>
                                        String(img.attId) === String(att.id) &&
                                        String(img.attOptionId) === String(eachOption.option_id),
                                    ) && (
                                      <div>
                                        {images
                                          .filter(
                                            (img) =>
                                              String(img.attId) === String(att.id) &&
                                              String(img.attOptionId) === String(eachOption.option_id),
                                          )
                                          .map((img) => (
                                            <div key={`${img.attId}-${img.attOptionId}`}>
                                              {/* Render existing image URLs */}
                                              {img.imgs &&
                                                img.imgs.map((innerImg, imgIndex) => (
                                                  <div key={`att-${imgIndex}`} style={{ display: 'inline' }}>
                                                    <button
                                                      type='button'
                                                      onClick={() =>
                                                        removeImage(att.id, eachOption.option_id, innerImg.id)
                                                      }
                                                      aria-label='Remove Image'
                                                    >
                                                      <DeleteOutlined />
                                                    </button>
                                                    <img
                                                      alt='slider-main'
                                                      src={innerImg.img}
                                                      style={{ width: '200px', height: '200px' }}
                                                    />
                                                  </div>
                                                ))}

                                              {/* Render files as image previews */}
                                              {img.files &&
                                                img.files.map((file, fileIndex) => {
                                                  const reader = new FileReader();

                                                  // Create a wrapper component to manage the asynchronous reading
                                                  return (
                                                    <div key={`att-${fileIndex}`} style={{ display: 'inline' }}>
                                                      <button
                                                        type='button'
                                                        onClick={() =>
                                                          removeImage(att.id, eachOption.option_id, undefined, file)
                                                        }
                                                        aria-label='Remove Image'
                                                      >
                                                        <DeleteOutlined />
                                                      </button>
                                                      <img
                                                        alt='slider-main'
                                                        style={{ width: '200px', height: '200px' }}
                                                        ref={(imgElement) => {
                                                          if (imgElement) {
                                                            reader.onloadend = () => {
                                                              imgElement.src = reader.result; // Set the image src directly
                                                            };
                                                            reader.readAsDataURL(file); // Start reading the file
                                                          }
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                  </Grid>
                                  <AntdButton
                                    style={{ marginRight: '5px', marginTop: '60px' }}
                                    type='primary'
                                    icon={<MinusCircleOutlined />}
                                    onClick={async () => {
                                      await removeImage(att.id, eachOption.option_id);
                                      onRemoveOptions(att.id, eachOption.option_id);
                                      onRemoveMultipleChoiceArray(att.id, eachOption.option_id);
                                    }}
                                    key={`btn-${indc}-${eachOption.option_id}`}
                                  >
                                    حذف ویژگی
                                  </AntdButton>
                                </Grid>
                              ))}
                          </Row>
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </div>
              ))}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid item xs={12} sm={6}>
              <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
              <Button
                id='backToList'
                component={Link}
                to={{
                  pathname: '/product-management/products',
                }}
                color='secondary'
                variant='outlined'
              >
                بازگشت به لیست
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

export default ProductAttributePage;
