import React from 'react';
import PropTypes from 'prop-types';

const StatusBullet = (props) => {
  const { className, size, color, ...rest } = props;

  return <span {...rest} className={className} />;
};

StatusBullet.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['neutral', 'primary', 'info', 'success', 'warning', 'danger']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

StatusBullet.defaultProps = {
  size: 'md',
  color: 'default',
};

export default StatusBullet;
