import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import useHasPermission from '../../../../hooks/useHasPermission';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';

const initialItem = {
  name: '',
  email: '',
  password: '',
  mobile: '',
  birthday: '',
};

const cookies = new Cookies();

const UserPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const [roles, setRoles] = useState([]);
  const { viewReadOnly } = globalState;
  const [userRole, setUserRole] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const hasPermission = useHasPermission('user.assign.role');
  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/users',
    localStorageKey: 'users',
    initialItem,
  });

  useEffect(() => {
    if (id) {
      fetchItem({
        modelName: `/roles`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const res = [];
          for (let i = 1; i < response.data.length; i++) {
            res.push(response.data[i]);
          }
          setRoles(res);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
    if (currentItem?.roles?.length > 0) {
      setUserRole(currentItem?.roles[0].id);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    saveItem('users', _currentItem, id)
      .then(() => navigate('/access-management/users'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const onChangeRole = (e) => {
    setUserRole(e.currentTarget.value);
  };

  const onSubmitRole = useCallback(() => {
    setLoadingPermission(true);
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    if (userRole) {
      axios({
        method: 'post',
        url: `assign-role/${userRole}/to-user/${id}`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('userToken')}`,
        },
      })
        .then((res) => {
          if (res?.data?.error?.email) {
            BSwalShowError(res?.data?.error?.email);
          }
          navigate('/access-management/users');
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoadingPermission(false));
    }
  }, [userRole]);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <>
          <Card {...rest}>
            <Button
              id='backToListIcon'
              component={Link}
              to={{
                pathname: '/access-management/users',
              }}
              color='secondary'
              variant='outlined'
              style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
            >
              <ArrowLeftOutlined />
            </Button>
            <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
              <CardHeader title='اطلاعات کاربر' />
              <Divider />
              <CardContent>
                <br />
                <Grid container spacing={3}>
                  <BTextField
                    xs={12}
                    sm={6}
                    label='نام'
                    name='name'
                    value={currentItem?.name}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.name}
                    required
                  />
                  <BTextField
                    xs={12}
                    sm={6}
                    label='ایمیل'
                    name='email'
                    value={currentItem?.email}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.email}
                    required
                  />
                  <BTextField
                    xs={12}
                    sm={6}
                    label='موبایل'
                    name='mobile'
                    value={currentItem?.mobile}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.mobile}
                    required
                  />
                  <BTextField
                    xs={12}
                    sm={6}
                    type='password'
                    name='password'
                    label='رمز عبور'
                    value={currentItem?.password}
                    onChange={onChange}
                    required
                    error={errors?.password}
                  />
                  {/* <BDateFieldTable
                    name='birthday'
                    label='تاریخ تولد'
                    error={errors?.birthday}
                    value={currentItem?.birthday}
                    onChange={onChange}
                    required
                  /> */}
                  <BCheckBoxField
                    sm={12}
                    name='display'
                    label='فعال'
                    value={Number(currentItem?.display)}
                    onChange={onChange}
                    readOnly={viewReadOnly || currentItem.display === '1'}
                  />
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid item xs={12} sm={6}>
                  {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                  <Button
                    id='backToList'
                    component={Link}
                    to={{
                      pathname: '/access-management/users',
                    }}
                    color='secondary'
                    variant='outlined'
                  >
                    بازگشت به لیست
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
          {currentItem.id && hasPermission && (
            <Card {...rest} style={{ marginTop: '10px' }}>
              <CardHeader title='تخصیص نقش به کاربر' />
              <Divider />
              <CardContent>
                <br />
                <Grid container spacing={3}>
                  <BSelectField
                    sm={6}
                    xs={12}
                    label='انتخاب نقش'
                    required
                    name='role'
                    value={userRole}
                    onChange={onChangeRole}
                    error={errors?.role}
                    items={convertArrayToKeyedObject(roles, 'id', 'name')}
                    readOnly={viewReadOnly}
                  />
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid item xs={12} sm={6}>
                  {!viewReadOnly && (
                    <Button
                      color='secondary'
                      variant='outlined'
                      id='submitRole'
                      loading={loadingPermission}
                      onClick={onSubmitRole}
                      disabled={!userRole}
                    >
                      تخصیص نقش به کاربر
                    </Button>
                  )}
                </Grid>
              </CardActions>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default UserPage;
