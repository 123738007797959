import React, { createContext, useCallback, useState, useContext } from 'react';
import { GetItem } from '../../helpers/APIHandlers';

const initCredentials = localStorage.getItem('userCredentials')
  ? JSON.parse(localStorage.getItem('userCredentials'))
  : {
      user: { permissions: [] },
      authorisation: null,
    };

export const UserCredentialsContext = createContext({
  credentials: initCredentials,
  onChangeCredentials: (data) => Credential,
});

export const useUserCredentialsContext = () => useContext(UserCredentialsContext).credentials;

/**
 *
 * @param props
 * @returns {createContext} This will return the user context provider
 * @constructor
 */
export default function UserCredentialsProvider({ children }) {
  const [credentials, setCredentials] = useState(initCredentials);

  const onChangeCredentials = useCallback(
    async (data) => {
      if (data?.user?.id) {
        initCredentials.user.name = data?.user?.name;
        await GetItem('user').then((response) => {
          initCredentials.authorisation = data?.authorisation;
          if (response && response.data?.data?.roles[0]?.permissions) {
            const _permissions = [];
            for (let i = 0; i < response.data?.data?.roles[0]?.permissions.length; i++) {
              _permissions.push(response.data?.data?.roles[0]?.permissions[i]);
            }
            initCredentials.user.permissions = _permissions;
          }
          localStorage.setItem('userCredentials', JSON.stringify(initCredentials));
          setCredentials(initCredentials);
        });
      }
      return credentials;
    },
    [credentials],
  );
  return (
    <UserCredentialsContext.Provider value={{ credentials, onChangeCredentials }}>
      {children}
    </UserCredentialsContext.Provider>
  );
}
