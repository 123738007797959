import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Checkbox, Typography, Skeleton } from '@mui/material';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { DashboardPanelContext } from '../../context/DashboardContext';

const BCheckBoxField = ({ label, style = null, name, value, readOnly = false, onChange, xs = 12, sm = 4 }) => {
  const {
    globalState: { pageLoading },
  } = useContext(DashboardPanelContext);
  return (
    <>
      <Grid item xs={xs} sm={sm} style={style}>
        {pageLoading && (
          <Box display='flex' alignItems='center'>
            <Skeleton variant='rect'>
              <Avatar />
            </Skeleton>
            <Box width='25%' style={{ marginRight: 5 }}>
              <Skeleton width='100%'>
                <Typography>.</Typography>
              </Skeleton>
            </Box>
          </Box>
        )}
        {!pageLoading && (
          <FormControlLabel
            control={
              <Checkbox
                disabled={readOnly}
                checked={value}
                onChange={(e, b) =>
                  onChange({
                    currentTarget: {
                      name,
                      value: b,
                    },
                  })
                }
              />
            }
            label={label}
          />
        )}
      </Grid>
    </>
  );
};

BCheckBoxField.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  xs: PropTypes.number,
  sm: PropTypes.number,
};

export default BCheckBoxField;
