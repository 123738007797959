import { Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../components/context/DashboardContext';
import BSubmitButton from '../../../components/buttons/SubmitButton';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../common/utilities';
import { BSwalShowError } from '../../../common/BSwal';
import BSelectField from '../../../components/inputs/forms/BSelectField';
import { SET_CURRENT_CURRENCY } from '../../../components/context/reducer/actionTypes';

const initialItem = {
  currency_symbol: 'ir',
};

const currencies = [
  { id: 'usd', name: 'دلار آمریکا' },
  { id: 'cad', name: 'دلار کانادا' },
  { id: 'aud', name: 'دلار استرالیا' },
  { id: 'eur', name: 'یورو' },
  { id: 'try', name: 'لیر ترکیه' },
  { id: 'gbp', name: 'پوند انگلیس' },
  { id: 'aed', name: 'درهم امارات' },
  { id: 'irt', name: 'تومان' },
];

const CurrencyPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem, globalDispatch } = useContext(DashboardPanelContext);
  const [currentItem, setCurrentItem] = useState(initialItem);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  useEffect(() => {
    fetchItem({
      modelName: `/get-default-currency`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setCurrentItem({ currency_symbol: response.data });
      }
    });
  }, []);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    saveItem('set-default-currency', _currentItem, null)
      .then(() => {
        globalDispatch({
          type: SET_CURRENT_CURRENCY,
          value: {
            id: _currentItem.currency_symbol,
            name: currencies.find((curr) => curr.id === _currentItem.currency_symbol).name,
          },
        });
        navigate('/settings-management/get-default-currency');
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='انتخاب واحد پولی پیش فرض وبسایت' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BSelectField
                  sm={4}
                  xs={12}
                  label='واحد پولی'
                  name='currency_symbol'
                  value={currentItem?.currency_symbol}
                  onChange={onChange}
                  error={errors?.currency_symbol}
                  items={convertArrayToKeyedObject(currencies, 'id', 'name')}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitCurrency' label='ذخیره اطلاعات' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }} />
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default CurrencyPage;
