import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { DashboardPanelContext } from '../../context/DashboardContext';
import { ReactFormHookServerError } from '../../../common/utilities';
import BErrorFormHelperText from '../../BErrorFormHelperText';
import BInputLabel from '../../BInputLabel';
import './BTextField.css';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(4),
//   },
//   customInputFormControl: {
//     width: '100%',
//   },
//   avatar: {
//     width: 120,
//     height: 120,
//     margin: 20,
//   },
// }));

const BTextareaField = ({
  label,
  tooltip,
  name,
  value,
  readOnly = false,
  min = null,
  max = null,
  step = null,
  onFocus,
  placeholder = null,
  error,
  onChange,
  setValue,
  onKeyDown,
  removeArrows = false,
  type = 'text',
  prefix,
  suffix,
  required = false,
  xs = 12,
  sm = 4,
  ...rest
}) => {
  const {
    globalState: { pageLoading, error: serverError },
  } = useContext(DashboardPanelContext);

  const onPaste = useMemo(
    () => (e) => {
      const str = e.clipboardData.getData('Text');
      for (let i = 0; i < str.length; i++) {
        if (onKeyDown && !onKeyDown({ preventDefault: e.preventDefault, key: str.charAt(i) }))
          return e.preventDefault();
      }
    },
    [onKeyDown],
  );

  const handleOnChange = (e) =>
    onChange
      ? onChange({
          currentTarget: {
            value: _.get(e, 'currentTarget.value', ''),
            name: _.get(e, 'currentTarget.name', null),
          },
        })
      : () => undefined;
  return (
    <>
      <Grid item xs={xs} sm={sm}>
        {pageLoading ? (
          <>
            <Typography variant='h1'>
              <Skeleton height={100} />
            </Typography>
          </>
        ) : (
          <>
            <BInputLabel
              tooltip={tooltip}
              label={label}
              error={error}
              name={name}
              required={required}
              serverError={serverError}
            />

            <TextField
              id={name}
              rows={4}
              autoComplete='current-password'
              error={error?.message || ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
              type={type}
              name={name}
              {...rest}
              placeholder={placeholder}
              onKeyDown={onKeyDown || (() => undefined)}
              onPaste={onPaste}
              onFocus={onFocus || (() => undefined)}
              className={removeArrows ? 'removeUpAndDown' : null}
              onChange={handleOnChange}
              fullWidth
              variant='outlined'
              inputProps={{
                min,
                max,
                step,
                // helperText: "h",
              }}
              disabled={readOnly}
              InputProps={{
                readOnly,
                startAdornment: prefix ? <InputAdornment position='start'>{prefix}</InputAdornment> : undefined,
                endAdornment: suffix ? <InputAdornment position='start'>{suffix}</InputAdornment> : undefined,
                // className: classes.customInputFormControl,
              }}
              value={value || ''}
              multiline
            />
            <BErrorFormHelperText text={error?.message} />
            {ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
          </>
        )}
      </Grid>
    </>
  );
};

BTextareaField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  min: PropTypes.any,
  max: PropTypes.any,
  step: PropTypes.any,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  setValue: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  removeArrows: PropTypes.bool,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default React.memo(BTextareaField);
