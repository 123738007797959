import React, { useEffect, useState, useMemo } from 'react';
import { filterDeep } from 'deepdash-es/standalone';
import _ from 'lodash';
import {
  DatabaseOutlined,
  FileImageOutlined,
  MenuOutlined,
  SettingOutlined,
  DiffOutlined,
  CustomerServiceOutlined,
  UsergroupDeleteOutlined,
  BookOutlined,
  AppstoreOutlined,
  UserSwitchOutlined,
  QuestionCircleOutlined,
  CommentOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  ShoppingOutlined,
  SettingFilled,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Profile } from './components';

const { Sider } = Layout;

const Sidebar = ({ credentials }) => {
  const [credential, setCredential] = useState(credentials);
  const location = useLocation();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    setCredential(credentials);
  }, [credentials]);

  const [menus] = useState(() => [
    {
      key: '6',
      icon: <AppstoreAddOutlined />,
      label: 'مدیریت  محصولات',
      children: [
        {
          key: '60',
          permission: 'product-category.index',
          label: 'دسته بندی محصولات',
          children: [
            {
              key: '600',
              path: 'product-management/main-product-categories',
              permission: 'product-category.index',
              label: <Link to='/product-management/main-product-categories'>دسته های والد</Link>,
            },
            {
              key: '601',
              path: 'product-management/product-categories',
              permission: 'product-category.index',
              label: <Link to='/product-management/product-categories'>دسته های فرعی و اصلی </Link>,
            },
          ],
        },
        {
          key: '61',
          path: 'product-management/attribute-categories',
          permission: 'attribute-category.index',
          label: <Link to='/product-management/attribute-categories'> دسته بندی ویژگی های محصولات</Link>,
        },
        {
          key: '62',
          path: 'product-management/brands',
          permission: 'brand.index',
          label: <Link to='/product-management/brands'>برندها</Link>,
        },
        {
          key: '63',
          path: 'product-management/units',
          permission: 'unit.index',
          label: <Link to='/product-management/units'>واحدها</Link>,
        },
        {
          key: '64',
          path: 'product-management/attributes',
          permission: 'attribute.index',
          label: <Link to='/product-management/attributes'> ویژگی های محصولات</Link>,
        },
        {
          key: '65',
          path: 'product-management/products',
          permission: 'product.index',
          label: <Link to='/product-management/products'> همه محصولات</Link>,
        },
        {
          key: '42',
          path: 'customer-management/discounts',
          permission: 'discounts.index',
          label: <Link to='/customer-management/discounts'>تخفیف ها</Link>,
        },
      ],
    },
    {
      key: '8',
      icon: <ShoppingOutlined />,
      label: 'مدیریت  سفارش ها',
      children: [
        {
          key: '80',
          permission: 'admin.get-all-provinces.getAllProvinces',
          path: 'order-management/provinces',
          label: <Link to='/order-management/provinces'>مدیریت استان ها</Link>,
        },
        {
          key: '81',
          permission: 'admin.get-all-cities.getAllCities',
          path: 'order-management/cities',
          label: <Link to='/order-management/cities'>مدیریت شهر ها</Link>,
        },
        {
          key: '82',
          path: 'delivery-management/delivery-methods',
          permission: 'admin.delivery_method.index',
          label: <Link to='/delivery-management/delivery-methods'>مدیریت روش های ارسال</Link>,
        },
        {
          key: '83',
          path: 'order-management/order-status',
          permission: 'roles.assign.permission',
          label: <Link to='/order-management/orders-status'>لیست وضعیت سفارش ها</Link>,
        },
        {
          key: '84',
          path: 'order-management/orders',
          permission: 'admin.orders.index',
          label: <Link to='/order-management/orders'>لیست سفارش ها</Link>,
        },
      ],
    },
    {
      key: '1',
      icon: <DatabaseOutlined />,
      label: 'مدیریت محتوا',
      children: [
        {
          key: '10',
          icon: <FileImageOutlined />,
          label: 'مدیریت اسلایدشو',
          children: [
            {
              key: '100',
              path: 'cms/slideshow-list',
              permission: 'sliders.index',
              label: <Link to='/cms/slideshow-list'> لیست اسلایدشو</Link>,
            },
            {
              key: '101',
              path: 'cms/slider-list',
              permission: 'galleries.show',
              label: <Link to='/cms/slider-list'>(برنامه نویس) لیست گالری اسلایدشو</Link>,
            },
          ],
        },
        {
          key: '11',
          icon: <MenuOutlined />,
          label: 'مدیریت منو',
          children: [
            {
              key: '111',
              path: 'cms/menu-items',
              permission: 'menu-items.index',
              label: <Link to='/cms/menu-items'> لیست آیتم های منو</Link>,
            },
            {
              key: '112',
              path: 'cms/menu-list',
              permission: 'menus.show',
              label: <Link to='/cms/menu-list'>(برنامه نویس) لیست منوها</Link>,
            },
          ],
        },
        {
          key: '13',
          icon: <DiffOutlined />,
          label: 'مدیریت صفحات',
          children: [
            {
              key: '131',
              path: 'cms/pages',
              permission: 'pages.index',
              icon: <DiffOutlined />,
              label: <Link to='/cms/pages'> لیست صفحات</Link>,
            },
            // {
            //   key: '132',
            //   path: 'cms/faqs',
            //   permission: 'pages.index',
            //   icon: <QuestionCircleOutlined />,
            //   label: <Link to='/cms/faqs'> لیست پرسش های متداول</Link>,
            // },
            // {
            //   key: '133',
            //   path: 'cms/comments',
            //   permission: 'pages.index',
            //   icon: <CommentOutlined />,
            //   label: <Link to='/cms/comments'> لیست نظرات</Link>,
            // },
          ],
        },
        {
          key: '12',
          icon: <SettingOutlined />,
          label: 'مدیریت تنظیمات',
          children: [
            {
              key: '121',
              path: 'cms/settings',
              permission: 'settings.index',
              label: <Link to='/cms/settings'> لیست تنظیمات</Link>,
            },
            {
              key: '122',
              path: 'cms/setting-sections',
              permission: 'setting-sections.show',
              label: <Link to='/cms/setting-sections'>(برنامه نویس) لیست قسمت های تنظیمات</Link>,
            },
          ],
        },
      ],
    },
    {
      key: '2',
      icon: <AppstoreOutlined />,
      label: 'مدیریت خدمات',
      children: [
        {
          key: '20',
          icon: <CustomerServiceOutlined />,
          label: 'مدیریت سرویس ها',
          children: [
            {
              key: '200',
              path: 'salon-management/service-categories',
              permission: 'service-categories.index',
              label: <Link to='/salon-management/service-categories'> لیست کتگوری سرویس ها</Link>,
            },
            {
              key: '201',
              path: 'salon-management/services',
              permission: 'services.index',
              label: <Link to='/salon-management/services'>لیست سرویس ها</Link>,
            },
          ],
        },
        {
          key: '21',
          icon: <CustomerServiceOutlined />,
          label: 'مدیریت درخواست های مشاوره',
          children: [
            {
              key: '210',
              path: 'salon-management/contact-form',
              permission: 'contact-form.index',
              label: <Link to='/salon-management/contact-forms'> لیست درخواست های مشاوره</Link>,
            },
          ],
        },
      ],
    },
    {
      key: '3',
      icon: <UsergroupDeleteOutlined />,
      label: 'مدیریت مشتریان',
      children: [
        {
          key: '30',
          path: 'customer-management/customers',
          permission: 'customers.index',
          label: <Link to='/customer-management/customers'> لیست مشتریان</Link>,
        },
      ],
    },
    {
      key: '4',
      icon: <BookOutlined />,
      label: 'مدیریت دوره های آموزشی',
      children: [
        {
          key: '40',
          path: 'course-management/videos',
          permission: 'videos.index',
          label: <Link to='/course-management/videos'> لیست ویدیوها</Link>,
        },
        {
          key: '41',
          path: 'course-management/courses',
          permission: 'courses.index',
          label: <Link to='/course-management/courses'> لیست دوره ها</Link>,
        },
      ],
    },
    {
      key: '7',
      icon: <BarChartOutlined />,
      label: 'گزارش ها',
      children: [
        {
          key: '70',
          path: 'reports/products',
          permission: 'roles.index',
          label: <Link to='/reports/products'>بر اساس محصول</Link>,
        },
        {
          key: '71',
          path: 'reports/orders',
          permission: 'roles.index',
          label: <Link to='/reports/orders'>بر اساس سفارش</Link>,
        },
        {
          key: '72',
          path: 'reports/customers',
          permission: 'roles.index',
          label: <Link to='/reports/customers'>بر اساس مشتری</Link>,
        },
        {
          key: '73',
          path: 'reports/discounts',
          permission: 'roles.index',
          label: <Link to='/reports/discounts'>بر اساس کد تخفیف</Link>,
        },
      ],
    },
    {
      key: '5',
      icon: <UserSwitchOutlined />,
      label: 'مدیریت  دسترسی',
      children: [
        {
          key: '50',
          path: 'access-management/roles',
          permission: 'roles.index',
          label: <Link to='/access-management/roles'> لیست نقش ها</Link>,
        },
        {
          key: '51',
          path: 'access-management/users',
          permission: 'users.index',
          label: <Link to='/access-management/users'> لیست کاربران</Link>,
        },
      ],
    },
    {
      key: '9',
      icon: <SettingFilled />,
      label: 'مدیریت تنظیمات سایت',
      children: [
        {
          key: '90',
          path: 'settings-management/get-default-currency',
          permission: 'admin.set.default.currency',
          label: <Link to='/settings-management/get-default-currency'>واحد پولی پیش فرض</Link>,
        },
      ],
    },
  ]);

  const [pages, setPages] = useState([]);
  const [current, setCurrent] = useState('');

  useEffect(() => {
    const foundMenu = menus.find((menu) => menu.children?.find((submenu) => location.pathname.includes(submenu.path)));

    if (foundMenu) {
      const foundChild = foundMenu.children.find((submenu) => location.pathname.includes(submenu.path));

      if (foundChild) {
        setCurrent(foundChild.key);
      } else {
        setCurrent(foundMenu.key);
      }
    }
  }, [location.pathname, menus]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    setPermissions(_.get(credential.user, 'permissions', []));
  }, [credential]);

  useEffect(() => {
    if (permissions.length > 0 && menus.length > 0)
      setPages(
        filterDeep(
          menus,
          (value) => {
            if (value) {
              if (permissions.find((permission) => permission.name === value.permission)?.id) {
                return true;
              }
              return false;
            }
            return false;
          },
          { childrenPath: 'children', checkCircular: false },
        ),
      );
  }, [permissions, menus]);

  return (
    <Sider theme='light' breakpoint='lg' collapsedWidth='0' style={{ minHeight: '100%', color: '#38291E' }} width={300}>
      <div className='logo'>
        <Profile credentials={credential} />
      </div>
      <Menu
        theme='dark'
        mode='inline'
        items={pages}
        onClick={onClick}
        selectedKeys={[current]}
        style={{ minHeight: '100vh' }}
      />
    </Sider>
  );
};

export default Sidebar;
