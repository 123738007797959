// This file contain all the variables used in the js files to avoid self modification and duplication

export const font = 'IRANSans';
export const fontSize = '12px';
export const fontLight = 'IRANSans-light';
export const fontBold = 'IRANSans-bold';
export const primaryMainColor = '#0B2545';
export const secondaryMainColor = '#16C086';
export const mainHintColor = '#BDBDBD';
export const mainBorderColor = '#E1E1E1';
export const primaryTextColor = '#1D313B';
export const secondaryTextColor = '#757575';
export const lightWhiteGreyColor = '#F7F9FA';
export const whiteColor = '#FFFFFF';
export const anchorTagColor = '#2da3c5';
export const errorColor = '#df0000';
