import { GlobalState } from '../contextInterfaces';
import {
  SET_ITEMS_LOADING,
  SET_ITEM_LOADING,
  SET_ERROR,
  SET_SAVE_SUCCESS,
  SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER,
  SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER_NAME,
  SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER,
  SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER_NAME,
  SET_SELECTED_MENU_ID,
  SET_CURRENT_DATE,
  SET_VIEW_READ_ONLY,
  SET_PAGE_LOADING,
  SET_HISTORY_ID_LIST,
  SET_ENUMS,
  SET_INITIAL_STATE,
  SET_LOADING_HISTORY,
  SET_ENUM,
  SET_CURRENT_CURRENCY,
} from './actionTypes';

type types =
  | typeof SET_ITEMS_LOADING
  | typeof SET_ITEM_LOADING
  | typeof SET_ERROR
  | typeof SET_SAVE_SUCCESS
  | typeof SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER
  | typeof SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER_NAME
  | typeof SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER
  | typeof SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER_NAME
  | typeof SET_SELECTED_MENU_ID
  | typeof SET_CURRENT_DATE
  | typeof SET_VIEW_READ_ONLY
  | typeof SET_PAGE_LOADING
  | typeof SET_HISTORY_ID_LIST
  | typeof SET_ENUMS
  | typeof SET_INITIAL_STATE
  | typeof SET_LOADING_HISTORY
  | typeof SET_ENUM
  | typeof SET_CURRENT_CURRENCY;

const reducer = (state: GlobalState, action: { type: types; value: any }): GlobalState => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return {
        ...action.value,
      };
    case SET_ENUM:
      return {
        ...state,
        enums: { ...state.enums, [action.value.name]: action.value.items },
      };
    case SET_ENUMS:
      return {
        ...state,
        enums: { ...action.value },
      };
    case SET_SAVE_SUCCESS:
      return {
        ...state,
        saveSuccess: action.value,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.value,
      };
    case SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER:
      return {
        ...state,
        currentCustomerPrimaryParameter: action.value,
      };
    case SET_CURRENT_CUSTOMER_PRIMARY_PARAMETER_NAME:
      return {
        ...state,
        currentCustomerPrimaryParameterName: action.value,
      };
    case SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER:
      return {
        ...state,
        currentPersonnelPrimaryParameter: action.value,
      };
    case SET_CURRENT_PERSONNEL_PRIMARY_PARAMETER_NAME:
      return {
        ...state,
        currentPersonnelPrimaryParameterName: action.value,
      };
    case SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.value,
      };
    case SET_VIEW_READ_ONLY:
      return {
        ...state,
        viewReadOnly: action.value,
      };
    case SET_HISTORY_ID_LIST:
      return {
        ...state,
        historyIdList: action.value,
      };
    case SET_ITEM_LOADING:
      return {
        ...state,
        itemLoading: action.value,
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.value,
      };
    case SET_ITEMS_LOADING:
      return {
        ...state,
        itemsLoading: action.value,
      };
    case SET_SELECTED_MENU_ID:
      return {
        ...state,
        selectedMenuId: action.value,
      };
    case SET_LOADING_HISTORY:
      return {
        ...state,
        isLoadingHistory: action.value,
      };
    case SET_CURRENT_CURRENCY:
      return {
        ...state,
        currentCurrency: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
