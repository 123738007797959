import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import { DashboardPanelContext } from '../../context/DashboardContext';
import { ReactFormHookServerError } from '../../../common/utilities';
import BErrorFormHelperText from '../../BErrorFormHelperText';
import BInputLabel from '../../BInputLabel';
import './BTextField.css';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(4),
//   },
//   customInputFormControl: {
//     width: '100%',
//   },
//   avatar: {
//     width: 120,
//     height: 120,
//     margin: 20,
//   },
// }));

const BTextField = ({
  label,
  tooltip = undefined,
  name = '',
  value,
  readOnly = false,
  min = null,
  max = null,
  step = null,
  onFocus = undefined,
  placeholder = null,
  error = undefined,
  onChange = (resp) => resp,
  onKeyDown = undefined,
  removeArrows = false,
  type = 'text',
  prefix = undefined,
  suffix = undefined,
  required = false,
  xs = 12,
  sm = 4,
  showServerError = true,
  serverError = undefined,
  thousandSeparator = false,
  allowLeadingZeros = true,
  format = undefined,
  ...rest
}) => {
  const {
    globalState: { pageLoading, error: _serverError },
  } = useContext(DashboardPanelContext);
  const onPaste = useMemo(
    () => (e) => {
      const str = e.clipboardData.getData('Text');
      for (let i = 0; i < str.length; i++) {
        if (onKeyDown && !onKeyDown({ preventDefault: e.preventDefault, key: str.charAt(i) }))
          return e.preventDefault();
      }
    },
    [onKeyDown],
  );

  const handleOnChange = (e) =>
    onChange
      ? onChange({
          currentTarget: {
            value: _.get(e, 'currentTarget.value', ''),
            name: _.get(e, 'currentTarget.name', null),
          },
        })
      : () => undefined;
  return (
    <>
      <Grid item xs={xs} sm={sm}>
        {pageLoading ? (
          <>
            <Typography variant='h1'>
              <Skeleton height={100} />
            </Typography>
          </>
        ) : (
          <>
            <BInputLabel
              tooltip={tooltip}
              label={label}
              error={error}
              name={name}
              required={required}
              serverError={serverError || _serverError}
            />
            {type === 'number' && (
              <NumericFormat
                onValueChange={(values) => {
                  handleOnChange({
                    currentTarget: {
                      name,
                      value: values.value,
                    },
                  });
                }}
                thousandSeparator={thousandSeparator}
                format={format}
                customInput={TextField}
                id={name}
                autoComplete='current-password'
                error={
                  error?.message || ReactFormHookServerError(serverError || _serverError, 'messages', 'fieldName', name)
                }
                name={name}
                allowLeadingZeros={allowLeadingZeros}
                {...rest}
                placeholder={placeholder}
                onKeyDown={onKeyDown || (() => undefined)}
                onPaste={onPaste}
                onFocus={onFocus || (() => undefined)}
                className={removeArrows ? 'removeUpAndDown' : null}
                fullWidth
                variant='outlined'
                inputProps={{
                  min,
                  max,
                  step,
                  style: {
                    direction: 'ltr',
                    textAlign: 'right',
                  },
                  // helperText: "h",
                }}
                disabled={readOnly}
                InputProps={{
                  readOnly,
                  startAdornment: prefix ? <InputAdornment position='start'>{prefix}</InputAdornment> : undefined,
                  endAdornment: suffix ? <InputAdornment position='start'>{suffix}</InputAdornment> : undefined,
                  // className: classes.customInputFormControl,
                }}
                value={value || ''}
              />
            )}

            {type !== 'number' && (
              <TextField
                id={name}
                data-testid={name}
                autoComplete='current-password'
                error={
                  error?.message || ReactFormHookServerError(serverError || _serverError, 'messages', 'fieldName', name)
                }
                type={type}
                name={name}
                {...rest}
                placeholder={placeholder}
                onKeyDown={onKeyDown || (() => undefined)}
                onPaste={onPaste}
                onFocus={onFocus || (() => undefined)}
                className={removeArrows ? 'removeUpAndDown' : null}
                onChange={handleOnChange}
                fullWidth
                variant='outlined'
                inputProps={{
                  min,
                  max,
                  step,
                  // helperText: "h",
                }}
                disabled={readOnly}
                InputProps={{
                  readOnly,
                  startAdornment: prefix ? <InputAdornment position='start'>{prefix}</InputAdornment> : undefined,
                  endAdornment: suffix ? <InputAdornment position='start'>{suffix}</InputAdornment> : undefined,
                  // className: classes.customInputFormControl,
                }}
                value={value || ''}
              />
            )}

            <BErrorFormHelperText text={error?.message} />
            {showServerError && ReactFormHookServerError(serverError || _serverError, 'messages', 'fieldName', name)}
          </>
        )}
      </Grid>
    </>
  );
};

BTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  min: PropTypes.any,
  max: PropTypes.any,
  step: PropTypes.any,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  removeArrows: PropTypes.bool,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  showServerError: PropTypes.bool,
  thousandSeparator: PropTypes.bool,
  format: PropTypes.string,
  allowLeadingZeros: PropTypes.bool,
};

export default React.memo(BTextField);
