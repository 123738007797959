import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';

const initialItem = {
  name: '',
  link: '',
  open_in: null,
  position: '100',
  display: true,
  menu_id: null,
  parent_id: null,
  type: null,
};

const OpenIn = [
  {
    key: 'current_page',
    name: 'تب فعلی',
  },
  {
    key: 'new_page',
    name: 'تب جدید',
  },
];

const MenuType = [
  {
    key: 'template',
    name: 'صفحه جدید',
  },
  {
    key: 'external',
    name: 'لینک دلخواه',
  },
  {
    key: 'data',
    name: 'انتخاب از صفحات موجود',
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function Quote({ quote, index }) {
  return (
    <Draggable draggableId={String(quote.id)} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <span
            style={{
              border: quote.id === 1000 ? '1px solid #000' : 'unset',
              padding: quote.id === 1000 ? '0 10px' : '0',
            }}
          >
            {' '}
            {quote.name}
          </span>
        </div>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(({ quotes }) =>
  quotes.map((quote, index) => <Quote quote={quote} index={index} key={quote.id} />),
);

const MenuItemsPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const [menus, setMenus] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [pages, setPages] = useState([]);
  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [state, setState] = useState({ quotes: [] });
  const [selectedTab, setSelectedTab] = useState('1');
  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/menu-items',
    localStorageKey: 'menu-items',
    initialItem,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/pages`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/pages?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setPages(res);
      }
    });
    fetchItem({
      modelName: `/menus`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          if (response.data?.data[i].display === '1') {
            res.push(response.data?.data[i]);
          }
        }
        if (response.data?.last_page > 1) {
          for (let j = 2; j <= response.data?.last_page; j++) {
            // eslint-disable-next-line no-await-in-loop
            await fetchItem({
              modelName: `/menus?page=${j}`,
              showLoader: true,
            }).then((r) => {
              for (let n = 0; n < r.data?.data?.length; n++) {
                if (r.data?.data[n].display === '1') {
                  res.push(r.data?.data[n]);
                }
              }
            });
          }
        }
        setMenus(res);
      }
    });
  }, []);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  useEffect(() => {
    if (currentItem?.menu_id) {
      fetchItem({
        modelName: `/menu-items`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (
              (response.data?.data[i].display === '1' ||
                (response.data?.data[i].display === '0' && response.data?.data[i].id === id)) &&
              String(response.data?.data[i].menu_id) === String(currentItem?.menu_id)
            ) {
              res.push(response.data?.data[i]);
            }
          }
          if (response.data?.last_page > 1) {
            for (let j = 2; j <= response.data?.last_page; j++) {
              // eslint-disable-next-line no-await-in-loop
              await fetchItem({
                modelName: `/menu-items?page=${j}`,
                showLoader: true,
              }).then((r) => {
                for (let n = 0; n < r.data?.data?.length; n++) {
                  if (
                    (r.data?.data[n].display === '1' ||
                      (r.data?.data[n].display === '0' && r.data?.data[n].id === id)) &&
                    String(r.data?.data[n].menu_id) === String(currentItem?.menu_id)
                  ) {
                    res.push(r.data?.data[n]);
                  }
                }
              });
            }
            setMenuItems(res);
            const _res = [...res];
            if ((selectedTab === '1' || (currentItem?.id && !currentItem?.parent_id)) && !currentItem.data) {
              const myRes = _res.filter((r) => !r.parent_id);
              if (!currentItem.id) {
                myRes.push({ name: currentItem.name || 'منو جدید', id: 1000 });
              }
              setState({ quotes: myRes.sort((a, b) => Number(b.position) - Number(a.position)) });
            }
          } else {
            setMenuItems(res);
            const _res = [...res];
            if ((selectedTab === '1' || (currentItem?.id && !currentItem?.parent_id)) && !currentItem.data) {
              const myRes = _res.filter((r) => !r.parent_id);
              if (!currentItem.id) {
                myRes.push({ name: currentItem.name || 'منو جدید', id: 1000 });
              }
              setState({ quotes: myRes.sort((a, b) => Number(b.position) - Number(a.position)) });
            }
          }
        }
      });
    }
  }, [currentItem?.menu_id]);

  useEffect(() => {
    if (currentItem?.parent_id) {
      if (menuItems?.length > 0) {
        const res = menuItems?.filter((item) => Number(item.parent_id) === Number(currentItem?.parent_id));
        if (!currentItem.id) {
          res.push({ name: currentItem.name || 'منو جدید', id: 1000 });
        }
        setState({ quotes: res.sort((a, b) => Number(b.position) - Number(a.position)) });
      }
    }
  }, [currentItem?.parent_id, menuItems]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    const _index = currentItem?.id
      ? state?.quotes?.findIndex((item) => String(item.id) === String(currentItem?.id))
      : state?.quotes?.findIndex((item) => item.id === 1000);
    let _position = '0';
    if (_index || _index === 0) {
      if (state?.quotes?.length > 2) {
        if (_index === state.quotes.length - 1) {
          _position = Number(state?.quotes[_index - 1]?.position) - Math.floor(Math.random() * 100);
        } else if (_index === 0) {
          _position = Number(state?.quotes[_index + 1]?.position) + Math.floor(Math.random() * 100);
        } else {
          const max = Number(state?.quotes[_index - 1]?.position);
          const min = Number(state?.quotes[_index + 1]?.position);
          _position = String(Math.floor(Math.random() * (max - min)) + min);
        }
      }
      if (state?.quotes?.length === 1) {
        if (currentItem.parent_id) {
          _position = 1000;
        } else {
          _position = 3000;
        }
      }
      if (state?.quotes?.length === 2) {
        if (_index === 0) {
          _position = Number(state?.quotes[_index + 1]?.position) + Math.floor(Math.random() * 100);
        }
        if (_index === 1) {
          _position = Number(state?.quotes[_index - 1]?.position) - Math.floor(Math.random() * 100);
        }
      }
      _currentItem.position = String(_position);
    }
    saveItem('menu-items', _currentItem, id)
      .then(() => navigate('/cms/menu-items'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChangeSelectedTab = (key) => {
    setState({ quotes: [] });
    setSelectedTab(key);
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(state.quotes, result.source.index, result.destination.index);

    setState({ quotes });
  };

  const items = [
    {
      key: '1',
      label: `ایجاد منو اصلی`,
      children: (
        <div style={{ margin: 10 }}>
          {currentItem && (
            <Card {...rest}>
              <Button
                id='backToListIcon'
                component={Link}
                to={{
                  pathname: '/cms/menu-items',
                }}
                color='secondary'
                variant='outlined'
                style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
              >
                <ArrowLeftOutlined />
              </Button>
              <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
                <CardHeader title='اطلاعات منو' />
                <Divider />
                <CardContent>
                  <br />
                  <Grid container spacing={3}>
                    <BTextField
                      xs={12}
                      sm={6}
                      label='عنوان'
                      name='name'
                      value={currentItem?.name}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      error={errors?.name}
                      required
                      // {...register('name', { required: true })}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نحوه باز شدن صفحه'
                      required
                      name='open_in'
                      value={currentItem?.open_in}
                      onChange={onChange}
                      error={errors?.open_in}
                      items={convertArrayToKeyedObject(OpenIn, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نوع لینک'
                      required
                      name='type'
                      value={currentItem?.type}
                      onChange={onChange}
                      error={errors?.type}
                      items={convertArrayToKeyedObject(MenuType, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {currentItem?.type && currentItem?.type === 'external' && (
                      <BTextField
                        xs={12}
                        sm={6}
                        label='لینک به صفحه'
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        readOnly={viewReadOnly}
                        required
                      />
                    )}
                    {currentItem?.type && (currentItem?.type === 'data' || currentItem?.type === 'template') && (
                      <BSelectField
                        sm={6}
                        xs={12}
                        label='لینک به صفحه'
                        required
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        items={convertArrayToKeyedObject(pages, 'name', 'description')}
                        readOnly={viewReadOnly}
                      />
                    )}
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='محل نمایش منو'
                      required
                      name='menu_id'
                      value={currentItem?.menu_id}
                      onChange={onChange}
                      error={errors?.menu_id}
                      items={convertArrayToKeyedObject(menus, 'id', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {/* <BTextField
                      xs={12}
                      sm={6}
                      label='ترتیب منو'
                      name='position'
                      value={currentItem?.position}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      error={errors?.position}
                      required
                    /> */}
                    {currentItem?.menu_id && state.quotes.length > 1 && (
                      <Grid item xs={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='list'>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                <h5>انتخاب ترتیب منوها</h5>
                                <QuoteList quotes={state.quotes} />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    )}
                    <BCheckBoxField
                      sm={12}
                      name='display'
                      label='نمایش داده شود'
                      value={Number(currentItem?.display)}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                    />
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item xs={12} sm={6}>
                    {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}
                  >
                    <Button
                      id='backToList'
                      component={Link}
                      to={{
                        pathname: '/cms/menu-items',
                      }}
                      color='secondary'
                      variant='outlined'
                    >
                      بازگشت به لیست
                    </Button>
                  </Grid>
                </CardActions>
              </form>
            </Card>
          )}
        </div>
      ),
    },
    {
      key: '2',
      label: `ایجاد زیر منو`,
      children: (
        <div style={{ margin: 10 }}>
          {currentItem && (
            <Card {...rest}>
              <Button
                id='backToListIcon'
                component={Link}
                to={{
                  pathname: '/cms/menu-items',
                }}
                color='secondary'
                variant='outlined'
                style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
              >
                <ArrowLeftOutlined />
              </Button>
              <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
                <CardHeader title='اطلاعات زیر منو' />
                <Divider />
                <CardContent>
                  <br />
                  <Grid container spacing={3}>
                    <BTextField
                      xs={12}
                      sm={6}
                      label='عنوان'
                      name='name'
                      value={currentItem?.name}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      error={errors?.name}
                      required
                      // {...register('name', { required: true })}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نحوه باز شدن صفحه'
                      required
                      name='open_in'
                      value={currentItem?.open_in}
                      onChange={onChange}
                      error={errors?.open_in}
                      items={convertArrayToKeyedObject(OpenIn, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نوع لینک'
                      required
                      name='type'
                      value={currentItem?.type}
                      onChange={onChange}
                      error={errors?.type}
                      items={convertArrayToKeyedObject(MenuType, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {currentItem?.type && currentItem?.type === 'external' && (
                      <BTextField
                        xs={12}
                        sm={6}
                        label='لینک به صفحه'
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        readOnly={viewReadOnly}
                        required
                      />
                    )}
                    {currentItem?.type && (currentItem?.type === 'data' || currentItem?.type === 'template') && (
                      <BSelectField
                        sm={6}
                        xs={12}
                        label='لینک به صفحه'
                        required
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        items={convertArrayToKeyedObject(pages, 'name', 'description')}
                        readOnly={viewReadOnly}
                      />
                    )}
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='محل نمایش منو'
                      required
                      name='menu_id'
                      value={currentItem?.menu_id}
                      onChange={onChange}
                      error={errors?.menu_id}
                      items={convertArrayToKeyedObject(menus, 'id', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {currentItem?.menu_id && (
                      <BSelectField
                        sm={6}
                        xs={12}
                        label='انتخاب منو'
                        name='parent_id'
                        value={currentItem?.parent_id}
                        onChange={onChange}
                        error={errors?.parent_id}
                        items={convertArrayToKeyedObject(menuItems, 'id', 'name')}
                        readOnly={viewReadOnly || menuItems.length === 0}
                        required
                      />
                    )}
                    {currentItem?.parent_id && (
                      <Grid item xs={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='list'>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                <h5>انتخاب ترتیب منوها</h5>
                                <QuoteList quotes={state.quotes} />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    )}
                    <BCheckBoxField
                      sm={12}
                      name='display'
                      label='نمایش داده شود'
                      value={Number(currentItem?.display)}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                    />
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item xs={12} sm={6}>
                    {!viewReadOnly && menuItems.length > 0 && (
                      <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}
                  >
                    <Button
                      id='backToList'
                      component={Link}
                      to={{
                        pathname: '/cms/menu-items',
                      }}
                      color='secondary'
                      variant='outlined'
                    >
                      بازگشت به لیست
                    </Button>
                  </Grid>
                </CardActions>
              </form>
            </Card>
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      {currentItem.id && currentItem.parent_id && (
        <div style={{ margin: 10 }}>
          {currentItem && (
            <Card {...rest}>
              <Button
                id='backToListIcon'
                component={Link}
                to={{
                  pathname: '/cms/menu-items',
                }}
                color='secondary'
                variant='outlined'
                style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
              >
                <ArrowLeftOutlined />
              </Button>
              <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
                <CardHeader title='اطلاعات زیر منو' />
                <Divider />
                <CardContent>
                  <br />
                  <Grid container spacing={3}>
                    <BTextField
                      xs={12}
                      sm={6}
                      label='عنوان'
                      name='name'
                      value={currentItem?.name}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      error={errors?.name}
                      required
                      // {...register('name', { required: true })}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نحوه باز شدن صفحه'
                      required
                      name='open_in'
                      value={currentItem?.open_in}
                      onChange={onChange}
                      error={errors?.open_in}
                      items={convertArrayToKeyedObject(OpenIn, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نوع لینک'
                      required
                      name='type'
                      value={currentItem?.type}
                      onChange={onChange}
                      error={errors?.type}
                      items={convertArrayToKeyedObject(MenuType, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {currentItem?.type && currentItem?.type === 'external' && (
                      <BTextField
                        xs={12}
                        sm={6}
                        label='لینک به صفحه'
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        readOnly={viewReadOnly}
                        required
                      />
                    )}
                    {currentItem?.type && (currentItem?.type === 'data' || currentItem?.type === 'template') && (
                      <BSelectField
                        sm={6}
                        xs={12}
                        label='لینک به صفحه'
                        required
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        items={convertArrayToKeyedObject(pages, 'name', 'description')}
                        readOnly={viewReadOnly}
                      />
                    )}
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='محل نمایش منو'
                      required
                      name='menu_id'
                      value={currentItem?.menu_id}
                      onChange={onChange}
                      error={errors?.menu_id}
                      items={convertArrayToKeyedObject(menus, 'id', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {currentItem?.menu_id && (
                      <BSelectField
                        sm={6}
                        xs={12}
                        label='انتخاب منو'
                        name='parent_id'
                        value={currentItem?.parent_id}
                        onChange={onChange}
                        error={errors?.parent_id}
                        items={convertArrayToKeyedObject(menuItems, 'id', 'name')}
                        readOnly={viewReadOnly || menuItems.length === 0}
                        required
                      />
                    )}
                    {currentItem?.parent_id && (
                      <Grid item xs={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='list'>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                <h5>انتخاب ترتیب منوها</h5>
                                <QuoteList quotes={state.quotes} />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    )}
                    <BCheckBoxField
                      sm={12}
                      name='display'
                      label='نمایش داده شود'
                      value={Number(currentItem?.display)}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                    />
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item xs={12} sm={6}>
                    {!viewReadOnly && menuItems.length > 0 && (
                      <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}
                  >
                    <Button
                      id='backToList'
                      component={Link}
                      to={{
                        pathname: '/cms/menu-items',
                      }}
                      color='secondary'
                      variant='outlined'
                    >
                      بازگشت به لیست
                    </Button>
                  </Grid>
                </CardActions>
              </form>
            </Card>
          )}
        </div>
      )}
      {currentItem.id && !currentItem.parent_id && (
        <div style={{ margin: 10 }}>
          {currentItem && (
            <Card {...rest}>
              <Button
                id='backToListIcon'
                component={Link}
                to={{
                  pathname: '/cms/menu-items',
                }}
                color='secondary'
                variant='outlined'
                style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
              >
                <ArrowLeftOutlined />
              </Button>
              <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
                <CardHeader title='اطلاعات منو' />
                <Divider />
                <CardContent>
                  <br />
                  <Grid container spacing={3}>
                    <BTextField
                      xs={12}
                      sm={6}
                      label='عنوان'
                      name='name'
                      value={currentItem?.name}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                      error={errors?.name}
                      required
                      // {...register('name', { required: true })}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نحوه باز شدن صفحه'
                      required
                      name='open_in'
                      value={currentItem?.open_in}
                      onChange={onChange}
                      error={errors?.open_in}
                      items={convertArrayToKeyedObject(OpenIn, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='نوع لینک'
                      required
                      name='type'
                      value={currentItem?.type}
                      onChange={onChange}
                      error={errors?.type}
                      items={convertArrayToKeyedObject(MenuType, 'key', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {currentItem?.type && currentItem?.type === 'external' && (
                      <BTextField
                        xs={12}
                        sm={6}
                        label='لینک به صفحه'
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        readOnly={viewReadOnly}
                        required
                      />
                    )}
                    {currentItem?.type && (currentItem?.type === 'data' || currentItem?.type === 'template') && (
                      <BSelectField
                        sm={6}
                        xs={12}
                        label='لینک به صفحه'
                        required
                        name='link'
                        value={currentItem?.link}
                        onChange={onChange}
                        error={errors?.link}
                        items={convertArrayToKeyedObject(pages, 'name', 'description')}
                        readOnly={viewReadOnly}
                      />
                    )}
                    <BSelectField
                      sm={6}
                      xs={12}
                      label='محل نمایش منو'
                      required
                      name='menu_id'
                      value={currentItem?.menu_id}
                      onChange={onChange}
                      error={errors?.menu_id}
                      items={convertArrayToKeyedObject(menus, 'id', 'name')}
                      readOnly={viewReadOnly}
                    />
                    {/* <BTextField
                     xs={12}
                     sm={6}
                     label='ترتیب منو'
                     name='position'
                     value={currentItem?.position}
                     onChange={onChange}
                     readOnly={viewReadOnly}
                     error={errors?.position}
                     required
                   /> */}
                    {currentItem?.menu_id && (
                      <Grid item xs={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='list'>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                <h5>انتخاب ترتیب منوها</h5>
                                <QuoteList quotes={state.quotes} />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    )}
                    <BCheckBoxField
                      sm={12}
                      name='display'
                      label='نمایش داده شود'
                      value={Number(currentItem?.display)}
                      onChange={onChange}
                      readOnly={viewReadOnly}
                    />
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item xs={12} sm={6}>
                    {!viewReadOnly && <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}
                  >
                    <Button
                      id='backToList'
                      component={Link}
                      to={{
                        pathname: '/cms/menu-items',
                      }}
                      color='secondary'
                      variant='outlined'
                    >
                      بازگشت به لیست
                    </Button>
                  </Grid>
                </CardActions>
              </form>
            </Card>
          )}
        </div>
      )}
      {!currentItem.id && <Tabs defaultActiveKey='1' items={items} onChange={onChangeSelectedTab} />}
    </>
  );
};

export default MenuItemsPage;
