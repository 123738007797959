import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents } from '../../../../common/utilities';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';

const initialItem = {
  name: '',
  phone_number: '',
  message: '',
};

const ContactFormPage = (props) => {
  const { staticContext, ...rest } = props;
  const { globalState } = useContext(DashboardPanelContext);
  const { viewReadOnly } = globalState;

  const { errors, setValue } = useForm();

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/contact-form',
    localStorageKey: 'contact-form',
    initialItem,
  });

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/salon-management/contact-forms',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer'>
            <CardHeader title='اطلاعات درخواست مشاوره' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={6}
                  label='نام'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                  // {...register('name', { required: true })}
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='شماره تماس'
                  name='phone_number'
                  value={currentItem?.phone_number}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.phone_number}
                />
                <BTextareaField
                  onChange={onChange}
                  name='message'
                  sm={12}
                  xs={12}
                  value={currentItem?.message}
                  label='متن پیام '
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/salon-management/contact-forms',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  بازگشت به لیست
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default ContactFormPage;
