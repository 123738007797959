import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button as AntdButton } from 'antd';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Cookies from 'universal-cookie';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import BSelectField from '../../../../components/inputs/forms/BSelectField';

const cookies = new Cookies();

const ProductAttributeQuantityPage = (props) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { staticContext, ...rest } = props;
  const { fetchItem, saveItem, removeItem, globalState } = useContext(DashboardPanelContext);
  const [loading, setLoading] = useState(false);
  const [attributeCategories, setAttributeCategories] = useState([]);
  const { handleSubmit } = useForm();
  const [allAttributeIds, setAllAttributeIds] = useState([]);
  const [noPermission, setNoPermission] = useState(false);

  const [mainAttributes, setMainAttributes] = useState([]);
  // const [secondAttributes, setSecondAttributes] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const { currentCurrency } = globalState;

  const handleAttributeChange = useCallback((index, field, value) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index][field] = value;
      return updatedAttributes;
    });
  }, []);

  const addAttributeSet = useCallback(() => {
    setAttributes((prevAttributes) => [
      ...prevAttributes,
      {
        mainAttribute: '',
        mainAttributeOption: '',
        secondAttribute: '',
        secondAttributeOption: '',
        mainAttributeOptions: [],
        secondAttributeOptions: [],
        quantity: null,
        id: null,
        sku: null,
        base_price: '',
        off_price: '',
      },
    ]);
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/attribute-category/options`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        if (response.data?.length > 0) {
          setAttributeCategories(response.data);
        }
      }
    });
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/variants/${productId}`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        let dataExist = false;
        if (response.data?.length > 0) {
          response.data.forEach((res) => {
            const noAttr = res.attributes?.find((attr) => String(attr.attribute_id) === '21');
            if (noAttr) {
              setNoPermission(true);
              dataExist = true;
              setAttributes((prev) => [
                ...prev,
                {
                  mainAttribute: '',
                  mainAttributeOption: '',
                  secondAttribute: '',
                  secondAttributeOption: '',
                  quantity: res.quantity,
                  id: res.id,
                  sku: res.sku,
                  base_price: res.base_price === '0.00' ? '' : String(Number(res.base_price)),
                  off_price: !res.off_price || res.off_price === '0.00' ? '' : String(Number(res.off_price)),
                },
              ]);
            } else if (res.attributes?.length > 0) {
              dataExist = true;
              setAttributes((prev) => [
                ...prev,
                {
                  mainAttribute: res.attributes[0].attribute_id,
                  mainAttributeOption: res.attributes[0].attribute_option_id,
                  secondAttribute: res.attributes[1] ? res.attributes[1].attribute_id : '',
                  secondAttributeOption: res.attributes[1] ? res.attributes[1].attribute_option_id : '',
                  quantity: String(res.quantity),
                  base_price: res.base_price === '0.00' ? '' : String(Number(res.base_price)),
                  off_price: !res.off_price || res.off_price === '0.00' ? '' : String(Number(res.off_price)),
                  id: res.id,
                  sku: res.sku,
                },
              ]);
            }
          });
        }
        if (!dataExist) {
          setAttributes([
            {
              mainAttribute: '',
              mainAttributeOption: '',
              secondAttribute: '',
              secondAttributeOption: '',
              mainAttributeOptions: [],
              secondAttributeOptions: [],
              quantity: null,
              base_price: '',
              off_price: '',
              id: null,
              sku: null,
            },
          ]);
        }
      }
    });
  }, [productId]);

  useEffect(() => {
    if (attributeCategories?.length > 0) {
      fetchItem({
        modelName: `/product-attribute-options/${productId}`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const allIds = [];
          const _attributeOptions = [];
          const getAttributeById = (id) => {
            const attribute = attributeCategories
              .flatMap((attrWrapper) => attrWrapper.attributes)
              .find((attr) => String(attr.id) === String(id));

            return attribute || null;
          };
          const getAttributeOptionNameById = (id) => {
            const attribute = attributeCategories
              .flatMap((attrWrapper) => attrWrapper.attributes)
              .flatMap((attr) => attr.options.map((option) => ({ ...option, data_type_id: attr.data_type_id }))) // Add data_type_id to each option
              .find((option) => String(option.id) === String(id));

            if (attribute) {
              if (attribute.data_type_id === 7) {
                return attribute.value.slice(7);
              }
              return attribute.value;
            }

            return null;
          };
          response.data.dropdowns.forEach((obj) => {
            Object.entries(obj).forEach(([key, value]) => {
              const keyAttribute = getAttributeById(key);
              const removeObj = {
                attribute_id: String(key),
                attribute_option_id: String(value),
                name: `${getAttributeOptionNameById(value)} ${
                  keyAttribute.data_type_id === 7 ? '' : keyAttribute?.unit?.name || ''
                }`,
              };
              const attributeExist = _attributeOptions.find((att) => String(att.id) === String(key));
              if (!attributeExist) {
                const attributeName = getAttributeById(key)?.name;
                if (attributeName && key !== '21') {
                  _attributeOptions.push({
                    name: attributeName,
                    id: key,
                  });
                }
              }
              allIds.push(removeObj);
            });
          });
          setAllAttributeIds(allIds);
          setMainAttributes(_attributeOptions);
        }
      });
    }
  }, [attributeCategories]);

  const onSubmit = async () => {
    setLoading(true);
    let hasError = false;
    const savePromises = attributes
      .map((attr) => {
        if (noPermission) {
          if (!attr.id && attr.quantity) {
            const attrs = [
              {
                attribute_id: 21,
                attribute_option_id: 129,
              },
            ];

            const finalData = {
              product_id: productId,
              attributes: JSON.stringify(attrs),
              quantity: attr.quantity,
              base_price: attr.base_price ? attr.base_price : '0',
              off_price: attr.off_price ? attr.off_price : '0',
            };
            const formData = new FormData();
            axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
            formData.append('product_id', finalData.product_id);

            formData.append('attributes', finalData.attributes);
            return axios({
              method: 'post',
              url: '/product-attribute-options',
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('userToken')}`,
              },
            }).then(() => saveItem(`variants`, finalData, null));
          }
          if (attr.id && attr.quantity) {
            const finalData = {
              variant_id: attr.id,
              quantity: attr.quantity,
              base_price: attr.base_price ? attr.base_price : '0',
              off_price: attr.off_price ? attr.off_price : '0',
            };
            return saveItem(`variants`, finalData, 'update-quantity');
          }
        } else {
          if (!attr.id && attr.mainAttribute && attr.mainAttributeOption && attr.quantity) {
            const attrs = [
              {
                attribute_id: attr.mainAttribute,
                attribute_option_id: attr.mainAttributeOption,
              },
            ];
            if (attr.secondAttribute && attr.secondAttributeOption) {
              attrs.push({
                attribute_id: attr.secondAttribute,
                attribute_option_id: attr.secondAttributeOption,
              });
            }
            const finalData = {
              product_id: productId,
              attributes: JSON.stringify(attrs),
              quantity: attr.quantity,
              base_price: attr.base_price ? attr.base_price : '0',
              off_price: attr.off_price ? attr.off_price : '0',
            };
            return saveItem(`variants`, finalData, null);
          }
          if (attr.id && attr.quantity) {
            const finalData = {
              variant_id: attr.id,
              quantity: attr.quantity,
              base_price: attr.base_price ? attr.base_price : '0',
              off_price: attr.off_price ? attr.off_price : '0',
            };
            return saveItem(`variants`, finalData, 'update-quantity');
          }
          if (!attr.quantity) {
            BSwalShowError(['فیلد تعداد موجودی اجباری است.']);
            hasError = true;
          }
          return null;
        }
        if (!attr.quantity) {
          BSwalShowError(['فیلد تعداد موجودی اجباری است.']);
          hasError = true;
        }
        return null;
      })
      .filter(Boolean);

    if (savePromises.length > 0) {
      try {
        await Promise.all(savePromises);
        if (!hasError) navigate('/product-management/products');
      } catch (err) {
        BSwalShowError(err?.data?.messages);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    attributes.forEach((attr, index) => {
      const _options = allAttributeIds.filter((idAttr) => String(idAttr.attribute_id) === String(attr.mainAttribute));
      handleAttributeChange(index, 'mainAttributeOptions', _options);
    });
  }, [attributes.map((attr) => attr.mainAttribute).join(','), allAttributeIds, handleAttributeChange]);

  useEffect(() => {
    attributes.forEach((attr, index) => {
      const _options = allAttributeIds.filter((idAttr) => String(idAttr.attribute_id) === String(attr.secondAttribute));
      handleAttributeChange(index, 'secondAttributeOptions', _options);
    });
  }, [attributes.map((attr) => attr.secondAttribute).join(','), allAttributeIds, handleAttributeChange]);

  const onRemoveQuantity = useCallback(
    async (attribute, index) => {
      if (attribute.id) {
        await removeItem('variants', attribute.id);
        if (!attribute.mainAttribute) {
          const finalRemoveData = {
            product_id: productId,
            attributes: [
              {
                attribute_id: 21,
                attribute_option_id: 129,
              },
            ],
          };
          const attributesRemovedJSONString = JSON.stringify(finalRemoveData.attributes);
          finalRemoveData.attributes = attributesRemovedJSONString;
          await saveItem(`remove-product-attribute-options`, finalRemoveData, null);
          setNoPermission(false);
        }
      }
      setAttributes((prevAttributes) => {
        const _attrs = [...prevAttributes];
        _attrs.splice(index, 1);
        return _attrs;
      });
    },
    [productId],
  );

  return (
    <div style={{ margin: 10 }}>
      <Card {...rest}>
        <Button
          id='backToListIcon'
          component={Link}
          to={{
            pathname: '/product-management/products',
          }}
          color='secondary'
          variant='outlined'
          style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
        >
          <ArrowLeftOutlined />
        </Button>
        <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='تخصیص موجودی به محصول' />
          <Divider />
          <CardContent>
            <br />
            <div>
              {
                <BCheckBoxField
                  sm={3}
                  key='none'
                  name='no-permission'
                  label='بدون ویژگی'
                  value={noPermission}
                  onChange={(e) => {
                    setNoPermission(e.currentTarget.value);
                    if (e.currentTarget.value && attributes.length === 0) {
                      addAttributeSet();
                    }
                  }}
                  readOnly={attributes[0]?.mainAttribute}
                />
              }
              {attributes.map((attr, index) => (
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: '10px', borderBottom: '1px solid #ddd', paddingBottom: '30px' }}
                  key={`attr-set-${index}`}
                >
                  {!noPermission && (
                    <BSelectField
                      sm={4}
                      xs={12}
                      label='انتخاب ویژگی اصلی'
                      name={`main-attribute-${index}`}
                      key={`main-attribute-${index}`}
                      value={attr.mainAttribute}
                      onChange={(e) => handleAttributeChange(index, 'mainAttribute', e.currentTarget.value)}
                      items={convertArrayToKeyedObject(mainAttributes, 'id', 'name')}
                      readOnly={attr.id || noPermission}
                      required
                    />
                  )}
                  {!noPermission && attr.mainAttributeOptions?.length > 0 && (
                    <BSelectField
                      sm={4}
                      xs={12}
                      label='مقدار ویژگی اصلی'
                      name={`main-attribute-value-${index}`}
                      key={`main-attribute-value-${index}`}
                      value={attr.mainAttributeOption}
                      onChange={(e) => handleAttributeChange(index, 'mainAttributeOption', e.currentTarget.value)}
                      items={convertArrayToKeyedObject(attr.mainAttributeOptions, 'attribute_option_id', 'name')}
                      readOnly={attr.id || noPermission}
                      required
                    />
                  )}
                  {!noPermission && mainAttributes?.length > 0 && (
                    <BSelectField
                      sm={4}
                      xs={12}
                      label='انتخاب ویژگی فرعی'
                      name={`second-attribute-${index}`}
                      key={`second-attribute-${index}`}
                      value={attr.secondAttribute}
                      onChange={(e) => handleAttributeChange(index, 'secondAttribute', e.currentTarget.value)}
                      items={convertArrayToKeyedObject(mainAttributes, 'id', 'name')}
                      readOnly={attr.id || noPermission}
                    />
                  )}
                  {!noPermission && attr.secondAttributeOptions?.length > 0 && (
                    <BSelectField
                      sm={4}
                      xs={12}
                      label='مقدار ویژگی فرعی'
                      name={`second-attribute-value-${index}`}
                      key={`second-attribute-value-${index}`}
                      value={attr.secondAttributeOption}
                      onChange={(e) => handleAttributeChange(index, 'secondAttributeOption', e.currentTarget.value)}
                      items={convertArrayToKeyedObject(attr.secondAttributeOptions, 'attribute_option_id', 'name')}
                      required={!!attr.secondAttribute}
                      readOnly={attr.id || noPermission}
                    />
                  )}
                  <BTextField
                    xs={12}
                    sm={4}
                    label='تعداد موجودی'
                    name={`quantity-${index}`}
                    value={attr.quantity}
                    onChange={(e) => handleAttributeChange(index, 'quantity', e.currentTarget.value)}
                    key={`quantity-${index}`}
                    required
                  />
                  {!noPermission && (
                    <BTextField
                      xs={12}
                      sm={4}
                      label={`قیمت تغییر یافته (${currentCurrency.name})`}
                      name={`base_price-${index}`}
                      value={attr.base_price}
                      onChange={(e) => handleAttributeChange(index, 'base_price', e.currentTarget.value)}
                      key={`base_price-${index}`}
                      readOnly={noPermission}
                    />
                  )}
                  {!noPermission && (
                    <BTextField
                      xs={12}
                      sm={4}
                      label={`قیمت تغییر یافته بعد از تخفیف (${currentCurrency.name})`}
                      name={`off_price-${index}`}
                      value={attr.off_price}
                      onChange={(e) => handleAttributeChange(index, 'off_price', e.currentTarget.value)}
                      readOnly={noPermission}
                      key={`off_price-${index}`}
                    />
                  )}
                  <AntdButton
                    style={{ marginRight: '5px', marginTop: '70px' }}
                    type='primary'
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      onRemoveQuantity(attr, index);
                    }}
                    key='btn-date-remove'
                    disabled={noPermission}
                  >
                    حذف
                  </AntdButton>
                </Grid>
              ))}
              <Button
                variant='contained'
                color='primary'
                onClick={addAttributeSet}
                style={{ marginTop: '10px' }}
                disabled={noPermission}
              >
                افزودن
              </Button>
            </div>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid item xs={12} sm={6}>
              <BSubmitButton id='submitClient' label='ذخیره اطلاعات' loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
              <Button
                id='backToList'
                component={Link}
                to={{
                  pathname: '/product-management/products',
                }}
                color='secondary'
                variant='outlined'
              >
                بازگشت به لیست
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

export default ProductAttributeQuantityPage;
