import React, { useContext, useEffect } from 'react';
import { useNavigate, Route, Routes, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { UserCredentialsContext } from '../context/LoginContext';

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, permission, ...rest } = props;
  const { credentials } = useContext(UserCredentialsContext);
  const cookies = new Cookies();
  const token = cookies.get('userToken');
  const navigate = useNavigate();
  const params = useParams();
  const pathname = useLocation();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);
  // if (permission && (!credentials || !(credentials.user?.currentPost?.privileges || []).includes(permission)))
  // return <Navigate to='/403' />;
  return (
    // <Route
    //   {...rest}
    //   render={(matchProps) =>
    //     cookies.get('userToken') ? (
    //       <Layout>
    //         <Component {...matchProps} />
    //       </Layout>
    //     ) : (
    //       <Redirect exact to='/login' />
    //     )
    //   }
    // />
    <Layout>
      <Component param={params} pathname={pathname} />
    </Layout>
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
